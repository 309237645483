import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

// Firebase imports
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { environment } from './../environments/environment';

// Bootstrap imports
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatOptionModule } from '@angular/material/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// PrimeNG imports
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';
import { BadgeModule } from 'primeng/badge';
import { DialogModule } from 'primeng/dialog';
import { InputTextareaModule } from 'primeng/inputtextarea';

// Other component imports
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { RootComponent } from './root/root.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RegistrationComponent } from './auth/registration/registration.component';
import { MyAccountDetailComponent } from './root/my-account-detail/my-account-detail.component';
import { GenderSelectorComponent } from './share-component/gender-selector/gender-selector.component';
import { WelcomeCustomerComponent } from './root/welcome-customer/welcome-customer.component';
import { UserManagementComponent } from './root/user-management/user-management.component';
import { UserListComponent } from './root/user-management/user-list/user-list.component';
import { UserDetailComponent } from './root/user-management/user-detail/user-detail.component';
import { QuanLyThanhToanComponent } from './root/quan-ly-thanh-toan/quan-ly-thanh-toan.component';
import { DanhSachNhaCungCapComponent } from './root/quan-ly-thanh-toan/danh-sach-nha-cung-cap/danh-sach-nha-cung-cap.component';
import { VisitedSceneComponent } from './visited-scene/visited-scene.component';

// Other libraries
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { NgxPrintModule } from 'ngx-print';
import { NgxFileDropModule } from 'ngx-file-drop';

// Cookie service
import { CookieService } from 'ngx-cookie-service';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RootComponent,
    PageNotFoundComponent,
    RegistrationComponent,
    MyAccountDetailComponent,
    GenderSelectorComponent,
    WelcomeCustomerComponent,
    UserManagementComponent,
    UserListComponent,
    UserDetailComponent,
    QuanLyThanhToanComponent,
    DanhSachNhaCungCapComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    FormsModule,
    AppRoutingModule,
    NgbModule,
    FontAwesomeModule,
    DropdownModule,
    ButtonModule,
    MultiSelectModule,
    InputTextModule,
    CheckboxModule,
    TooltipModule,
    BadgeModule,
    DialogModule,
    NgxDocViewerModule,
    NgxPrintModule,
    NgxFileDropModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    InputTextareaModule,
    MatRadioModule,
    MatOptionModule,
    VisitedSceneComponent,
  ],
  providers: [
    CookieService,
    provideHttpClient(withInterceptorsFromDi()),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage()),
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
