import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';  // Import switchMap
import { Firestore, doc, getDoc } from '@angular/fire/firestore';
import { User } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root',
})
export class CsytGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private firestore: Firestore
  ) {}

  // Hàm canActivate sẽ trả về một Observable<boolean>
  canActivate(): Observable<boolean> {
    return this.authService.userSubject.pipe(
      // Dùng switchMap để xử lý bất đồng bộ và chuyển Observable sang Observable<boolean>
      switchMap(async (user: User | null) => {
        if (user) {
          try {
            const userDocRef = doc(this.firestore, `User/${user.uid}`);
            const userSnapshot = await getDoc(userDocRef);
            if (userSnapshot.exists()) {
              const userData = userSnapshot.data();
              // Kiểm tra nếu người dùng có vai trò 'csyt'
              return Array.isArray(userData.role) && userData.role.includes('csyt');
            }
          } catch (error) {
            console.error('Error fetching user roles:', error);
          }
        }
        return false;
      })
    );
  }
}
