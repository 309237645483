import { Injectable } from '@angular/core';
import {
  Auth,
  getAuth,
  signInWithEmailAndPassword,
  User,
} from '@angular/fire/auth';
import { CookieService } from 'ngx-cookie-service';
import { NotifierService } from 'angular-notifier';
import { GoService } from './go.service';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import {
  Firestore,
  doc,
  setDoc,
  getDoc,
  DocumentReference,
} from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private auth: Auth;
  userSubject: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(
    null,
  );
  private userDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null,
  );
  userData$: Observable<any> = this.userDataSubject.asObservable();

  constructor(
    private go: GoService,
    private notifier: NotifierService,
    private cookieSv: CookieService,
    private firestore: Firestore,
  ) {
    this.auth = getAuth();

    // Theo dõi thay đổi trạng thái người dùng
    this.auth.onAuthStateChanged(async (currentUser) => {
      this.userSubject.next(currentUser); // Cập nhật BehaviorSubject

      // Gọi loadUserData ngay khi trạng thái người dùng thay đổi
      if (currentUser) {
        this.loadUserData(currentUser).subscribe(); // Gọi loadUserData khi có người dùng đăng nhập
      }
    });
  }

  async signInWithEmail(email: string, password: string, isSave?: boolean) {
    try {
      const userCredential = await signInWithEmailAndPassword(
        this.auth,
        email,
        password,
      );
      const user = userCredential.user;

      this.cookieManagement(email, password, isSave);
      this.notifier.notify('success', 'Đăng nhập thành công!');
      this.go.home(); // Điều hướng về trang chủ sau khi đăng nhập thành công
    } catch (error) {
      this.showFailed(error);
    }
  }

  private cookieManagement(email: string, password: string, isSave?: boolean) {
    if (isSave) {
      this.cookieSv.set('email', email);
      this.cookieSv.set('password', password);
    } else {
      this.cookieSv.delete('email');
      this.cookieSv.delete('password');
    }
  }

  setUserData(data: any) {
    this.userDataSubject.next(data);
  }

  getUserData(): Observable<any> {
    return this.userData$; // Trả về Observable người dùng
  }

  getUserDocRef(user: User): DocumentReference {
    return doc(this.firestore, `User/${user.uid}`);
  }

  async signOut() {
    console.log('[AuthService]', 'Sign Out!');
    try {
      await this.auth.signOut();
      this.cookieSv.delete('email');
      this.cookieSv.delete('password');
      this.userDataSubject.next(null); // Xóa dữ liệu người dùng khi đăng xuất
      this.go.login();
    } catch (error) {
      console.error('[AuthService]', 'Sign out failed', error);
    }
  }

  loadUserData(user: any): Observable<any> {
    if (!user) return of(null);

    const userDocRef = doc(this.firestore, `User/${user.uid}`);

    // Sử dụng getDoc để lấy dữ liệu 1 lần
    return new Observable((observer) => {
      from(getDoc(userDocRef)).subscribe({
        next: (docSnap) => {
          if (docSnap.exists()) {
            const userData = docSnap.data();
            this.setUserData(userData); // Cập nhật dữ liệu vào service
            observer.next(userData);
          } else {
            // Tạo tài liệu người dùng mới nếu không tồn tại
            const newUserData = {
              email: user.email || '',
              createdAt: new Date(),
              diamonds: 0,
            };
            this.setUserData(newUserData); // Lưu dữ liệu mới vào service
            observer.next(newUserData);
            from(setDoc(userDocRef, newUserData)).subscribe();
          }
          observer.complete(); // Hoàn thành Observable sau khi lấy dữ liệu
        },
        error: (err) => {
          observer.error(err); // Báo lỗi nếu có lỗi xảy ra
        },
      });
    });
  }

  // Hàm kiểm tra xem user có role "csyt" không
  isCsyt(userData: any): boolean {
    const role = userData?.role;
    return Array.isArray(role) && role.includes('csyt');
  }

  private showFailed(error: any) {
    console.log('[AuthService]', error.code);
    let message = '';
    let suggest = '';

    switch (error.code) {
      case 'auth/wrong-password':
        message = 'Sai mật khẩu!';
        suggest = 'Hãy thử lại với một mật khẩu khác.';
        break;
      case 'auth/user-not-found':
        message = 'Email này không có trong hệ thống!';
        suggest = 'Hãy sử dụng một email khác hoặc đăng ký mới.';
        break;
      case 'auth/popup-closed-by-user':
        message = 'Cửa sổ đăng nhập đã bị đóng khi chưa hoàn thành đăng nhập.';
        suggest =
          'Hãy thử quá trình đăng nhập lại hoặc sử dụng một tài khoản khác.';
        break;
      default:
        message = 'Đăng nhập thất bại!';
        suggest = 'Vui lòng thử lại bằng tài khoản khác.';
        break;
    }

    this.notifier.notify('error', message);
    this.notifier.notify('warning', suggest);
  }
}
