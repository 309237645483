import { Injectable } from '@angular/core';
import * as BABYLON from 'babylonjs';

@Injectable({
  providedIn: 'root'
})
export class SceneSetupService {
  createStaticMeshes(scene: BABYLON.Scene): void {
    // Tạo ground
    const ground = this.createGround(scene);

    // Tạo các tường
    const wallMaterial = this.createMaterial(scene, new BABYLON.Color3(1, 1, 1));
    this.createWall(scene, { width: 50, height: 10, depth: 0.5 }, new BABYLON.Vector3(0, 5, -25), wallMaterial);
    this.createWall(scene, { width: 50, height: 10, depth: 0.5 }, new BABYLON.Vector3(0, 5, 25), wallMaterial);
    this.createWall(scene, { width: 0.5, height: 10, depth: 50 }, new BABYLON.Vector3(-25, 5, 0), wallMaterial);
    this.createWall(scene, { width: 0.5, height: 10, depth: 50 }, new BABYLON.Vector3(25, 5, 0), wallMaterial);

    // Tạo ceiling
    const ceilingMaterial = this.createMaterial(scene, new BABYLON.Color3(1, 0.5, 1));
    const ceiling = BABYLON.MeshBuilder.CreateBox('ceiling', { width: 50, height: 0.5, depth: 50 }, scene);
    ceiling.position.set(0, 10, 0);
    ceiling.material = ceilingMaterial;
    ceiling.physicsImpostor = new BABYLON.PhysicsImpostor(
        ceiling,
        BABYLON.PhysicsImpostor.BoxImpostor,
        { mass: 0, restitution: 0.9 },
        scene,
      );

    // Tạo nguồn sáng
    this.createLighting(scene);

    const skyboxMaterial = new BABYLON.StandardMaterial(
        'skyboxMaterial',
        scene,
      );
      skyboxMaterial.backFaceCulling = false;
      skyboxMaterial.diffuseColor = new BABYLON.Color3(0.2, 0.4, 0.8); // Màu xanh blue

      const skybox = BABYLON.MeshBuilder.CreateBox(
        'skybox',
        { size: 1000 },
        scene,
      );
      skybox.infiniteDistance = true;
      skybox.material = skyboxMaterial;
  }

  private createGround(scene: BABYLON.Scene): BABYLON.Mesh {
    const ground = BABYLON.MeshBuilder.CreateGround('ground', { width: 51, height: 51 }, scene);
    const groundMaterial = this.createMaterial(scene, new BABYLON.Color3(0.3, 0.7, 0.3));
    ground.material = groundMaterial;
    ground.physicsImpostor = new BABYLON.PhysicsImpostor(
      ground,
      BABYLON.PhysicsImpostor.BoxImpostor,
      { mass: 0, restitution: 0.9 },
      scene,
    );
    return ground;
  }

  private createWall(scene: BABYLON.Scene, size: { width: number, height: number, depth: number }, position: BABYLON.Vector3, material: BABYLON.Material): BABYLON.Mesh {
    const wall = BABYLON.MeshBuilder.CreateBox('wall', size, scene);
    wall.position = position;
    wall.material = material;
    wall.checkCollisions = true; // Enable collision for the wall
    wall.physicsImpostor = new BABYLON.PhysicsImpostor(
      wall,
      BABYLON.PhysicsImpostor.BoxImpostor,
      { mass: 0, restitution: 0.9 },
      scene,
    );
    return wall;
  }

  private createMaterial(scene: BABYLON.Scene, color: BABYLON.Color3): BABYLON.StandardMaterial {
    const material = new BABYLON.StandardMaterial('material', scene);
    material.diffuseColor = color;
    return material;
  }

  private createLighting(scene: BABYLON.Scene): void {
    const pointLight = new BABYLON.PointLight('pointLight', new BABYLON.Vector3(0, 8, 0), scene);
    pointLight.diffuse = new BABYLON.Color3(1, 1, 1);
    pointLight.intensity = 0.6;
    pointLight.range = 35;
    scene.addLight(pointLight);

    const hemiLight = new BABYLON.HemisphericLight('hemiLight', new BABYLON.Vector3(0, 6, 0), scene);
    hemiLight.diffuse = new BABYLON.Color3(1, 0.5, 1);
    hemiLight.intensity = 0.6;
    scene.addLight(hemiLight);
  }
}
