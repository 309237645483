import { GoService } from './../../services/go.service';
import { Component, OnInit } from '@angular/core';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTwitter, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { getAuth, createUserWithEmailAndPassword } from '@angular/fire/auth'; // Import auth functions
import { getFirestore, Firestore, doc, setDoc } from '@angular/fire/firestore'; // Import Firestore functions

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {
  email = '';
  password = '';
  rePassword = '';

  icon = { envelope: faEnvelope, lock: faLock, facebook: faFacebookF, tweeter: faTwitter, google: faGoogle };

  private auth = getAuth(); // Khởi tạo auth
  private firestore: Firestore; // Đối tượng Firestore

  constructor(
    public go: GoService
  ) {
    this.firestore = getFirestore(); // Khởi tạo Firestore
  }

  ngOnInit(): void { }

  register() {
    this.password = this.password.trim();
    this.rePassword = this.rePassword.trim();

    if (this.password.length && this.rePassword.length && this.password === this.rePassword) {
      createUserWithEmailAndPassword(this.auth, this.email, this.password)
        .then(userCredential => {
          const user = userCredential.user;

          // Tạo document trong Firestore cho user
          setDoc(doc(this.firestore, 'User', user?.uid), {
            email: this.email,
            diamonds: 10,
            createdAt: new Date(),
            // Bạn có thể thêm các trường khác nếu cần
          }).then(() => {
            // Sau khi lưu thành công, chuyển người dùng đến trang login
            this.go.login();
          }).catch(error => {
            
          });

        })
        .catch(reason => {
          switch (reason?.code) {
            case "auth/email-already-in-use":
              
              break;
            case "auth/invalid-email":
          
              break;
            case "auth/weak-password":

              break;
            default:

              break;
          }
        });
    } else {

    }
  }
}
