import {
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import * as BABYLON from 'babylonjs';
import 'babylonjs-loaders';
import * as GUI from 'babylonjs-gui';
import {
  Firestore,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  onSnapshot,
  DocumentChange,
  QueryDocumentSnapshot,
  DocumentData,
} from '@angular/fire/firestore';
import * as CANNON from 'cannon';
import { Observable, Subject } from 'rxjs';
import {
  Storage,
  ref,
  uploadBytesResumable,
  listAll,
  deleteObject,
  getDownloadURL,
} from '@angular/fire/storage';
import { NotifierService } from 'angular-notifier';
import { Auth, getAuth, onAuthStateChanged } from '@angular/fire/auth';
import { UserDocument } from '../../interfaces/user-document.interface';
import { CallData } from '../../interfaces/call-data.interface';
import { VideoCallService } from '../../services/video-call.service';
import { DiamondService } from '../../services/diamond.service';
import { AuthService } from '../../services/auth.service';
import OpenAI from "openai";

declare let webkitSpeechRecognition: any;

interface Problem {
  name: string;
  tag: string[];
}

interface Plan {
  providerEmail: string;
  plans: { name: string; option: number; tag: string[] }[];
  clientEmail: string;
  createdAt: any;
  clientPhone: string;
  fullName: string;
  guide?: string[];
  history?: string[];
  medications: { brandName: string; quantity: number; use: string }[];
}

interface DocumentGuide {
  document: QueryDocumentSnapshot<DocumentData>;
  guides: string[];
  providerEmail: string;
}

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css'],
})
export class UserManagementComponent implements OnInit, OnDestroy {
 @ViewChild('renderCanvas', { static: true }) renderCanvas!: ElementRef<HTMLCanvasElement>;

  private userDocSnapshot: any;

  private gameEngine!: BABYLON.Engine;
  private gameScene!: BABYLON.Scene;
  private canvas!: HTMLCanvasElement;
  private player!: BABYLON.Mesh;

  private balls: BABYLON.Mesh[] = [];
  private createBallInterval: any;

  private pickBall: HTMLAudioElement;
  private destroy$ = new Subject<void>();
  private clientPhoneInput: GUI.InputText;
  private inputHistory: GUI.InputTextArea;

  public plansProblem: {
    tag: any;
    name: string;
    option: number;
  }[] = [];
  private gameOver: boolean = false;
  buttonFind: boolean = true;
  matchingProblems: Problem[] = [];
  pickedProblems: Set<string> = new Set();
  private guides: DocumentGuide[] = []; //Các guide từ các plans doc để show
  private currentDocumentIndex: number = 0;
  recognition: any;
  public transcriptText = '';
  
  userData: UserDocument = {
    gifters: [],
    bannerUrl: '',
    organize: '',
    watching: [],
    email: '',
    property: [],
    diamonds: 0, // Giá trị mặc định
    avata: '',
    menu: [],
    name: '',
  };
  
  authEmail: string;

  private isRecognizing = false;
  allProblems: any[] = [];
  allGptPrompts: any[] = [];
  chatGPTContent: string = '';

  goHome: boolean = false;
  goMarket: boolean = false;

  constructor(
    private ngZone: NgZone,
    private firestore: Firestore,
    private storage: Storage,
    public notifier: NotifierService,
    public auth: Auth, // Đảm bảo bạn lấy dịch vụ Auth
    public videoCallService: VideoCallService,
    private diamondService: DiamondService,
    public authService: AuthService,
  ) {
    this.fetchAllProblems();
    this.fetchAllGptPrompts();
  }

  private async fetchAllProblems() {
    try {
      const problemsRef = collection(this.firestore, 'problems');
      const querySnapshot = await getDocs(problemsRef);
      this.allProblems = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      console.log('All problems loaded:', this.allProblems);
    } catch (error) {
      console.error('Error fetching problems:', error);
    }
  }
  
  private async fetchAllGptPrompts() {
    try {
      const gptPromptsRef = collection(this.firestore, 'gptPrompts');
      const querySnapshot = await getDocs(gptPromptsRef);
      this.allGptPrompts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      console.log('All problems loaded:', this.allProblems);
    } catch (error) {
      console.error('Error fetching gptPrompts:', error);
    }
  }

  ngOnInit(): void {
    this.canvas = this.renderCanvas.nativeElement;
    this.initGameScene();
    this.loadUserData();
    window.addEventListener('resize', this.onResize);
    document.addEventListener('fullscreenchange', this.onResize);
    document.addEventListener('webkitfullscreenchange', this.onResize);
    document.addEventListener('mozfullscreenchange', this.onResize);
    document.addEventListener('msfullscreenchange', this.onResize);
  }

  async loadUserData() {
    const auth = getAuth();
    this.listenToAuthStateChanges(auth); // Bắt đầu theo dõi trạng thái đăng nhập
  }

  private listenToAuthStateChanges(auth: Auth) {
    const userData$: Observable<UserDocument | null> = new Observable(
      (observer) => {
        onAuthStateChanged(auth, (user) => {
          if (user) {
            const userId = user.uid;
            this.authEmail = user.email!;
            this.listenToUserData(userId, observer); // Lắng nghe dữ liệu người dùng theo thời gian thực
          } else {
            console.log('No user is logged in');
            observer.next(null);
          }
        });
      },
    );

    // Subscribe to userData$ để xử lý dữ liệu người dùng
    userData$.subscribe({
      next: (userData) => {
        if (userData) {
          this.userData = userData; // Gán dữ liệu người dùng
          this.userData.diamonds = this.userData.diamonds || 0;

          // Sau khi tải xong dữ liệu người dùng, bắt đầu lắng nghe dữ liệu cuộc gọi
          this.videoCallService.listenToCallsData(this.authEmail);
        }
      },
      error: (error) => {
        console.error('Error loading user data:', error);
      },
    });
  }

  // Hàm lắng nghe dữ liệu người dùng từ Firestore theo thời gian thực
  private listenToUserData(userId: string, observer: any) {
    const userDocRef = doc(this.firestore, `User/${userId}`);
    onSnapshot(
      userDocRef,
      (userDocSnapshot) => {
        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data() as UserDocument;
          this.userDocSnapshot = userDocSnapshot; // Lưu DocumentSnapshot để sử dụng sau này
          observer.next(userData); // Gửi dữ liệu realtime tới observer
        } else {
          console.log('User document not found');
          observer.next(null); // Trả về null nếu không tìm thấy document
        }
      },
      (error) => {
        console.error('Error fetching user data in realtime:', error);
      },
    );
  }  

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.onResize);
    document.removeEventListener('fullscreenchange', this.onResize);
    document.removeEventListener('webkitfullscreenchange', this.onResize);
    document.removeEventListener('mozfullscreenchange', this.onResize);
    document.removeEventListener('msfullscreenchange', this.onResize);
    this.gameEngine.dispose();

    this.destroy$.next();
    this.destroy$.complete();

    if (this.videoCallService.unsubscribeCallsData) {
      this.videoCallService.unsubscribeCallsData();
    }
  }

  private initGameScene(): void {
    this.canvas.style.width = '100%';
    this.canvas.style.height = '100%';
    this.gameEngine = new BABYLON.Engine(this.canvas, true);
    this.gameScene = this.createGameScene();
    this.gameEngine.runRenderLoop(() => {
      this.gameScene.render();
    });

    this.recognition = new webkitSpeechRecognition();
    this.recognition.continuous = true;
    this.recognition.interimResults = true;
    this.recognition.lang = 'vi-VI';

    this.recognition.onresult = (event: any) => {
      let finalTranscript = '';
      for (let i = event.resultIndex; i < event.results.length; i++) {
        const transcript = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
          finalTranscript += transcript + ' ';
        }
      }
      this.transcriptText = finalTranscript;
    };

    this.recognition.onspeechend = () => {};
    this.preloadPickBall();
  }

  private createGameScene(): BABYLON.Scene {
    const scene = new BABYLON.Scene(this.gameEngine);
    this.gameScene = scene;
    this.createSkybox(scene);
    this.createCamera(scene);
    this.createLight(scene);
    this.createInteractiveObjects(scene);
    

    return scene;
  }

  private createSkybox(scene: BABYLON.Scene): void {
    const skyboxMaterial = new BABYLON.StandardMaterial('skyboxMaterial', scene);
    skyboxMaterial.backFaceCulling = false;
    skyboxMaterial.diffuseColor = new BABYLON.Color3(0.2, 0.4, 0.8);
    const skybox = BABYLON.MeshBuilder.CreateBox('skybox', { size: 1000 }, scene);
    skybox.infiniteDistance = true;
    skybox.material = skyboxMaterial;
  }

  private createCamera(scene: BABYLON.Scene): void {
    const camera = new BABYLON.ArcRotateCamera(
      'camera',
      Math.PI / 2 - (30 * Math.PI) / 180,
      Math.PI / 2.5,
      10,
      BABYLON.Vector3.Zero(),
      scene
    );
    camera.attachControl(this.canvas, true);
    camera.lowerBetaLimit = Math.PI / 2 - Math.PI / 15;
    camera.upperBetaLimit = Math.PI / 2;
    camera.lowerRadiusLimit = 10;
    camera.upperRadiusLimit = 10;
    camera.angularSensibilityX = 1000;
    camera.pinchDeltaPercentage = 0.005;
  }

  private createLight(scene: BABYLON.Scene): void {
    new BABYLON.HemisphericLight('light', new BABYLON.Vector3(0, 1, 0), scene);
  }

  private createInteractiveObjects(scene: BABYLON.Scene): void {
    const playerMaterial = new BABYLON.StandardMaterial("playerMaterial", scene);
    playerMaterial.diffuseColor = new BABYLON.Color3(1, 1, 1); // Màu trắng ban đầu
    this.player = this.createSphere('player', { diameter: 1 }, scene, new BABYLON.Vector3(0, 0.5, 4));
    this.player.material = playerMaterial; // Gán material cho player
    this.addActionToMesh(this.player, () => {
      if (this.isRecognizing) {
        this.startBallInterval(scene);
        this.stopRecognition();
        (this.player.material as BABYLON.StandardMaterial).diffuseColor = new BABYLON.Color3(1, 1, 1); // Màu trắng
        
      } else {
        if (this.createBallInterval) {
          clearInterval(this.createBallInterval);
          this.createBallInterval = null; // Đảm bảo không có interval nào còn chạy
        }
        this.startRecognition();
        (this.player.material as BABYLON.StandardMaterial).diffuseColor = new BABYLON.Color3(1, 0, 0); // Màu đỏ
      }
      this.isRecognizing = !this.isRecognizing;
    });
    const market = this.createSphere('market', { diameter: 0.6 }, scene, new BABYLON.Vector3(-2, 0.5, 5));
    this.addActionToMesh(market, () => {
      if (this.goMarket) {
        this.goMarket = false;
      } else {
        this.goMarket = true;
      } 
    });
    const bZone = this.createSphere('bZone', { diameter: 0.5 }, scene, new BABYLON.Vector3(2.5, 0.5, 2));
    bZone.rotation.y = Math.PI;
    bZone.material = this.createTextureMaterial('homeIconMaterial', 'assets/textures/home-color-icon.png', scene);
    this.addActionToMesh(bZone, async () => {
      try {
        this.goMarket = false;
        
        await this.ngZone.runOutsideAngular(async () => {
          await this.disposeGame();  
        });

        this.hideUIElements();

        // Chờ một thời gian (thay vì setTimeout)
        await this.delay(3000); // Chờ 3 giây

        this.goHome = true;
      } catch (error) {
        console.error('Error in action:', error);
      }
    });
  }

  private async disposeGame(): Promise<void> {
    this.gameEngine.dispose();
    this.gameScene.dispose();
    this.matchingProblems = [];
    this.balls = [];
    this.pickedProblems.clear();
    this.player.dispose();
  }

  private hideUIElements(): void {
    const gameOverDiv = document.getElementById('gameOver');
    if (gameOverDiv) {
      gameOverDiv.style.display = 'none';
    }
    
    this.destroy$.next();
    this.destroy$.complete();
  }

  private delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  private createSphere(name: string, options: any, scene: BABYLON.Scene, position: BABYLON.Vector3): BABYLON.Mesh {
    const sphere = BABYLON.MeshBuilder.CreateSphere(name, options, scene);
    sphere.position = position;
    return sphere;
  }
  private createTextureMaterial(name: string, texturePath: string, scene: BABYLON.Scene): BABYLON.StandardMaterial {
    const material = new BABYLON.StandardMaterial(name, scene);
    material.diffuseTexture = new BABYLON.Texture(texturePath, scene);
    return material;
  }
  private addActionToMesh(mesh: BABYLON.Mesh, action: () => void): void {
    mesh.actionManager = new BABYLON.ActionManager(mesh.getScene());
    mesh.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, action));
  }

  private startBallInterval(scene: BABYLON.Scene): void {
    this.createBallInterval = setInterval(() => {
      if (!this.gameOver) {
        this.createBall(scene);
      } else {
        clearInterval(this.createBallInterval);
        this.createBallInterval = null;
      }
    }, 2000);
  }

  private createBall(scene: BABYLON.Scene): void {
    const availableProblems = this.matchingProblems.filter(
      (problem) => !this.pickedProblems.has(problem.name),
    );
    if (availableProblems.length > 0) {
      const randomProblem =
        availableProblems[Math.floor(Math.random() * availableProblems.length)];
      const option = Math.floor(Math.random() * 5) + 1;
      const ball = BABYLON.MeshBuilder.CreateSphere(
        'ball',
        { diameter: 2 },
        scene,
      );
      ball.position = new BABYLON.Vector3(Math.random() * 8 - 4, 0.25, -10);
      ball.metadata = {
        name: randomProblem.name,
        option,
        tag: randomProblem.tag,
      };
      const colors = {
        1: BABYLON.Color3.Green(),
        2: new BABYLON.Color3(0.5, 1, 0.5),
        3: BABYLON.Color3.Yellow(),
        4: BABYLON.Color3.FromHexString('#FFA500'),
        5: BABYLON.Color3.Red(),
      };
      const material = new BABYLON.StandardMaterial('coinMaterial', scene);
      material.diffuseColor = colors[option];
      ball.material = material;
      const advancedTexture =
        GUI.AdvancedDynamicTexture.CreateFullscreenUI('UI');
      const label = new GUI.TextBlock();
      label.text = randomProblem.name;
      label.color = 'white';
      label.fontSize = 24;
      advancedTexture.addControl(label);
      label.linkWithMesh(ball);
      // Update the label's fontSize based on camera-mesh distance
      scene.onBeforeRenderObservable.add(() => {
        // Calculate the distance between the camera and the mesh
        const distance = BABYLON.Vector3.Distance(
          scene.activeCamera.position,
          ball.position,
        );
        // Adjust the fontSize based on the distance
        const newFontSize = Math.max(12, 24 - distance * 0.5); // Adjust the scaling factor as needed
        label.fontSize = newFontSize;
      });
      ball.actionManager = new BABYLON.ActionManager(scene);
      ball.actionManager.registerAction(
        new BABYLON.ExecuteCodeAction(
          BABYLON.ActionManager.OnPickTrigger,
          () => {
            this.ngZone.run(() => {
              this.pickBall.currentTime = 0; // Đặt lại thời gian phát âm thanh
              this.pickBall.play(); //
              this.plansProblem = this.plansProblem.filter(
                (plan) => plan.name !== randomProblem.name,
              );
              this.plansProblem.push({
                name: randomProblem.name,
                option,
                tag: randomProblem.tag,
              });
              this.pickedProblems.add(randomProblem.name);
              ball.dispose();
            });
          },
        ),
      );
      ball.onDispose = () => {
        advancedTexture.dispose();
      };
      this.balls.push(ball);
      scene.onBeforeRenderObservable.add(() => {
        ball.position.z += 0.1;
        if (ball.position.z > this.player.position.z + 1) {
          ball.dispose();
        }
      });
      // Tự hủy ball sau 8 giây
      setTimeout(() => {
        if (!ball.isDisposed()) {
          ball.dispose();
        }
      }, 8000);
    } else if ( this.pickedProblems.size > 0) {
      this.endGame()
    }
  }

  startRecognition() {
    this.recognition.start();
  }

  async stopRecognition() {
    this.recognition.stop();
    const words = this.transcriptText.toLowerCase();
    const matchingProblems = this.allProblems.filter((problem) =>
      Array.isArray(problem.tag) &&
      problem.tag.some((tag) => words.includes(tag.toLowerCase()))
    );
    const uniqueProblems = Array.from(
      new Map(matchingProblems.map((problem) => [problem.id, problem])).values()
    );
    this.matchingProblems = uniqueProblems;
  }
  
  private endGame() {
    this.gameOver = true;
    this.balls.forEach((ball) => ball.dispose());
    this.balls = [];
    const gameOverDiv = document.getElementById('gameOver');
    if (gameOverDiv) {
      gameOverDiv.style.display = 'block';
    }
  }

  public replay() {
    const gameOverDiv = document.getElementById('gameOver');
    if (gameOverDiv) {
      gameOverDiv.style.display = 'none';
    }

    this.ngZone.runOutsideAngular(() => {
      this.gameOver = false;
      this.balls = [];
      this.plansProblem = [];
      this.pickedProblems.clear();
      this.chatGPTContent = '';
    });

    this.buttonFind = true;

    // Gọi lại startBallInterval để khởi động quá trình tạo bóng
    this.startBallInterval(this.gameScene);
  }

  public async getGptGuide() {
    if (this.plansProblem.length > 0) {
      try {
        if (this.allGptPrompts.length > 0) {
          const promptData = this.allGptPrompts.find(prompt => prompt.id === 'prompt2');
          
          if (!promptData) {
            console.error("No such document 'prompt2' in gptPrompts!");
            return;
          }
          const { messages, model, apiKey } = promptData;
          
          if (!apiKey) {
            throw new Error("API key is missing from Firestore document.");
          }
          // Gọi OpenAI API với cấu hình đã lấy được
          this.chatGPTContent = await this.fetchGptResponse(apiKey, model, messages);
          // Ẩn nút sau khi lấy hướng dẫn để tránh truy vấn nhiều lần
          this.buttonFind = false;
        }
      } catch (error) {
        console.error('Error getting plans documents: ', error);
      }
    }
  }

  // Hàm gọi OpenAI API để tạo phản hồi
  private async fetchGptResponse(apiKey: string, model: string, messages: any[]): Promise<string> {
    try {
      const openai = new OpenAI({ apiKey });
      const formattedMessage = [
        {
          role: messages[0].role,
          content: messages[0].content,
        },
        {
          role: messages[1].role,
          content: messages[1].content.replace(
            "${plansProblems.join(', ')}",
            this.plansProblem.join(", ")
          ),
        },
      ];
      const completion = await openai.chat.completions.create({
        model: model || "gpt-4o-mini",
        messages: formattedMessage,
      });
      return completion.choices[0].message.content;
    } catch (error) {
      console.error("Error fetching response from OpenAI:", error);
      return "Không thể kết nối với AI. Vui lòng thử lại sau.";
    }
  }

  callDoctor(): void {
    window.location.href = 'tel:0981919115';
    this.savePlans
  }

  private async savePlans() {
    const planData: Plan = {
      clientEmail: this.userData.email,
      createdAt: new Date(),
      plans: this.plansProblem,
      providerEmail: 'pkthienbinh@gmail.com',
      guide: this.chatGPTContent.split('\n'),
      history: [],
      clientPhone: '',
      fullName: '',
      medications: [],
    };
    try {
      const plansCollectionRef = collection(this.firestore, 'plans');
      const docRef = await addDoc(plansCollectionRef, planData);
      console.log('Plan saved with ID: ', docRef.id);
      this.notifier.notify(
        'success',
        `Đặt yêu cầu thành công!`,
      );
    } catch (error) {
      console.error('Error adding plan: ', error);
    }
  }

  private async sendDiamond(email: string) {
    const success = await this.diamondService.sendDiamond(
      this.userData.email,
      email,
      this.userData.diamonds
    );
    
    if (success) {
      this.updateUserDiamonds(this.userData.diamonds -= 1);
    }
  }

  private async updateUserDiamonds(newDiamonds: number) {
    try {
      await this.userDocSnapshot.ref.update({ diamonds: newDiamonds });
    } catch (error) {
      console.error('Error updating user diamonds:', error);
    }
  }

  private onResize = () => {
    if (this.gameEngine) {
      this.gameEngine.resize();
    }
    this.adjustUI();
  };

  private adjustUI() {
    const buttons = document.querySelectorAll('button');

    buttons.forEach((button) => {
      (button as HTMLElement).style.width = '100%';
      (button as HTMLElement).style.bottom = '10px'; // Điều chỉnh khoảng cách từ đáy
    });
  }
  private preloadPickBall() {
    const pickBall = new Audio();
    pickBall.src = '/assets/audio/pickCoin.mp3';
    pickBall.load();
    this.pickBall = pickBall;
  }

  onTableClicked() {
    // Gọi phương thức của component cha khi nhận được sự kiện từ con
    this.goHome = false;
    this.initGameScene();
  }
}
