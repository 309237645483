import {
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import * as BABYLON from 'babylonjs';
import { Firestore, collection, getDocs, addDoc, doc, onSnapshot, DocumentReference } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { User } from '@angular/fire/auth';
import { UserDocument } from '../../interfaces/user-document.interface';
import { AuthService } from '../../services/auth.service';
import { OpenAIService } from '../../services/openai.service';
import { SceneSetupService } from '../../services/babylon.service';
import { SearchResult, Plan } from '../../interfaces/searchResult.interface';
import { Timestamp } from 'firebase/firestore';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

interface Problem {
  name: string;
  tag: string[];
}

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css'],
})
export class UserManagementComponent implements OnInit, OnDestroy {
  @ViewChild('renderCanvas', { static: true }) renderCanvas!: ElementRef<HTMLCanvasElement>;
  @ViewChild('userDetail') userDetailComponent: any;

  gameEngine!: BABYLON.Engine;
  gameScene!: BABYLON.Scene;
  canvas!: HTMLCanvasElement;

  private player!: BABYLON.Mesh;

  buttonFind: boolean = true;

  userData: UserDocument = {
    email: '',
    diamonds: 0, 
  };

  user$: Observable<User | null>;
  userDocRef: DocumentReference | null = null;

  private isRecognizing = false;
  public transcriptText: string = '';
  allProblems: any[] = [];
  matchingProblems: Problem[] = [];

  private balls: BABYLON.Mesh[] = [];
  private pickBall: HTMLAudioElement;

  pickedProblems: Set<string> = new Set();
  public plansProblem: Plan[] = [];

  gameOverDiv: boolean = false;

  outputPlanData: SearchResult | null = null;

  goMarket: boolean = false;
  goDetail:  boolean = false;

  shouldClearBalls: boolean = false;

  constructor(
    private ngZone: NgZone,
    private firestore: Firestore,
    public authService: AuthService,
    private openAIService: OpenAIService,
    private sceneSetupService: SceneSetupService,
    private router: Router,
    private toastr: ToastrService,
  ) {
        
    this.user$ = this.authService.userSubject;
  }

  private async fetchAllProblems() {
    try {
      const problemsRef = collection(this.firestore, 'problems');
      const querySnapshot = await getDocs(problemsRef);
      this.allProblems = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      console.log('All problems loaded:', this.allProblems);
    } catch (error) {
      console.error('Error fetching problems:', error);
    }
  }

  ngOnInit(): void {
    this.canvas = this.renderCanvas.nativeElement;
    
    this.initGameScene();
    

    // Lắng nghe sự kiện resize và fullscreen
    window.addEventListener('resize', this.onResize);
    document.addEventListener('fullscreenchange', this.onResize);
    document.addEventListener('webkitfullscreenchange', this.onResize);
    document.addEventListener('mozfullscreenchange', this.onResize);
    document.addEventListener('msfullscreenchange', this.onResize);
  }

  ngAfterViewInit(): void {
    this.onResize(); // Đảm bảo canvas và engine được resize ngay sau khi DOM render
    this.fetchAllProblems();
    this.listenUserData();
  }

  private initGameScene(): void {
    this.canvas.style.width = '100%';
    this.canvas.style.height = '100%';

    this.gameEngine = new BABYLON.Engine(this.canvas, true);
    this.gameScene = this.createGameScene();

    this.gameEngine.runRenderLoop(() => {
      this.gameScene.render();
    });

    this.preloadPickBall();
  }

  private onResize = (): void => {
    if (this.gameEngine) {
      this.gameEngine.resize(); // Resize engine
    }
    this.adjustUI(); // Điều chỉnh UI nếu cần
  };


  private adjustUI() {
    const buttons = document.querySelectorAll('button');

    buttons.forEach((button) => {
      (button as HTMLElement).style.width = '100%';
      (button as HTMLElement).style.bottom = '10px'; // Điều chỉnh khoảng cách từ đáy
    });
  }

  private createGameScene(): BABYLON.Scene {
    const scene = new BABYLON.Scene(this.gameEngine);
    this.gameScene = scene;

    // Hàm cập nhật ảnh nền dựa trên kích thước cửa sổ
    const updateBackgroundImage = () => {
        const isLandscape = window.innerWidth > window.innerHeight;
        const backgroundImage = isLandscape ? '/assets/backgroundW.jpeg' : '/assets/backgroundH.jpeg';

        // Tạo lớp ảnh nền
        const backgroundLayer = new BABYLON.Layer('backgroundLayer', backgroundImage, scene);
        
        // Đảm bảo lớp ảnh nền phủ toàn bộ màn hình
        backgroundLayer.isBackground = true;

        // Điều chỉnh tỷ lệ ảnh nền phù hợp với kích thước màn hình
        const texture = backgroundLayer.texture;
        const scaleX = window.innerWidth / texture.getSize().width;
        const scaleY = window.innerHeight / texture.getSize().height;

        // Sử dụng phương thức scale thay vì uScale/vScale
        texture.scale(scaleX); // Điều chỉnh tỷ lệ theo chiều ngang
        texture.scale(scaleY); // Điều chỉnh tỷ lệ theo chiều dọc
    };

    // Cập nhật ảnh nền khi thay đổi kích thước cửa sổ
    window.addEventListener('resize', updateBackgroundImage);

    // Gọi hàm cập nhật ảnh nền ban đầu
    updateBackgroundImage();

    // Tạo các thành phần khác
    this.createCamera(scene); // Camera
    this.sceneSetupService.createLight(scene); // Ánh sáng
    this.createPlayer(scene);
    this.createMarket(scene);
    this.createBZone(scene);

    return scene;
}


  private createCamera(scene: BABYLON.Scene): void {
    // Tạo camera với ArcRotateCamera
    const camera = new BABYLON.ArcRotateCamera(
      'camera',
      Math.PI / 2,
      Math.PI / 2,
      10, // Đặt bán kính (radius) sao cho vừa vặn với ảnh nền
      new BABYLON.Vector3(0, 0, 0), // Vị trí nhìn vào trung tâm
      scene
    );

    // Camera này sẽ không xoay hoặc di chuyển
    camera.allowUpsideDown = false; // Không cho phép camera đảo ngược
    camera.lowerBetaLimit = Math.PI / 2 - Math.PI; // Giới hạn dưới của camera, không cho phép nhìn quá xuống dưới
    camera.upperBetaLimit = Math.PI / 2 - Math.PI / 30; // Giới hạn trên của camera
    camera.lowerRadiusLimit = 10; // Bán kính thấp nhất để camera gần ảnh
    camera.upperRadiusLimit = 10; // Bán kính cao nhất để camera không di chuyển ra xa quá

    camera.attachControl(this.canvas, true); // Gắn điều khiển camera vào canvas

    // Tắt tính năng di chuyển của camera
    camera.inputs.clear(); // Xóa các input di chuyển (trượt, xoay)
  }


  private unsubscribeUserData: () => void | null = null;

  private listenUserData(): void {
    this.user$.subscribe((user) => {
      // Hủy listener cũ nếu tồn tại
      if (this.unsubscribeUserData) {
        this.unsubscribeUserData();
        this.unsubscribeUserData = null;
      }

      if (user) {
        this.userDocRef = doc(this.firestore, `User/${user.uid}`);
        
        // Tạo listener mới và lưu lại hàm hủy
        this.unsubscribeUserData = onSnapshot(
          this.userDocRef,
          (userDocSnapshot) => {
            if (userDocSnapshot.exists()) {
              this.userData = userDocSnapshot.data() as UserDocument;
              const role = this.userData?.role;

              const pharmacy = Array.isArray(role) && role.includes('pharmacy');
              if (pharmacy) {
                this.router.navigate(['t/tai-khoan-cua-toi']);
              }

              const csyt = Array.isArray(role) && role.includes('csyt');
              if (csyt) {
                this.createCZone();
              }
            } else {
              console.log('User document not found');
            }
          },
          (error) => {
            console.error('Error fetching user data in realtime:', error);
          }
        );
      } else {
        this.userData = null;
        console.log('No user is logged in');
      }
    });
  }

  private createPlayer(scene: BABYLON.Scene) {
    // Tạo mesh cho player
    this.player = BABYLON.MeshBuilder.CreateSphere('player', { diameter: 0.5 }, scene);
    this.player.position = new BABYLON.Vector3(0, -4, -3);

    // Tạo material cho player với màu đỏ
    const playerMaterial = new BABYLON.StandardMaterial('playerMaterial', scene);
    playerMaterial.diffuseColor = new BABYLON.Color3(1, 0, 0); // Màu đỏ (RGB: 1, 0, 0)

    // Áp dụng material vào player
    this.player.material = playerMaterial;

    // Thêm hành động vào player (tùy thuộc vào logic của bạn)
    this.addActionToMesh(this.player, () => {
      this.goMarket = false;
      this.goDetail = false;
      this.stop(scene);
    });
  }

  private createBZone(scene: BABYLON.Scene) {
    const bZone = BABYLON.MeshBuilder.CreateSphere('bZone', { diameter: 0.5 }, scene);
    bZone.position = new BABYLON.Vector3(1, 3.5, 0);
    this.addActionToMesh(bZone, async () => {
      try {
        this.goMarket = false;
        this.goDetail = !this.goDetail;
        this.gameOverDiv = false;      
      } catch (error) {
        console.error('Error in action:', error);
      }
    });
  }

  private createMarket(scene: BABYLON.Scene): void {

    const market = BABYLON.MeshBuilder.CreateSphere('market', { diameter: 0.5 }, scene);

    market.position = new BABYLON.Vector3(0, 3.5, 0);

    const marketMaterial = new BABYLON.PBRMaterial("earthMaterial", scene);
    marketMaterial.albedoColor = this.goMarket
      ? new BABYLON.Color3(1, 1, 1) 
      : new BABYLON.Color3(0.1, 0.3, 0.9);   
    marketMaterial.metallic = 0.5; // Tạo hiệu ứng phản chiếu nhẹ.
    marketMaterial.roughness = 0.7; // Làm mềm bề mặt.
    market.material = marketMaterial;

    this.addActionToMesh(market, () => {
      this.goDetail = false;
      this.goMarket = !this.goMarket;
      this.gameOverDiv = false;
      // Xóa bóng cũ với hiệu ứng
      this.balls.forEach((b) => this.sceneSetupService.fadeOutAndDispose(b));
      this.balls = [];
      this.plansProblem = [];
      this.pickedProblems.clear();
      this.outputPlanData = null;

      // Clear balls in child component directly
      if (this.userDetailComponent) {
        this.userDetailComponent.clearBalls();
      }


      marketMaterial.albedoColor = this.goMarket
        ? new BABYLON.Color3(1, 1, 1)
        : new BABYLON.Color3(0.1, 0.3, 0.9);

      BABYLON.Animation.CreateAndStartAnimation(
        'scaleAnimation',
        market,
        'scaling',
        30, // Tốc độ khung hình
        30, // Số khung hình cho hiệu ứng
        new BABYLON.Vector3(1, 1, 1), // Kích thước ban đầu
        new BABYLON.Vector3(1.2, 1.2, 1.2), // Kích thước phóng to
        BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT,
        null,
        () => {
          // Sau khi hiệu ứng phóng to kết thúc, trả về kích thước ban đầu
          BABYLON.Animation.CreateAndStartAnimation(
            'scaleAnimationBack',
            market,
            'scaling',
            30,
            30,
            new BABYLON.Vector3(1.2, 1.2, 1.2),
            new BABYLON.Vector3(1, 1, 1)
          );
        }
      );
    });
  }

  private createCZone() {
    const cZone = BABYLON.MeshBuilder.CreateSphere('cZone', { diameter: 0.3 }, this.gameScene);
    cZone.position = new BABYLON.Vector3(-2, 3.5, 0);
      this.gameScene.onPointerDown = (evt, pickResult) => {
        if (pickResult.hit && pickResult.pickedMesh === cZone) {
          this.goMarket = false;
          this.goDetail = false;
          this.gameOverDiv = false;
          this.router.navigate(['t/chao-mung']);
        }
      }
  }

  private async disposeGame(): Promise<void> {
    this.gameEngine.dispose();
    this.gameScene.dispose();
    this.matchingProblems = [];
    this.balls = [];
    this.pickedProblems.clear();
    this.player.dispose();
  }

  private createTextureMaterial(name: string, texturePath: string, scene: BABYLON.Scene): BABYLON.StandardMaterial {
    const material = new BABYLON.StandardMaterial(name, scene);
    material.diffuseTexture = new BABYLON.Texture(texturePath, scene);
    return material;
  }
  private addActionToMesh(mesh: BABYLON.Mesh, action: () => void): void {
    mesh.actionManager = new BABYLON.ActionManager(mesh.getScene());
    mesh.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, action));
  }

  async stop(scene: BABYLON.Scene) {
    this.gameOverDiv = false;
    this.ngZone.runOutsideAngular(() => {
      // Xóa bóng cũ với hiệu ứng
      this.balls.forEach((b) => this.sceneSetupService.fadeOutAndDispose(b));
      this.balls = [];
      this.plansProblem = [];
      this.pickedProblems.clear();
      this.outputPlanData = null;
    });

    // Hiển thị prompt để người dùng nhập thêm thông tin
    this.transcriptText = window.prompt('Nhập chữ hoặc dùng micro bàn phím:', '');

    const words = this.transcriptText?.toLowerCase() || '';
    const matchingProblems = await this.allProblems.filter(
      (problem) =>
        Array.isArray(problem.tag) &&
        problem.tag.some((tag) => words.includes(tag.toLowerCase()))
    );

    this.matchingProblems = Array.from(
      new Map(matchingProblems.map((problem) => [problem.id, problem])).values()
    );

    if (this.matchingProblems.length === 0) {
      this.toastr.error('Hãy nhập thêm thông tin!', 'Không vấn đề phù hợp:');
      return;
    }

    this.createBalls(scene);
    this.toastr.info('Xanh lá cây là bình thường!', 'Đánh giá rối loạn:');
  }

  private createBalls(scene: BABYLON.Scene): void {
    const availableProblems = this.matchingProblems.filter(
      (problem) => !this.pickedProblems.has(problem.name)
    );

    if (availableProblems.length > 0) {
      const randomProblem = availableProblems[Math.floor(Math.random() * availableProblems.length)];

      // Xử lý click bóng
      const onPickCallback = (option: number) => {
        this.ngZone.run(() => {
          this.pickBall.currentTime = 0;
          this.pickBall.play();

          this.plansProblem = this.plansProblem.filter(
            (plan) => plan.name !== randomProblem.name
          );
          this.plansProblem.push({
            name: randomProblem.name,
            option,
          });

          this.pickedProblems.add(randomProblem.name);

          // Xóa bóng cũ với hiệu ứng
          this.balls.forEach((b) => this.sceneSetupService.fadeOutAndDispose(b));
          this.balls = [];

          if (this.pickedProblems.size < this.matchingProblems.length) {
            this.createBalls(scene);
          } else {
            this.endGame();
          }
        });
      };

      // Gọi service tạo bóng
      this.balls = this.sceneSetupService.createBalls(
        scene,
        randomProblem,
        this.pickedProblems,
        onPickCallback
      );
    } else if (this.pickedProblems.size > 0) {
      this.endGame();
    }
  }
  
  private endGame() {
    this.balls.forEach((ball) => ball.dispose());
    this.balls = [];
    this.gameOverDiv = true;
  }

  public replay() {
    this.gameOverDiv = false;

    this.ngZone.runOutsideAngular(() => {
      this.balls = [];
      this.plansProblem = [];
      this.pickedProblems.clear();
      this.outputPlanData = null;
    });

    this.buttonFind = true;
    this.createBalls(this.gameScene);
  }

  public async getGptGuide() {
    const medRecords = `
      Diễn biến: ${this.transcriptText || 'Chưa có'}.
      Trong đó, mức độ một số triệu chứng là: ${this.plansProblem || 'Chưa có'}.
      Lưu ý: Mức độ các triệu chứng được quy định bán định lượng như sau: 1: Hoàn toàn bình thường. 2: Mức độ nhẹ, cần theo dõi. 3: Mức độ vừa, có thể trì hoãn can thiệp. 4: Mức độ nặng, cần can thiệp ngay. 5: Mức độ rất nặng, cần can thiệp khẩn cấp.
    `;

    if (!medRecords.trim()) {
      this.toastr.error('Không có dữ liệu hợp lệ để gửi!', 'Lỗi!');
      return;
    }

    this.buttonFind = false;

    const coins = 1;

    const result = await this.openAIService.processOpenAIResponse(
      medRecords,
      this.userDocRef,
      this.userData,
      coins
    );

    if (result) {
      this.outputPlanData = result; // Gán dữ liệu sau khi xử lý
    } else {
      this.toastr.error('Không thể lấy dữ liệu!');
    }

  }

  callDoctor(): void {
    window.location.href = 'tel:0981919115';
  }

  private preloadPickBall() {
    const pickBall = new Audio();
    pickBall.src = '/assets/audio/pickCoin.mp3';
    pickBall.load();
    this.pickBall = pickBall;
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.onResize);
    document.removeEventListener('fullscreenchange', this.onResize);
    document.removeEventListener('webkitfullscreenchange', this.onResize);
    document.removeEventListener('mozfullscreenchange', this.onResize);
    document.removeEventListener('msfullscreenchange', this.onResize);

    this.gameEngine.dispose();

    if (this.unsubscribeUserData) {
      this.unsubscribeUserData();
      this.unsubscribeUserData = null;
      console.log('Unsubscribed from user data listener');
    }

  }
  
}
