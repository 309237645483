import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  canActivate,
  redirectUnauthorizedTo,
  redirectLoggedInTo,
} from '@angular/fire/auth-guard';

// Import các component
import { UserManagementComponent } from './root/user-management/user-management.component';
import { WelcomeCustomerComponent } from './root/welcome-customer/welcome-customer.component';
import { MyAccountDetailComponent } from './root/my-account-detail/my-account-detail.component';
import { RegistrationComponent } from './auth/registration/registration.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RootComponent } from './root/root.component';
import { LoginComponent } from './auth/login/login.component';
import { QuanLyThanhToanComponent } from './root/quan-ly-thanh-toan/quan-ly-thanh-toan.component';
import { CsytGuard } from './guards/csyt.guard';
import { UserDetailComponent } from './root/user-management/user-detail/user-detail.component';

// Định nghĩa các route
const routes: Routes = [
  { path: 'tai-khoan', component: MyAccountDetailComponent },
  {
    path: 'dang-ky',
    component: RegistrationComponent,
    ...canActivate(() => redirectLoggedInTo(['trang-chu'])),
  },
  { path: 'registration', redirectTo: 'dang-ky', pathMatch: 'full' },
  {
    path: 'dang-nhap',
    component: LoginComponent,
    ...canActivate(() => redirectLoggedInTo(['trang-chu'])),
  },
  { path: 'login', redirectTo: 'dang-nhap', pathMatch: 'full' },
  {
    path: 't',
    component: RootComponent,
    ...canActivate(() => redirectUnauthorizedTo(['dang-nhap'])),
    children: [
      {
        path: 'chao-mung',
        component: WelcomeCustomerComponent,
        canActivate: [CsytGuard], // Sử dụng guard ở đây
      },
      { path: 'tai-khoan-cua-toi', component: MyAccountDetailComponent },
      { path: 'quan-ly-nguoi-dung', component: UserManagementComponent },
      { path: 'quan-ly-thanh-toan', component: QuanLyThanhToanComponent },
      { path: 'user-detail', component: UserDetailComponent },
      { path: '', redirectTo: 'quan-ly-nguoi-dung', pathMatch: 'full' },
    ],
  },
  { path: 'trang-chu', redirectTo: 't', pathMatch: 'full' },
  { path: '', redirectTo: 't', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
