import {
  Component,
  OnInit,
  Input,
} from '@angular/core';
import { UserDocument } from '../../../interfaces/user-document.interface';
import {
  Firestore,
  collection,
  query,
  where,
  getDoc,
  getDocs,
  addDoc,
  DocumentData,
} from '@angular/fire/firestore';
import { Storage, getDownloadURL, ref } from '@angular/fire/storage';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SearchResult } from '../../../interfaces/searchResult.interface';
import { Product } from '../../../interfaces/product.interface';
import { Timestamp } from 'firebase/firestore';
import { DecimalPipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  @Input() userData!: UserDocument;
  
  allProducts: Product[] = [];
  allServices: any[] = [];

  selectedDocument: DocumentData | null = null;
  imageUrls: string[] = [];
  selectedImage: string | null = null;

  openImageModal(image: string): void {
    this.selectedImage = image;
  }

  closeImageModal(): void {
    this.selectedImage = null;
  }
  
  constructor(
    private firestore: Firestore,
    public docModal: NgbModal,
    private storage: Storage,
    private toastr: ToastrService,
  ) {
    this.fetchAllProducts();
    this.fetchAllServices();
  }


  private async fetchAllProducts() {
    try {
      const productsRef = collection(this.firestore, 'products');
      const productsQuery = query(productsRef, where('classify', 'array-contains', 'y tế'));
      const querySnapshot = await getDocs(productsQuery);

      // Map dữ liệu từ Firestore vào kiểu Product, bao gồm id
      this.allProducts = querySnapshot.docs.map(doc => ({
        id: doc.id, // Lấy id từ Firestore
        ...doc.data() // Gộp các trường còn lại từ dữ liệu Firestore
      }) as Product); // Chuyển kiểu thành Product

      console.log('All products loaded:', this.allProducts);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  }


  private async fetchAllServices() {
    try {
      const servicesRef = collection(this.firestore, 'services');
      const querySnapshot = await getDocs(servicesRef);
      this.allServices = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      console.log('All services loaded:', this.allServices);
    } catch (error) {
      console.error('Error fetching services:', error);
    }
  }

  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  selectDocument(document: DocumentData) {
    this.selectedDocument = document;
    this.loadImages();
    console.log('Selected document:', document);
  }

  async loadImages() {
    this.imageUrls = [];
    if (this.selectedDocument && this.selectedDocument.productImages) {
      for (const imageRef of this.selectedDocument.productImages) {
        const url = await getDownloadURL(ref(this.storage, imageRef));
        this.imageUrls.push(url);
      }
    } else if (this.selectedDocument && this.selectedDocument.serviceImages) {
      for (const imageRef of this.selectedDocument.serviceImages) {
        const url = await getDownloadURL(ref(this.storage, imageRef));
        this.imageUrls.push(url);
      }
    }
  }

  async savePlans(modal: any) {
    // Nhắc người dùng nhập số điện thoại và địa chỉ
    const clientPhone = prompt('Vui lòng nhập số điện thoại của khách hàng:');
    const address = prompt('Vui lòng nhập địa chỉ của khách hàng:');

    if (!clientPhone || !address) {
      console.warn('Số điện thoại và địa chỉ không được để trống.');
      return; // Ngừng thực thi nếu người dùng không nhập đủ thông tin
    }

    try {
      // Lấy email từ document reference (provider)
      const providerRef = this.selectedDocument?.provider;
      let providerEmail = '';

      if (providerRef) {
        const providerDoc = await getDoc(providerRef);
        if (providerDoc.exists()) {
          const providerData = providerDoc.data() as UserDocument; // Ép kiểu về UserDocument
          providerEmail = providerData.email; // Truy cập email từ UserDocument
        } else {
          console.warn('Không tìm thấy thông tin của nhà cung cấp.');
        }
      }

      const planData: SearchResult = {
        clientEmail: this.userData.email,
        createdAt: Timestamp.fromDate(new Date()),
        providerEmail: providerEmail || 'pkthienbinh@gmail.com',
        guide: null,
        history: this.selectedDocument
          ? [
              `${this.selectedDocument.propName || this.selectedDocument.name}: ${this.selectedDocument.propPrice || this.selectedDocument.price}`
            ]
          : [],
        clientPhone: clientPhone,
        address: address,
      };

      const plansCollectionRef = collection(this.firestore, 'plans');
      const docRef = await addDoc(plansCollectionRef, planData);
      this.toastr.success('Chúng tôi sẽ liên hệ lại!', 'Cảm ơn bạn!');
      modal.close();
    } catch (error) {
      console.error('Error adding plan: ', error);
    }
  }

}
