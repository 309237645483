import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Timestamp } from 'firebase/firestore'; // Import Timestamp from Firestore in Firebase v9+
import { OpenAIService } from '../../services/openai.service'; // Import OpenAIService
import { Auth } from '@angular/fire/auth';
import {
  Firestore,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
  onSnapshot,
  DocumentData,
} from '@angular/fire/firestore';
import {
  Storage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from '@angular/fire/storage';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { AuthService } from '../../services/auth.service';
import { VideoCallService } from '../../services/video-call.service';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common'; // Thêm CommonModule vào đây
import { SearchResult, Plan } from '../../interfaces/searchResult.interface';

interface Problem {
  id: string;
  name: string;
  tag: string[];
  description: string[];
}

interface UploadedFile {
  url: string;
  name: string;
  type: string;
}

@Component({
  selector: 'app-welcome-customer',
  templateUrl: './welcome-customer.component.html',
  styleUrls: ['./welcome-customer.component.css'],
})

export class WelcomeCustomerComponent implements OnInit, AfterViewInit {
  matchingProblems: Problem[] = [];
  selectedOptions: { [key: string]: number } = {};
  searchResults: SearchResult[] = [];
  selectedDocument: SearchResult | null = null;
  guideInput: string = '';
  labInput: string = '';
  historyInput: string = '';
  medications: { brandName: string; quantity: number; use: string }[] = [];
  age: number | null = null;
  gender: string | null = null;
  fullName?: string = '';
  clientPhone: string = '';
  clientEmail: string = '';
  keyword: string = '';
  plans: Plan[];
  address?: string = '';
  underlying?: string = '';
  userData: DocumentData | undefined;
  inUpdate: boolean = false;
  private unsubscribe: (() => void) | null = null;
  subcribePlans: SearchResult[];
  selectedStatuses: string[] = [];
  statusOptions = [
    { label: 'Tiếp nhận', value: '1' },
    { label: 'Đã phục vụ', value: '2' },
    { label: 'Đã thanh toán', value: '3' },
    { label: 'Theo dõi', value: '4' },
    { label: 'Đã hoàn thành', value: '5' },
    { label: 'Đặt mua thuốc', value: '6' },
  ];
  allProblems: Problem[];
  selectedFiles: File[] = [];
  currentViewedFile: UploadedFile | null = null;
  loadingFileSelectedOther: boolean;
  loadingFileList: boolean = false;
  diagnosisInput: string = '';
  remarkInput: string = '';
  importedFiles: { url: string; name: string; type: string }[];

  constructor(
    public notifier: NotifierService,
    public docModal: NgbModal,
    private firestore: Firestore, // Sử dụng Firestore mới
    private storage: Storage,
    public auth: Auth, // Đảm bảo bạn lấy dịch vụ Auth
    private openAIService: OpenAIService, // Inject OpenAIService
    public authService: AuthService,
    public videoCallService: VideoCallService,
  ) {}

  ngOnInit(): void {
    this.collectUserData();
  }

  collectUserData() {
    // Theo dõi dữ liệu người dùng từ AuthService
    this.authService.userData$.subscribe({
      next: (userData) => {
        if (userData) {
          this.userData = userData; // Gán dữ liệu người dùng vào biến
          this.loadUserPlans(userData.email); // Gọi hàm để tải kế hoạch của người dùng
          this.videoCallService.listenToCallsData(userData.email);
        } else {
          console.log('No user data available');
          // Thực hiện các hành động cần thiết nếu không có dữ liệu người dùng
        }
      },
      error: (err) => {
        console.error('Error fetching user data:', err);
      },
    });
  }

  private loadUserPlans(userEmail: string) {
    const plansQuery = query(
      collection(this.firestore, 'plans'),
      where('providerEmail', '==', userEmail),
    );

    this.unsubscribe = onSnapshot(
      plansQuery,
      (plansSnapshot) => {
        this.subcribePlans = plansSnapshot.docs.map((doc) => ({
          documentId: doc.id, // Lấy ID của document
          ...doc.data(), // Lấy các dữ liệu khác trong document
        })) as SearchResult[];
      },
      (error) => {
        console.error('Error fetching plans:', error);
      },
    );
  }

  addMedication() {
    this.medications.push({ brandName: '', quantity: 0, use: '' });
  }

  removeMedication(index: number) {
    this.medications.splice(index, 1);
  }

  async handleKeywordFilter() {
    console.log('Searching for keyword:', this.keyword); // Debugging
    this.selectedDocument = null;

    try {
      const results: SearchResult[] = [];
      const keywordLower = this.keyword
        ? this.keyword.toString().toLowerCase()
        : '';

      this.subcribePlans.forEach((data: SearchResult) => {
        const fullName = data.fullName
          ? data.fullName.toString().toLowerCase()
          : '';
        const clientPhone = data.clientPhone
          ? data.clientPhone.toString().toLowerCase()
          : '';
        const clientEmail = data.clientEmail
          ? data.clientEmail.toString().toLowerCase()
          : '';

        if (
          (fullName && fullName.includes(keywordLower)) ||
          (clientPhone && clientPhone.includes(keywordLower)) ||
          (clientEmail && clientEmail.includes(keywordLower))
        ) {
          results.push({
            ...data, // Thêm tất cả các trường dữ liệu từ tài liệu
          });
        }
      });

      this.searchResults = results.sort((a, b) => {
        const aTime = a.createdAt ? a.createdAt.toMillis() : 0;
        const bTime = b.createdAt ? b.createdAt.toMillis() : 0;
        return bTime - aTime;
      });
    } catch (error) {
      console.error('Error during search:', error);
    }
  }

  onStatusFilter() {
    if (this.selectedStatuses.length > 0) {
      this.searchResults = this.subcribePlans.filter(
        (result) =>
          result.status &&
          result.status.some((status) =>
            this.selectedStatuses.includes(status),
          ), // Kiểm tra nếu status tồn tại và nằm trong selectedStatuses
      );
    } else {
      this.searchResults = [...this.searchResults]; // Reset lại khi không có trạng thái nào được chọn
    }
    this.selectedStatuses = []; // Reset selectedStatuses
  }

  selectDocument(document: SearchResult) {
    this.selectedDocument = document;
    console.log('Selected document:', document);
  }

  deleteDocument(documentId: string) {
    if (confirm('Bạn có chắc chắn muốn xóa document này?')) {
      const documentRef = doc(this.firestore, 'plans', documentId); // Đảm bảo sử dụng đúng tham số cho doc
      deleteDoc(documentRef)
        .then(() => {
          console.log('Document deleted successfully');
          // Cập nhật searchResults để loại bỏ document đã xóa
          this.searchResults = this.searchResults.filter(
            (result) => result.documentId !== documentId, // Giả sử bạn đã đổi id thành documentId trong SearchResult
          );
          this.selectedDocument = null; // Đặt selectedDocument về null
        })
        .catch((error) => {
          console.error('Error deleting document: ', error);
        });
    }
  }

  importSelectedDocumentPersonal() {
    if (this.selectedDocument) {
      this.fullName = this.selectedDocument.fullName || null;
      this.clientEmail = this.selectedDocument.clientEmail || null;
      this.address = this.selectedDocument.address || null;
      this.underlying = this.selectedDocument.underlying || null;
      this.age = this.selectedDocument.age || null;
      this.gender = this.selectedDocument.gender || null;
      this.clientPhone = this.selectedDocument.clientPhone || null;
    }
  }

  importSelectedDocumentLab() {
    if (this.selectedDocument) {
      this.labInput = (this.selectedDocument.lab || []).join('\n');
    }
  }

  importSelectedDocumentTreatment() {
    if (this.selectedDocument) {
      this.guideInput = (this.selectedDocument.guide || []).join('\n');
      this.medications = this.selectedDocument.medications || [];
    }
  }

  importAll() {
    if (this.selectedDocument) {
      this.fullName = this.selectedDocument.fullName || null;
      this.clientEmail = this.selectedDocument.clientEmail || null;
      this.address = this.selectedDocument.address || null;
      this.underlying = this.selectedDocument.underlying || null;
      this.age = this.selectedDocument.age || null;
      this.gender = this.selectedDocument.gender || null;
      this.clientPhone = this.selectedDocument.clientPhone || null;

      // Các trường nhập từ mảng và chuyển thành chuỗi
      this.labInput = (this.selectedDocument.lab || []).join('\n');
      this.guideInput = (this.selectedDocument.guide || []).join('\n');
      this.historyInput = (this.selectedDocument.history || []).join('\n');

      // Các trường khác
      this.medications = this.selectedDocument.medications || [];
      this.selectedStatuses = this.selectedDocument.status || [];
      this.diagnosisInput = this.selectedDocument.diagnosis || null;
      this.remarkInput = this.selectedDocument.remark || null;

      // Kiểm tra xem plans có tồn tại và là một mảng không
      if (Array.isArray(this.selectedDocument.plans)) {
        // Sử dụng reduce để map các kế hoạch vào selectedOptions
        this.selectedOptions = this.selectedDocument.plans.reduce(
          (acc, plan) => {
            // Chỉ thêm vào nếu plan hợp lệ và option là số
            if (plan && plan.name && typeof plan.option === 'number') {
              acc[plan.name] = plan.option;
            } else {
              console.warn(`Invalid plan detected:`, plan); // Cảnh báo nếu có plan không hợp lệ
            }
            return acc;
          },
          {},
        );
      } else {
        this.selectedOptions = {};
      }

      this.importedFiles = this.selectedDocument.files || [];
    }

    this.inUpdate = true;
  }

  async updatePlan() {
    if (this.selectedDocument) {
      const documentId = this.selectedDocument.documentId; // Lấy documentId từ selectedDocument (ID của document Firestore)
      const updatedData = {
        plans: Object.entries(this.selectedOptions).map(([name, option]) => ({
          name,
          option,
        })),
        fullName: this.fullName,
        clientEmail: this.clientEmail,
        address: this.address,
        underlying: this.underlying,
        age: this.age,
        gender: this.gender,
        clientPhone: this.clientPhone,
        status: this.selectedStatuses,
        history: this.historyInput
          .split('\n')
          .filter((history: string) => history.trim() !== ''),
        lab: this.labInput
          .split('\n')
          .filter((lab: string) => lab.trim() !== ''),
        guide: this.guideInput
          .split('\n')
          .filter((guide: string) => guide.trim() !== ''),
        medications: this.medications,
        updatedAt: Timestamp.now(),
        files: await this.addSelectedFiles(this.selectedDocument.files || []),
        diagnosis: this.diagnosisInput,
        remark: this.remarkInput,
      };

      try {
        const documentRef = doc(
          collection(this.firestore, 'plans'),
          documentId,
        ); // Lấy documentRef dựa trên documentId
        await updateDoc(documentRef, updatedData);
        console.log('Document updated successfully');
        this.reset();
      } catch (error) {
        console.error('Error updating document:', error);
      }
    }
  }

  reset() {
    this.matchingProblems = [];
    this.selectedOptions = {};
    this.searchResults = [];
    this.selectedDocument = null;
    this.guideInput = '';
    this.labInput = '';
    this.historyInput = '';
    this.medications = [];
    this.age = null;
    this.gender = null;
    this.fullName = null;
    this.address = null;
    this.clientEmail = null;
    this.underlying = null;
    this.clientPhone = '';
    this.keyword = '';
    this.plans = [];
    this.inUpdate = false;
    this.selectedStatuses = [];
    this.diagnosisInput = '';
    this.remarkInput = '';
    this.selectedFiles = [];
    this.importedFiles = [];
    this.currentViewedFile = null;
  }

  ngAfterViewInit(): void {
    this.loadUserPlans(this.userData.email);
    this.getProblemsCollection();
  }

  getProblemsCollection(): void {
    const problemsQuery = collection(this.firestore, 'problems');

    getDocs(problemsQuery).then((problemsSnapshot) => {
      // Lưu toàn bộ problems vào biến allProblems
      this.allProblems = problemsSnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          name: data.name, // Giả sử bạn có thuộc tính này trong Firestore
          tag: data.tag, // Giả sử bạn có thuộc tính này trong Firestore
          description: data.description, // Giả sử bạn có thuộc tính này trong Firestore
          selectedOption: 1, // Giá trị option mặc định
        } as Problem;
      });
    });
  }

  searchProblems(): void {
    const words = (
      this.underlying +
      ' ' +
      this.historyInput +
      ' ' +
      this.labInput
    ).toLowerCase();

    // Sử dụng Set để loại bỏ trùng lặp
    const matchingIds = new Set<string>();

    this.allProblems.forEach((problem) => {
      if (
        problem.tag.some((tag: string) => words.includes(tag.toLowerCase()))
      ) {
        matchingIds.add(problem.id); // Thêm ID của document vào Set
      }
    });

    // Chuyển đổi Set thành mảng các document duy nhất
    this.matchingProblems = this.allProblems.filter((problem) =>
      matchingIds.has(problem.id),
    );
  }

  selectOption(problemName: string, option: number) {
    this.selectedOptions[problemName] = option;
  }

  async searchPlans() {
    this.selectedDocument = null;
    const plansArray = Object.entries(this.selectedOptions).map(
      ([name, option]) => ({ name, option }),
    );

    try {
      // Tìm kiếm tài liệu với điều kiện "=="
      const plansQuery = query(
        collection(this.firestore, 'plans'),
        where('plans', '==', plansArray), // Tìm kiếm chính xác
      );

      const querySnapshot = await getDocs(plansQuery);

      // Nếu không tìm thấy, tìm kiếm với điều kiện array-contains-any
      if (querySnapshot.empty) {
        const fallbackQuery = query(
          collection(this.firestore, 'plans'),
          where('plans', 'array-contains-any', plansArray), // Tìm kiếm các đối tượng
        );
        const fallbackSnapshot = await getDocs(fallbackQuery);

        // Lọc kết quả để chỉ lấy những document có chứa tất cả các đối tượng trong plansArray
        this.searchResults = fallbackSnapshot.docs
          .map((doc) => ({
            documentId: doc.id,
            ...(doc.data() as Omit<SearchResult, 'documentId'>),
          }))
          .filter((doc) => {
            const plans = doc.plans || [];
            return plansArray.every((plan) =>
              plans.some(
                (existingPlan) => existingPlan.name === plan.name, // Không cần kiểm tra option
              ),
            );
          }) as SearchResult[];

        // Nếu vẫn không tìm thấy kết quả, gọi hàm getOpenAIResponse()
        if (this.searchResults.length === 0) {
          this.getOpenAIResponse();
        }
      } else {
        // Nếu tìm thấy kết quả với where('plans', '==', plansArray)
        this.searchResults = querySnapshot.docs.map((doc) => ({
          documentId: doc.id,
          ...(doc.data() as Omit<SearchResult, 'documentId'>),
        })) as SearchResult[];
      }

      // Sắp xếp kết quả theo thời gian tạo (giảm dần)
      this.searchResults.sort((a, b) => {
        const aTime = a.createdAt ? a.createdAt.toMillis() : 0;
        const bTime = b.createdAt ? b.createdAt.toMillis() : 0;
        return bTime - aTime;
      });
    } catch (error) {
      console.error('Error during search:', error);
    }
  }

  getOpenAIResponse() {
    const words =
      this.underlying + ' ' + this.historyInput + ' ' + this.labInput + ' ';

    if (!words || words.trim() === '') {
      console.error('No valid input for OpenAI API');
      return;
    }

    this.openAIService.getCompletion(words).subscribe({
      next: (response) => {
        if (response && response.choices && response.choices.length > 0) {
          this.guideInput = response.choices[0].message.content;
          console.log('OpenAI Response:', this.guideInput);
        } else {
          console.error('No response received from OpenAI');
        }
      },
      error: (error) => {
        console.error('Error fetching OpenAI response:', error);
      },
      complete: () => {
        console.log('Request completed');
      },
    });
  }

  trackByFn(index, item) {
    return item; // Assuming items are unique or have a unique identifier
  }

  selectFile(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length > 0) {
      this.selectedFiles = Array.from(target.files);
    }
  }

  async addSelectedFiles(
    existingFiles: UploadedFile[],
  ): Promise<UploadedFile[]> {
    const updatedFiles = [...existingFiles]; // Giữ lại các file đã có trước đó

    for (const file of this.selectedFiles) {
      if (file.type.match(/image\/*|application\/pdf/) == null) {
        this.notifier.notify(
          'error',
          `File ${file.name} không phải file ảnh/file pdf`,
        );
        continue;
      }

      if (file.size > 10485760) {
        this.notifier.notify('error', `File ${file.name} không được quá 10MB.`);
        continue;
      }

      // Tạo tên file duy nhất
      const uniqueFileName = `${Date.now()}-${file.name}`;
      const filePath = `hospitalUpload/${uniqueFileName}`;
      const fileRef = ref(this.storage, filePath);
      const task = uploadBytesResumable(fileRef, file);

      try {
        const uploadTaskSnapshot = await task;
        const downloadURL = await getDownloadURL(uploadTaskSnapshot.ref);

        updatedFiles.push({
          url: downloadURL,
          name: uniqueFileName, // Sử dụng tên file mới
          type: file.type,
        });
      } catch (error) {
        this.notifier.notify('error', `Upload file ${file.name} thất bại.`);
        console.error('Error uploading file:', error);
      }
    }

    return updatedFiles;
  }

  async savePlans() {
    const planData: Omit<SearchResult, 'documentId'> = {
      plans: Object.entries(this.selectedOptions).map(([name, option]) => ({
        name,
        option,
      })),
      guide: this.guideInput
        .split('\n')
        .filter((guide: string) => guide.trim() !== ''),
      lab: this.labInput.split('\n').filter((lab: string) => lab.trim() !== ''),
      history: this.historyInput
        .split('\n')
        .filter((history: string) => history.trim() !== ''),
      createdAt: Timestamp.now(),
      medications: this.medications,
      age: this.age,
      gender: this.gender,
      fullName: this.fullName,
      clientEmail: this.clientEmail,
      clientPhone: this.clientPhone,
      providerEmail: this.userData.email,
      address: this.address,
      underlying: this.underlying,
      status: this.selectedStatuses,
      files: [], // Mảng files sẽ được cập nhật sau
      diagnosis: this.diagnosisInput,
      remark: this.remarkInput,
    };

    // Gọi hàm addUploadFiles để thêm các file vào planData
    planData.files = await this.addSelectedFiles(planData.files);

    try {
      const docRef = await addDoc(
        collection(this.firestore, 'plans'),
        planData,
      );
      console.log('Document written with ID: ', docRef.id);

      // Nếu cần, có thể lưu lại savedPlanData vào state hoặc biến khác
      this.notifier.notify('success', 'Lưu kế hoạch và các file thành công');
      this.reset();
    } catch (error) {
      console.error('Error adding document: ', error);
      this.notifier.notify('error', 'Lưu kế hoạch thất bại');
    }
  }

  viewSelectedFile(_t141: File) {
    const reader = new FileReader();

    // Đặt callback khi đọc file hoàn tất
    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (e.target && e.target.result) {
        this.currentViewedFile = {
          url: e.target.result as string,
          type: _t141.type.startsWith('image/') ? 'image' : 'other',
          name: _t141.name,
        };
      }
    };

    // Gọi phương thức readAsDataURL để bắt đầu đọc file
    reader.readAsDataURL(_t141);
  }

  viewUploadedFile(file: UploadedFile) {
    this.currentViewedFile = {
      url: file.url, // Sử dụng URL đã có trong đối tượng UploadedFile
      type: file.type.startsWith('image/') ? 'image' : 'other',
      name: file.name,
    };
  }

  deleteImportedFile(index: number) {
    const fileToDelete = this.importedFiles[index];
    const storageRef = ref(this.storage, fileToDelete.url);
    deleteObject(storageRef)
      .then(() => {
        console.log('File deleted successfully from storage');
        this.importedFiles.splice(index, 1);
        if (this.importedFiles.length === 0) {
          this.currentViewedFile = null;
        }
      })
      .catch((error) => {
        console.error('Error deleting file from storage:', error);
      });
  }

  deleteSelectedFile(index: number) {
    this.selectedFiles.splice(index, 1);
    if (this.selectedFiles.length === 0) {
      this.currentViewedFile = null;
    }
  }

  isFileSelected(file: File): boolean {
    return this.currentViewedFile?.name === file.name;
  }
  contentLoaded() {
    this.loadingFileSelectedOther = false;
  }

  ngOnDestroy(): void {
    if (this.unsubscribe) {
      this.unsubscribe(); // Gọi unsubscribe để hủy đăng ký
    }

    if (this.videoCallService.unsubscribeCallsData) {
      this.videoCallService.unsubscribeCallsData();
    }
  }
}
