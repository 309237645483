import { Component, OnDestroy, OnInit } from '@angular/core';
import { VideoCallService } from '../services/video-call.service';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.css'],
})
export class RootComponent implements OnInit, OnDestroy {

  constructor(
    public videoCallService: VideoCallService
  ) {}

  ngOnInit() {
    this.videoCallService.listenToCallsData();
  }

  ngOnDestroy() {
    if (this.videoCallService.unsubscribeCallsData) {
      this.videoCallService.unsubscribeCallsData();
    }
  }
}

