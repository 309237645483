export const environment = {
  production: false,
  firebase: {
    projectId: 'doctorcity-globe',
    appId: '1:1051883467266:web:3f7d3e7df606623b052d1b',
    databaseURL: 'https://doctorcity-globe.firebaseio.com',
    storageBucket: 'doctorcity-globe.appspot.com',
    locationId: 'asia-southeast2',
    apiKey: 'AIzaSyDhLWfuuKVrZB72X6Rf3X9hWp6L-36BPjM',
    authDomain: 'doctorcity-globe.firebaseapp.com',
    messagingSenderId: '1051883467266',
    measurementId: 'G-RKDNLZ3X32',
  },
  servicePrice: 100000
};
