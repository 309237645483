<div class="product-list">
  <div *ngFor="let product of allProducts" class="product-item" (click)="
              selectDocument(product);
              docModal.open(selectedDocModal, { size: 'lg', centered: true })
            ">
    <h4>{{ product.propName }}</h4>
    <p>{{ product.propTip }}</p>
    <p>{{ product.propPrice }}</p>
  </div>
</div>

<div class="product-list right-aligned">
  <div *ngFor="let service of allServices" class="product-item" (click)="
              selectDocument(service);
              docModal.open(selectedDocModal, { size: 'lg', centered: true })
            ">
    <h4>{{ service.name }}</h4>
    <p>{{ service.description }}</p>
    <p>{{ service.price }}</p>
  </div>
</div>

<ng-template #selectedDocModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        {{ selectedDocument.propName }}
      </h4>
      
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="max-height: 600px; overflow-y: auto">
      <div class="product-slideshow">

    <div ngxSlickItem *ngFor="let image of selectedDocument.productImages" class="slide">
      <img [src]="image" alt="Product Image"  class="img-fluid"/>
    </div>

</div>
      <div>
  <h6><b>Tên sản phẩm:</b></h6>
  <span *ngIf="selectedDocument.propName; else serviceName">{{ selectedDocument.propName }}</span>
  <ng-template #serviceName>
    <h4>{{ selectedDocument.name }}</h4>
  </ng-template>
  <br />

  <h6><b>Công dụng:</b></h6>
  <span *ngIf="selectedDocument.propTip; else serviceDescription">{{ selectedDocument.propTip }}</span>
  <ng-template #serviceDescription>
    <p>{{ selectedDocument.description }}</p>
  </ng-template>
  <br />

  <h6><b>Giá:</b></h6>
  <span *ngIf="selectedDocument.propPrice; else servicePrice">{{ selectedDocument.propPrice }}</span>
  <ng-template #servicePrice>
    <p>{{ selectedDocument.price }}</p>
  </ng-template>
</div>

    </div>
    <div class="modal-footer">
      <button
        class="btn btn-outline-warning"
        (click)="savePlans()"
      >
        Đặt hàng
      </button>
    </div>
  </ng-template>
