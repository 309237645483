import { Injectable } from '@angular/core';
import * as BABYLON from 'babylonjs';
import * as GUI from 'babylonjs-gui';

@Injectable({
  providedIn: 'root'
})
export class SceneSetupService {

  createLight(scene: BABYLON.Scene): void {
    const light = new BABYLON.HemisphericLight('light', new BABYLON.Vector3(1, 1, 1), scene);
    light.intensity = 1; // Điều chỉnh độ sáng của ánh sáng
  }
  
  createBalls(
    scene: BABYLON.Scene,
    randomProblem: any,
    pickedProblems: Set<string>,
    onPickCallback: (option: number) => void
  ): BABYLON.Mesh[] {
    const balls: BABYLON.Mesh[] = [];
    for (let option = 1; option <= 5; option++) {
      const ball = BABYLON.MeshBuilder.CreateCapsule(`capsule_${option}`, { 
        radius: 0.3,       // Bán kính phần tròn của con nhộng
        height: 1.5,       // Chiều dài của con nhộng
        subdivisions: 4,   // Độ mịn của hình
        tessellation: 16   // Số mặt trong mỗi phần
    }, scene);

    ball.rotation = new BABYLON.Vector3(0, 0, Math.PI / 2);

      // Vị trí ban đầu và góc xoay
      const spacing = 1; // Khoảng cách giữa các đối tượng dọc
      const initialX = -4; // Vị trí lề phải ban đầu
      const initialY = -3.5; // Vị trí y ban đầu (vẫn ở dưới mặt đất)

      ball.position = new BABYLON.Vector3(
        initialX, // X là cố định, căn vào lề phải
        initialY + (option - 1) * spacing, // Y thay đổi để xếp theo chiều dọc
        0 // Z bằng 0 để tất cả các đối tượng nằm trên mặt phẳng
      );

      ball.metadata = {
        name: randomProblem.name,
        option,
        tag: randomProblem.tag,
      };

      // Màu sắc
      const colors = {
        1: BABYLON.Color3.FromHexString('#008000'),
        2: BABYLON.Color3.FromHexString('#00FFFF'),
        3: BABYLON.Color3.FromHexString('#FFFF00'),
        4: BABYLON.Color3.FromHexString('#FFA500'),
        5: BABYLON.Color3.FromHexString('#FF0000'),
      };

      const material = new BABYLON.StandardMaterial('coinMaterial', scene);
      material.diffuseColor = colors[option];
      ball.material = material;

      // Tạo nhãn
      const advancedTexture = GUI.AdvancedDynamicTexture.CreateFullscreenUI('UI');
      const label = new GUI.TextBlock();
      label.text = randomProblem.name;
      label.color = 'white';
      label.fontSize = 20;
      advancedTexture.addControl(label);
      label.linkWithMesh(ball);
      ball.metadata.label = label;

      // Hiệu ứng trồi lên
      this.animateBallAppearance(ball);

      // Xử lý khi click vào bóng
      ball.actionManager = new BABYLON.ActionManager(scene);
      ball.actionManager.registerAction(
        new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, () => {
          onPickCallback(option);
        })
      );

      balls.push(ball);
    }

    return balls;
  }

// Hiệu ứng di chuyển từ lề ra
animateBallAppearance(ball: BABYLON.Mesh): void {
  const animation = new BABYLON.Animation(
    'moveFromSide',
    'position.x', // Thay đổi vị trí theo trục X
    30, // Tốc độ khung hình
    BABYLON.Animation.ANIMATIONTYPE_FLOAT,
    BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
  );

  const startPositionX = -4; // Bắt đầu từ ngoài màn hình bên trái (hoặc tùy chỉnh giá trị)
  const endPositionX = -1; // Vị trí kết thúc ở lề phải (hoặc giá trị mong muốn)

  const keys = [
    { frame: 0, value: startPositionX }, // Bắt đầu từ ngoài màn hình (trái)
    { frame: 30, value: endPositionX },  // Kết thúc ở vị trí mong muốn (lề phải)
  ];

  animation.setKeys(keys);
  ball.animations = [animation];
  ball.getScene().beginAnimation(ball, 0, 30, false);
}


  // Hiệu ứng fade out và xóa
  fadeOutAndDispose(ball: BABYLON.Mesh): void {
    const fadeAnimation = new BABYLON.Animation(
      'fadeOut',
      'visibility',
      30,
      BABYLON.Animation.ANIMATIONTYPE_FLOAT,
      BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
    );

    const keys = [
      { frame: 0, value: 1 }, // Hiện rõ
      { frame: 30, value: 0 }, // Biến mất
    ];

    fadeAnimation.setKeys(keys);
    ball.animations = [fadeAnimation];

    ball.getScene().beginAnimation(ball, 0, 30, false, 1, () => {
      if (ball.metadata?.label) {
        ball.metadata.label.dispose();
      }
      ball.dispose();
    });
  }
}
