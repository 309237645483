<div class="container-fluid mt-4" >
    <div class="row">
        <p style="margin: 0; padding: 0; display: flex; flex-wrap: wrap; align-items: center;">
            Tổng tiền chưa thu: {{ calculateTotalAmount() | currency:'VND':'symbol':'1.0-0' }}
            <input
                type="text"
                [(ngModel)]="keyword"
                (ngModelChange)="handleKeywordFilter()"
                placeholder="Lọc theo tên / điện thoại / email"
                style="flex-grow: 1; margin-left: 5px"
            />
            <button class="btn btn-outline-light my-2 my-sm-0" style="padding:0; margin-left: 5px"
              (click)="authService.signOut()">{{ userData.diamonds }}&#10162;</button>
        </p>
    </div>
    
    <div class="row">
      <div class="col-lg-12" style="padding:0; margin:0;">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Tên khách hàng</th>
                <th>Email</th>
                <th>Điện thoại</th>
                <th>Địa chỉ</th>
                <th>Tuổi</th>
                <th>Giới</th>
                <th>Ghi chú</th>
                <th>Tiền thuốc</th>
                <th>Đã thu</th>
                <th>Ngày tạo</th>              
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let plan of searchResults" class="item"
                [ngStyle]="{
                  'background-color': selectedDocument == plan ? 'lightblue' : ''
                }">
                  <td (click)="
                    selectDocument(plan);
                    docModal.open(selectedDocModal, { size: 'lg', centered: true })
                  ">{{plan.fullName}}</td>
                  <td>{{plan.clientEmail}}</td>
                  <td>{{plan.clientPhone}}</td>
                  <td>{{plan.address}}</td>
                  <td>{{plan.age}}</td>
                  <td>{{plan.gender}}</td>
                  <td>{{plan.remark}}</td>
                  <td>{{plan.medicationPay | currency:'VND':'symbol':'1.0-0'}}</td>
                  <td>
                    <label *ngIf="plan.medicationPay" class="form-switch">
                      <input
                        type="checkbox"
                        [checked]="plan.status?.includes('8')"
                        (change)="toggleStatus(plan, $event)"
                      />
                      <span class="slider round"></span>
                    </label>
                  </td>
                  <td>{{plan.createdAt?.toDate() | date:'dd/MM/yyyy HH:mm'}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <ng-template #selectedDocModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        {{ selectedDocument.fullName }}
      </h4>
    
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="max-height: 600px; overflow-y: auto">
      <div>
        <h6><b>Chẩn đoán:</b></h6>
        <span>{{ selectedDocument.diagnosis }}</span>
        <br />
        <h6><b>Ghi chú:</b></h6>
        <span>{{ selectedDocument.remark }}</span>
        <br />

        <h6><b>Thuốc:</b></h6>
        <ol *ngIf="selectedDocument.medications?.length > 0">
          <li
            *ngFor="
              let medication of selectedDocument.medications;
              let i = index
            "
          >
            <p>
              {{ medication.brandName }} - Số lượng: {{ medication.quantity }}
            </p>
          </li>
        </ol>
      </div>
    </div>
    <div class="modal-footer"> 
        <input
        type="number"
        class="form-control input-sm"
        [(ngModel)]="selectedDocument.medicationPay"
        placeholder="Nhập số tiền thanh toán"
      />
      <button
        class="btn btn-outline-warning"
        (click)="updatePlan(selectedDocument.medicationPay)"
      >
        Cập nhật thanh toán
      </button>      
    </div>
  </ng-template>
</div>