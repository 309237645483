  <div class="planDoc-list">
    <!-- Kiểm tra nếu có dữ liệu trong orderList -->
    <div *ngIf="orderList && orderList.length > 0; else noData">
      <div 
        *ngFor="let planDoc of orderList" 
        class="planDoc-item" 
        (click)="
          selectDocument(planDoc);
          docModal.open(selectedDocModal1, { size: 'lg', centered: true, backdrop: 'static' })
        ">
        <h5>{{ planDoc.providerEmail }}</h5>
        <p>{{ planDoc.createdAt.toDate() | date: 'dd/MM/yyyy HH:mm' }}</p>
      </div>
    </div>
    
    <!-- Nội dung hiển thị nếu không có dữ liệu -->
    <ng-template #noData>
      <div class="no-data-message">
        <p>Chưa có hồ sơ được lưu trữ</p>
      </div>
    </ng-template>
  </div>

  <div *ngIf="gameOverDiv" class="game-over">
    <button (click)="replay()">Đánh giá lại</button>
    <div *ngIf="!outputPlanData">
      <h2>Tình trạng sức khỏe</h2>      
      <ul>
        <li *ngFor="let problem of plansProblem">
          <span>{{ problem.name }}</span>
          <span class="container-status">          
            <span *ngIf="problem.option == 1" class="dot-status green"></span>         
            <span *ngIf="problem.option == 2" class="dot-status cyan"></span>
            <span *ngIf="problem.option == 3" class="dot-status yellow"></span>
            <span *ngIf="problem.option == 4" class="dot-status orange"></span>
            <span *ngIf="problem.option == 5" class="dot-status red"></span>
          </span>
        </li>
      </ul>
      <button *ngIf="buttonFind" (click)="getOpenAIResponse()">Xem hướng dẫn</button>
    </div>

    <!-- Khi đã có dữ liệu từ OpenAI -->
    <div *ngIf="outputPlanData">
      <div *ngIf="outputPlanData.diagnosis">
        <h3>Chẩn đoán</h3>
        <p>{{ outputPlanData.diagnosis }}</p>
      </div>
      <div *ngIf="outputPlanData.guide?.length">
        <h3>Hướng dẫn</h3>
        <ul class="left-aligned-list">
          <li *ngFor="let guide of outputPlanData.guide">
            {{ guide }}
          </li>
        </ul>
      </div>
      <div *ngIf="outputPlanData.lab?.length">
        <h3>Xét nghiệm cần làm</h3>
        <ul class="left-aligned-list">
          <li *ngFor="let lab of outputPlanData.lab">
            {{ lab }}
          </li>
        </ul>
      </div>
      <div *ngIf="outputPlanData.medications?.length">
        <h3>Thuốc</h3>
        <ul class="left-aligned-list">
          <li *ngFor="let med of outputPlanData.medications">
            <strong>{{ med.brandName }}</strong> - 
            Số lượng: {{ med.quantity }} - 
            Hướng dẫn: {{ med.use }}
          </li>
        </ul>
      </div>      
      <div *ngIf="outputPlanData.remark">
        <h3>Ghi chú</h3>
        <p>{{ outputPlanData.remark }}</p>
      </div>
      <button (click)="closeGameOverDiv()">Đóng</button>
    </div>
  </div>

  <ng-template #selectedDocModal1 let-modal>
    <div class="modal-header dark-modal">
      <h4 class="modal-title" id="modal-basic-title">
        {{ selectedDocument.createdAt.toDate() | date: 'dd/MM/yyyy HH:mm' }}
      </h4>

      <button 
        *ngIf="selectedDocument.clientEmail"
        class="btn btn-outline-warning"
        (click)="
          stop();
          modal.dismiss()"
      >
        Đánh giá lại tình trạng sức khỏe
      </button>
      
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >

        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body dark-modal" style="max-height: 75vh; overflow-y: auto">
      <div>     
        <h6><b>Thuốc:</b></h6>
        <ol *ngIf="selectedDocument?.medications?.length">
          <li *ngFor="let medication of selectedDocument.medications">
            <p>
              {{ medication.brandName }} - Số lượng: {{ medication.quantity }} -
              {{ medication.use }}
            </p>
          </li>
        </ol>
        <br />
        <h6><b>Hướng dẫn:</b></h6>
        <ul 
          *ngIf="selectedDocument.guide?.length > 0" 
          style="list-style-type: none; padding-left: 0; font-size: 20px;"
        >
          <li *ngFor="let guide of selectedDocument.guide">
            {{ guide }}
          </li>
        </ul>
         <h6><b>File:</b></h6>
         <div *ngIf="!loadingFileList">
          <ul *ngIf="selectedDocument.guide?.length > 0">
            <li *ngFor="let file of selectedDocument.files">
              <span (click)="viewUploadedFile(file)" style="cursor: pointer">
                  {{ file.name }}
              </span>
            </li>
          </ul>
         </div>
         
         <div *ngIf="loadingFileList" class="center2">
          <div class="loader"></div>
        </div>
        <div *ngIf="currentViewedFile">
          <div class="center2" *ngIf="loadingFileSelectedOther">
            <div class="loader"></div>
          </div>
          <ngx-doc-viewer
            *ngIf="currentViewedFile.type !== 'image'"
            [url]="currentViewedFile.url"
            viewer="url"
            (loaded)="contentLoaded()"
            [googleCheckContentLoaded]="true"
            [googleCheckInterval]="3000"
            [googleMaxChecks]="10"
            style="width: 100%; height: 50vh"
          ></ngx-doc-viewer>
          <img
            *ngIf="currentViewedFile.type === 'image'"
            [src]="currentViewedFile.url"
            [alt]="currentViewedFile.name"
            style="width: 100%; height: auto"
          />
        </div>
      </div>
    </div>
    <div class="modal-footer dark-modal">
      <button 
        *ngIf="selectedDocument.medications?.length > 0 && !selectedDocument.status?.includes('6')"
        class="btn btn-outline-warning"
        (click)="updatePlan()"
      >
        Đặt mua thuốc theo đơn
      </button>
      <div *ngIf="selectedDocument.status?.includes('6')" class="text-success">
        Đã đặt mua thuốc!
      </div>
    </div>
  </ng-template>
