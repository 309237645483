import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { Auth, sendPasswordResetEmail } from '@angular/fire/auth';
import { GoService } from '../../services/go.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  isSaveCookies = false;
  email = '';
  password = '';

  constructor(
    public auth: Auth, // Đảm bảo bạn lấy dịch vụ Auth
    public authSv: AuthService,
    public go: GoService,
    public mdSv: NgbModal,
    private cookieSv: CookieService,
  ) {
    if (this.cookieSv.check('email') && this.cookieSv.check('password')) {

      this.email = this.cookieSv.get('email');
      this.password = this.cookieSv.get('password');
      this.authSv.signInWithEmail(this.email, this.password, true);
    }
  }

  ngOnInit(): void {}

  resetPassword() {
    console.log('[LoginComponent]', 'Start reset!');

    // Sử dụng sendPasswordResetEmail từ Firebase Auth
    sendPasswordResetEmail(this.auth, this.email)
      .then(() => {

      })
      .catch((error) => {

        console.error(error);
      });
  }
}
