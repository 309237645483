import { Injectable } from '@angular/core';
import { Firestore, collection, query, where, getDocs, DocumentReference, updateDoc } from '@angular/fire/firestore';
import { UserDocument } from '../interfaces/user-document.interface';

@Injectable({
  providedIn: 'root'
})
export class DiamondService {
  constructor(private firestore: Firestore) {}

  async sendDiamond(senderEmail: string, receiverEmail: string, senderDiamonds: number): Promise<boolean> {
    try {
      const receiverQuery = query(
        collection(this.firestore, 'User'),
        where('email', '==', receiverEmail)
      );

      const receiverSnapshot = await getDocs(receiverQuery);

      if (!receiverSnapshot.empty && senderDiamonds >= 1) {
        const doc = receiverSnapshot.docs[0];
        const receiverData = doc.data() as UserDocument;
        let receiverDiamonds = receiverData.diamonds || 0;
        receiverDiamonds += 1;

        const existingGifterIndex = receiverData.gifters?.findIndex(
          (gifter) => gifter.email === senderEmail
        ) ?? -1;

        const updateData: Partial<UserDocument> = {
          diamonds: receiverDiamonds,
        };

        if (existingGifterIndex === -1) {
          const newGifter = {
            email: senderEmail,
            lastSent: new Date().toISOString(),
            seen: false,
          };
          updateData.gifters = receiverData.gifters ? [...receiverData.gifters, newGifter] : [newGifter];
        } else if (receiverData.gifters) {
          receiverData.gifters[existingGifterIndex] = {
            ...receiverData.gifters[existingGifterIndex],
            lastSent: new Date().toISOString(),
            seen: false,
          };
          updateData.gifters = receiverData.gifters;
        }

        const docRef = doc.ref as DocumentReference<UserDocument>;
        await updateDoc(docRef, updateData);
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error sending diamond:', error);
      return false;
    }
  }
}
