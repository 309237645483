import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OpenAIService {

  private apiUrl = 'https://api.openai.com/v1/chat/completions';  // OpenAI API URL
  private apiKey = 'sk-svcacct-S9K4AgC9Z1iQzcHK7z6-WK_SAYg_-uhdC1B20rbPFxqAwFQw-THSN2Nu67izEs3T3BlbkFJQN4PMYVRFjROjoludSaTLIU5c5LF8FTM7jT7GBYHxKXzets1PdMHfe7hvz1cIAA';  // Store your API key securely in a server for production use!

  constructor(private http: HttpClient) { }

  getCompletion(prompt: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.apiKey}`
    });

    const body = {
      model: 'gpt-3.5-turbo',
      messages: [
        {
          role: 'system', content: "Bạn là một trợ lý bác sĩ giúp bệnh nhân."
        },
        {
          role: 'user',
          content: `Với vai trò là trợ lý bác sĩ, hãy chuẩn bị nội dung tư vấn chuyên nghiệp, ngắn gọn cho bệnh nhân có các triệu chứng: ${prompt} để trình bác sĩ duyệt, liệt kê trực tiếp các gạch đầu dòng, không cần những lời mở đầu hay kết thúc như kiểu giao tiếp xã hội.`
        }
      ],
    };

    return this.http.post<any>(this.apiUrl, body, { headers });
  }
}
