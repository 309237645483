import {
  Component,
  NgZone,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import * as BABYLON from 'babylonjs';
import 'babylonjs-loaders';
import * as GUI from 'babylonjs-gui';
import {
  Firestore,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  DocumentReference,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import {
  Storage,
  ref,
  listAll,
  getDownloadURL,
} from '@angular/fire/storage';
import { UserDocument } from '../interfaces/user-document.interface';
import { DiamondService } from '../services/diamond.service';
import { AuthService } from '../services/auth.service';
import { User } from '@angular/fire/auth';
import { Timestamp } from 'firebase/firestore';

@Component({
  selector: 'app-visited-scene',
  template: '',
  standalone: true,
})
export class VisitedSceneComponent implements OnChanges {
  @Input() canvas!: HTMLCanvasElement;
  @Input() ngZone!: NgZone;
  @Input() email!: string; // Thêm `@Input()` để nhận email
  @Input() userData!: UserDocument;

  @Output() backButtonClicked: EventEmitter<void> = new EventEmitter<void>();

  visitedUserData!: UserDocument;
  visitedUserId!: string;

  user$: Observable<User | null>;
  userDocRef: DocumentReference | null = null;

  private isVisitedUserUIVisible: boolean = false;

  private lastUserActivityTime: number;
  private readonly INACTIVITY_THRESHOLD = 5000; // 5 seconds
  private readonly ROTATION_SPEED = 0.005;

  private audioInstance: HTMLAudioElement | null = null;

  constructor(
    private diamondService: DiamondService,
    public firestore: Firestore,
    public storage: Storage,
    public authService: AuthService,
  ) {
    this.user$ = this.authService.userSubject;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['email'] && changes['email'].currentValue !== changes['email'].previousValue) {
      this.startScene();
      this.user$.subscribe((user) => {
        if (user) {
          this.userDocRef = doc(this.firestore, `User/${user.uid}`);
        }
      });
    } else {
      this.startScene();
    }
  }

  async startScene() {
    if (this.email) {
      await this.initVisitedScene(this.email);
    }
  }

  private async initVisitedScene(email: string) {
    try {
      if (email) {
        const visitedUserQuery = query(
          collection(this.firestore, 'User'),
          where('email', '==', email)
        );

        const visitedUserSnapshot = await getDocs(visitedUserQuery);

        if (!visitedUserSnapshot.empty) {
          const visitedUserDocSnapshot = visitedUserSnapshot.docs[0];
          this.visitedUserData = visitedUserDocSnapshot.data() as UserDocument;
          this.visitedUserId = visitedUserDocSnapshot.id;

          const visitedEngine = new BABYLON.Engine(this.canvas, true);
          const scene = this.createVisitedScene(visitedEngine);

          if (scene) {
            visitedEngine.runRenderLoop(() => {
              scene.render();
            });

            window.addEventListener('resize', () => {
              visitedEngine.resize();
            });
          } else {
            console.error('Scene creation failed');
          }
        } else {
          console.error('No user document found');
        }
      }
    } catch (error) {
      console.error('Error during Firestore operation:', error);
    }
  }

  private createVisitedScene(engine: BABYLON.Engine): BABYLON.Scene | null {
    const scene = new BABYLON.Scene(engine);

    return scene;
  }


  private async addWatching() {
    try {
      if (!this.userData.watching) {
        this.userData.watching = [];
      }

      if (!this.userData.watching.includes(this.visitedUserData.email)) {
        this.userData.watching.push(this.visitedUserData.email);
        await updateDoc(this.userDocRef, {
          watching: this.userData.watching,
        });
        console.log('Đã thêm vào danh sách theo dõi');
      } else {
        console.log('In watching already!');
        const index = this.userData.watching.indexOf(this.visitedUserData.email);
        if (index > -1) {
          this.userData.watching.splice(index, 1);
          await updateDoc(this.userDocRef, {
            watching: this.userData.watching,
          });
          console.log('Đã loại bỏ khỏi danh sách theo dõi');
        }
      }
    } catch (error) {
      console.error('Lỗi khi cập nhật danh sách theo dõi:', error);
    }
  }

  onBackButtonClick() {
    this.backButtonClicked.emit(); // Phát ra sự kiện khi nhấn nút quay lại
  }

}
