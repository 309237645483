import {
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import * as BABYLON from 'babylonjs';
import 'babylonjs-loaders';
import * as GUI from 'babylonjs-gui';
import {
  Firestore,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  onSnapshot,
  DocumentChange,
  QueryDocumentSnapshot,
  DocumentData,
} from '@angular/fire/firestore';
import * as CANNON from 'cannon';
import { Observable, Subject } from 'rxjs';
import {
  Storage,
  ref,
  uploadBytesResumable,
  listAll,
  deleteObject,
  getDownloadURL,
} from '@angular/fire/storage';
import { NotifierService } from 'angular-notifier';
import { Auth, getAuth, onAuthStateChanged } from '@angular/fire/auth';
import { UserDocument } from '../../../interfaces/user-document.interface';
import { Product } from '../../../interfaces/product.interface';
import { CallData } from '../../../interfaces/call-data.interface';
import { VideoCallService } from '../../../services/video-call.service';
import { DiamondService } from '../../../services/diamond.service';
import { SceneSetupService } from '../../../services/babylon.service';
import { VisitedSceneComponent } from '../../../visited-scene/visited-scene.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

declare let webkitSpeechRecognition: any;

interface Problem {
  name: string;
  tag: string[];
}

interface Plan {
  providerEmail: string;
  plans: { name: string; option: number; tag: string[] }[];
  clientEmail: string;
  createdAt: any;
  clientPhone: string;
  fullName: string;
  guide?: string[];
  history?: string[];
  medications: { brandName: string; quantity: number; use: string }[];
}

interface DocumentGuide {
  document: QueryDocumentSnapshot<DocumentData>;
  guides: string[];
  providerEmail: string;
}

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css']
})
export class UserDetailComponent implements OnInit {
  @Input() userData!: UserDocument;
  @Input() canvas!: HTMLCanvasElement;

  @Output() tableClicked: EventEmitter<void> = new EventEmitter<void>();
  

  private buildingEngine!: BABYLON.Engine;
  private buildingScene!: BABYLON.Scene;
  private screen: BABYLON.Mesh;
  private destroy$ = new Subject<void>();
  private nurse: BABYLON.Mesh;

  private matchingTags: string[] = [];
  private productsSnapshot: any;
  private currentClient: any;

  private pickCoin: HTMLAudioElement;
  private menuSound: HTMLAudioElement;
  private isMarketUIVisible: boolean = false; // Biến để theo dõi trạng thái hiển thị của marketUI
  private currentTip: GUI.InputTextArea = null;
  private currentBuyButton: GUI.Button = null;
  private currentCancelButton: GUI.Button = null;
  private buildingCamera: BABYLON.ArcRotateCamera;
  private hasJumpedOverCeiling = false; // Biến boolean để theo dõi trạng thái nhảy
  private bannerImage: GUI.Image;
  private bannerImageR: GUI.Image;
  private audioInstance: HTMLAudioElement | null = null;
  private userDocSnapshot: any;
  private table: any;
  private screenImage: GUI.Image;
  private avataImage: GUI.Image | undefined;
  private isUpdateInfoUIVisible: boolean = false;
  private isCommunityUIVisible: boolean = false;
  
  private clientPhoneInput: GUI.InputText;
  private isSearchEmaiUIVisible: boolean = false;
  private updateInfoUI: GUI.AdvancedDynamicTexture;
  orderList: any[] = [];

  providerPlanSubscription: any;
  clientPlanSubscription: any;
  clientPlansListOpen: boolean = false;
  selectedDocument: any | null = null;

  selectedEmail!: string;

  authEmail: string;

  public plansProblem: {
    tag: any;
    name: string;
    option: number;
  }[] = [];


  constructor(
    public ngZone: NgZone,
    public firestore: Firestore,
    public storage: Storage,
    public notifier: NotifierService,
    public auth: Auth, // Đảm bảo bạn lấy dịch vụ Auth
    public videoCallService: VideoCallService,
    private diamondService: DiamondService,
    private sceneSetupService: SceneSetupService,
    public docModal: NgbModal,
  ) {}


  ngOnInit(): void {
    this.loadUserData();
  }

  ngAfterViewInit(): void {

  this.initBuildingScene();
}
  async loadUserData() {
    const auth = getAuth();
    this.listenToAuthStateChanges(auth); // Bắt đầu theo dõi trạng thái đăng nhập
  }

  private listenToAuthStateChanges(auth: Auth) {
    const userData$: Observable<UserDocument | null> = new Observable(
      (observer) => {
        onAuthStateChanged(auth, (user) => {
          if (user) {
            const userId = user.uid;
            this.authEmail = user.email!;
            this.listenToUserData(userId, observer); // Lắng nghe dữ liệu người dùng theo thời gian thực
          } else {
            console.log('No user is logged in');
            observer.next(null);
          }
        });
      },
    );

    // Subscribe to userData$ để xử lý dữ liệu người dùng
    userData$.subscribe({
      next: (userData) => {
        if (userData) {
          this.userData = userData; // Gán dữ liệu người dùng
          this.userData.diamonds = this.userData.diamonds || 0;

          // Sau khi tải xong dữ liệu người dùng, bắt đầu lắng nghe dữ liệu cuộc gọi
          this.listenToCallsData();
        }
      },
      error: (error) => {
        console.error('Error loading user data:', error);
      },
    });
  }

  // Hàm lắng nghe dữ liệu người dùng từ Firestore theo thời gian thực
  private listenToUserData(userId: string, observer: any) {
    const userDocRef = doc(this.firestore, `User/${userId}`);
    onSnapshot(
      userDocRef,
      (userDocSnapshot) => {
        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data() as UserDocument;
          this.userDocSnapshot = userDocSnapshot; // Lưu DocumentSnapshot để sử dụng sau này
          observer.next(userData); // Gửi dữ liệu realtime tới observer
        } else {
          console.log('User document not found');
          observer.next(null); // Trả về null nếu không tìm thấy document
        }
      },
      (error) => {
        console.error('Error fetching user data in realtime:', error);
      },
    );

    const clientPlansQuery = query(
      collection(this.firestore, 'plans'),
      where('clientEmail', '==', this.userData.email),
    );

    this.clientPlanSubscription = onSnapshot(
      clientPlansQuery,
      (plansSnapshot) => {
        const orderListSnapshot = plansSnapshot.docChanges();
        this.orderList = orderListSnapshot.map((change) => {
          const data = change.doc.data();
          return {
            ...data,
            createdAt: data.createdAt ? data.createdAt.toDate() : null, // Chuyển đổi Timestamp sang Date
          };
        }).sort((a, b) => {
          if (!a.createdAt) return 1;
          if (!b.createdAt) return -1;
          return b.createdAt - a.createdAt;
        });
      },
      (error) => {
        console.error('Error fetching client plans:', error);
      },
    );
  }

  // Hàm lắng nghe dữ liệu cuộc gọi từ Firestore (Realtime)
  private listenToCallsData() {
    const callsQuery = query(
      collection(this.firestore, 'calls'),
      where('clientEmail', '==', this.authEmail),
    );

    onSnapshot(
      callsQuery,
      (changes) => {
        changes.forEach((change) => {
          const data = change.data() as CallData;
          const docId = change.id;

          if (data.status === 'incoming') {
            this.videoCallService.handleIncomingCall(docId, data);
          }
        });
      },
      (error) => {
        console.error('Error during Firestore operation:', error);
      },
    );
  }

  private initBuildingScene(): void {
    this.canvas.style.width = '100%';
    this.canvas.style.height = '100%';
    this.buildingEngine = new BABYLON.Engine(this.canvas, true);
    this.buildingScene = this.createBuildingScene();
    this.buildingEngine.runRenderLoop(() => {
      this.buildingScene.render();
    });

    this.preloadSceneBuildingSound();
  }

  private createBuildingScene(): BABYLON.Scene {
    const scene = new BABYLON.Scene(this.buildingEngine);
    scene.enablePhysics(new BABYLON.Vector3(0, -9.81, 0), new BABYLON.CannonJSPlugin(true, 10, CANNON));

    this.sceneSetupService.createStaticMeshes(scene);


    const glassMaterial = new BABYLON.StandardMaterial('glassMaterial', scene);
    glassMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0.2);
    glassMaterial.alpha = 0.9;


    const tableMaterial = new BABYLON.StandardMaterial('tableMaterial', scene);
    tableMaterial.diffuseColor = new BABYLON.Color3(0.6, 0.6, 0.6);

    this.nurse = BABYLON.MeshBuilder.CreateSphere(
      'nurse',
      { diameter: 1, segments: 16 },
      scene,
    );
    this.nurse.position.set(-5, 5, 5);
    const diffuseTexture = new BABYLON.Texture(
      'assets/textures/doctor.png',
      scene,
    );
    const material = new BABYLON.StandardMaterial('doctor', scene);
    material.diffuseTexture = diffuseTexture;
    this.nurse.material = material;
    this.nurse.physicsImpostor = new BABYLON.PhysicsImpostor(
      this.nurse,
      BABYLON.PhysicsImpostor.BoxImpostor,
      { mass: 0.05, restitution: 0.9 },
      scene,
    );
    this.nurse.actionManager = new BABYLON.ActionManager(scene);
    this.nurse.actionManager.registerAction(
      new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, () => {
        if (this.clientPlansListOpen) {
          this.clientPlansListOpen = false;
        } else {
          this.clientPlansListOpen = true;
        }
      }),
    );
    if (!this.nurse.rotationQuaternion) {
      this.nurse.rotationQuaternion = BABYLON.Quaternion.Identity();
    }

    this.table = BABYLON.MeshBuilder.CreateBox(
      'table',
      { height: 1, width: 1, depth: 1 },
      scene,
    );
    this.table.position.set(-20, 3, -1);
    this.table.material = tableMaterial;
    this.table.physicsImpostor = new BABYLON.PhysicsImpostor(
      this.table,
      BABYLON.PhysicsImpostor.BoxImpostor,
      { mass: 0.1, restitution: 0.5 },
      scene,
    );
    this.table.physicsImpostor.registerOnPhysicsCollide(
      this.nurse.physicsImpostor,
      (_collider, _collidedAgainst) => {
        this.ngZone.runOutsideAngular(() => {
          if (this.audioInstance) {
            this.audioInstance.pause();
          }
          this.buildingEngine.dispose();
          scene.dispose();
          this.onTableClick();
        });
        this.clientPlansListOpen = false;
      },
    );
    let targetPosition = null;
    let isMoving = false;
    // Register onMouseMove event for capturing mouse position
    scene.onPointerMove = (evt) => {
      const pickResult = scene.pick(evt.clientX, evt.clientY);
      if (pickResult.hit) {
        targetPosition = pickResult.pickedPoint;
        isMoving = true;
      }
    };
    scene.registerBeforeRender(() => {
      if (isMoving && targetPosition) {
        const direction = targetPosition.subtract(this.nurse.position);
        if (direction.length() > 3) {
          // If the nurse is not yet at the target position
          direction.normalize();
          // Update nurse's rotation to face the target position
          const yaw = Math.atan2(direction.z, direction.x);
          const pitch = Math.atan2(
            direction.y,
            Math.sqrt(direction.x * direction.x + direction.z * direction.z),
          );
          this.nurse.rotationQuaternion =
            BABYLON.Quaternion.RotationYawPitchRoll(yaw, pitch, 0);
          // Move nurse towards target position
          const movementSpeed = 0.05; // Adjust speed factor as needed
          const moveDelta = direction.scale(movementSpeed);
          this.nurse.position.addInPlace(moveDelta);
          // Interpolate camera position smoothly
          const lerpFactor = 0.05; // Adjust this factor to increase/decrease camera lag
          const cameraPosition = BABYLON.Vector3.Lerp(
            this.buildingCamera.target,
            this.nurse.position,
            lerpFactor,
          );
          this.buildingCamera.setTarget(cameraPosition);
        } else {
          isMoving = false; // Stop moving if nurse is close enough to target
        }
      }
      // Kiểm tra vị trí y của nurse
      if (this.nurse.position.y > 8) {
        // Trên trần nhà
        if (!this.hasJumpedOverCeiling) {
          // Chưa nhảy lên trên trần nhà
          this.updateUserDiamonds((this.userData.diamonds += 1));
          this.hasJumpedOverCeiling = true; // Đánh dấu đã nhảy
        }
      } else {
        // Dưới trần nhà
        this.hasJumpedOverCeiling = false; // Đánh dấu chưa nhảy
      }
    });

      BABYLON.SceneLoader.ImportMesh(
        '',
        '/assets/',
        'vending.glb',
        scene,
        (meshes) => {
          const diffuseTexture = new BABYLON.Texture(
            'assets/textures/Vending_Machine_VendingMachine_AlbedoTrans.png',
            scene,
          );
          Promise.all([
            new Promise((resolve) =>
              diffuseTexture.onLoadObservable.addOnce(resolve),
            ),
          ])
            .then(() => {
              const material = new BABYLON.StandardMaterial(
                'vendingMaterial',
                scene,
              );
              material.diffuseTexture = diffuseTexture;
              meshes.forEach((mesh) => {
                mesh.material = material;
                mesh.physicsImpostor = new BABYLON.PhysicsImpostor(
                  mesh,
                  BABYLON.PhysicsImpostor.BoxImpostor,
                  { mass: 0, restitution: 0.9 },
                  scene,
                );
                mesh.actionManager = new BABYLON.ActionManager(scene);
                mesh.actionManager.registerAction(
                  new BABYLON.ExecuteCodeAction(
                    BABYLON.ActionManager.OnPickTrigger,
                    async () => {
                      await this.createMarketUI();
                    },
                  ),
                );
              });
              const vending = meshes[0];
              vending.position = new BABYLON.Vector3(24, 0, 3);
              vending.scaling = new BABYLON.Vector3(1, 1, 1);
              vending.rotate(BABYLON.Axis.X, -Math.PI / 2);
            })
            .catch((error) => {
              console.error('Texture failed to load:', error);
            });
        },
      );

    

      const screen = BABYLON.MeshBuilder.CreatePlane(
        'screen',
        { height: 6, width: 9 },
        scene,
      );
      this.screen = screen;
      screen.position.set(0, 4, -24.5);
      screen.rotation.y = Math.PI;
      screen.rotation.x = -Math.PI / 90;
      screen.material = glassMaterial;
      screen.actionManager = new BABYLON.ActionManager(scene);
      screen.actionManager.registerAction(
        new BABYLON.ExecuteCodeAction(
          BABYLON.ActionManager.OnPickTrigger,
          async () => {
            const fileInput = document.createElement('input');
            fileInput.type = 'file';
            fileInput.accept = 'image/*';
            const files = await new Promise<FileList | null>((resolve) => {
              fileInput.addEventListener('change', (event) => {
                resolve((event.target as HTMLInputElement).files);
              });
              fileInput.click();
            });
            if (files && files.length > 0) {
              const file = files[0];
              const imageUrl = URL.createObjectURL(file);
              this.screenImage = new GUI.Image('screenImage', imageUrl);
              this.screenImage.stretch = GUI.Image.STRETCH_FILL;
              this.screenImage.width = 1;
              this.screenImage.height = 1;
              this.screenImage.top = '200px';
              const screenUI = GUI.AdvancedDynamicTexture.CreateForMesh(screen);
              screenUI.addControl(this.screenImage);
            }
          },
        ),
      );

      const community = BABYLON.MeshBuilder.CreatePlane(
        'community',
        { height: 5, width: 4 },
        scene,
      );
      community.position.set(-10, 3.5, -24.7);
      community.rotation.y = Math.PI;
      community.actionManager = new BABYLON.ActionManager(scene);
      community.actionManager.registerAction(
        new BABYLON.ExecuteCodeAction(
          BABYLON.ActionManager.OnPickTrigger,
          () => {
            this.createCommunityUI();
          },
        ),
      );
      const communityTexture =
        GUI.AdvancedDynamicTexture.CreateForMesh(community);
      const scrollMenu = new GUI.ScrollViewer();
      scrollMenu.width = community.edgesWidth;
      scrollMenu.height = '1000px';
      scrollMenu.color = 'transparent';
      scrollMenu.background = 'rgba(50,0,0,0.2)';
      scrollMenu.isVisible = true;
      scrollMenu.top = '0px';
      scrollMenu.left = '0px';
      scrollMenu.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
      scrollMenu.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;

      const stackPanel = new GUI.StackPanel();
      stackPanel.width = scrollMenu.width;
      stackPanel.background = 'rgba(0,0,0,0.5)';
      scrollMenu.addControl(stackPanel);
      communityTexture.addControl(scrollMenu);

      const globeSearch = BABYLON.MeshBuilder.CreateSphere(
        'globeSearch',
        { diameter: 1, segments: 16 },
        scene,
      );
      globeSearch.position.set(20, 0.5, 15);
      globeSearch.material = glassMaterial;
      globeSearch.physicsImpostor = new BABYLON.PhysicsImpostor(
        globeSearch,
        BABYLON.PhysicsImpostor.BoxImpostor,
        { mass: 0.1, restitution: 0.5 },
        scene,
      );
      globeSearch.actionManager = new BABYLON.ActionManager(scene);
      globeSearch.actionManager.registerAction(
        new BABYLON.ExecuteCodeAction(
          BABYLON.ActionManager.OnPickTrigger,
          () => {
            this.searchEmailUI();
          },
        ),
      );
      
      this.buildingCamera = new BABYLON.ArcRotateCamera(
        'buildingCamera',
        0,
        10,
        10,
        this.nurse.position,
        scene,
      );
      this.buildingCamera.attachControl(this.canvas, true);
      this.buildingCamera.lowerRadiusLimit = 2;
      this.buildingCamera.upperRadiusLimit = 12;
      this.buildingCamera.wheelDeltaPercentage = 0.01;
      this.buildingCamera.lowerBetaLimit = Math.PI / 2 - Math.PI / 15;
      this.buildingCamera.upperBetaLimit = Math.PI / 2 ;

      // Enable collision detection for camera
      this.buildingCamera.checkCollisions = true;
      this.buildingCamera.collisionRadius = new BABYLON.Vector3(0.5, 0.5, 0.5);

      // Enable collision detection for the scene
      scene.collisionsEnabled = true;

      if (this.userData.watching && Array.isArray(this.userData.watching)) {
        for (const email of this.userData.watching) {
          const username = email.split('@')[0];
          const textBlock = new GUI.TextBlock();
          textBlock.text = username;
          textBlock.width = stackPanel.width;
          textBlock.height = '100px';
          textBlock.fontSize = 48;
          textBlock.color = 'white';
          stackPanel.addControl(textBlock);
        }
      }

      if (this.userData.property) {
        if (
          this.userData.property.some(
            (prop) => prop.propName === 'Bàn làm việc',
          )
        ) {
          BABYLON.SceneLoader.ImportMesh(
            '',
            '/assets/',
            'banL.glb',
            scene,
            (meshes) => {
              const diffuseTexture = new BABYLON.Texture(
                'assets/textures/RGB_5a0aa8925de14bf8b918f4221119f06f_Desk_A_diffuse.tga.png',
                scene,
              );
              Promise.all([
                new Promise((resolve) =>
                  diffuseTexture.onLoadObservable.addOnce(resolve),
                ),
              ])
                .then(() => {
                  const material = new BABYLON.StandardMaterial(
                    'banLMaterial',
                    scene,
                  );
                  material.diffuseTexture = diffuseTexture;
                  meshes.forEach((mesh) => {
                    mesh.material = material;
                    mesh.physicsImpostor = new BABYLON.PhysicsImpostor(
                      mesh,
                      BABYLON.PhysicsImpostor.BoxImpostor,
                      { mass: 0, restitution: 0.9 },
                      scene,
                    );
                  });
                  const banL = meshes[0];
                  banL.position = new BABYLON.Vector3(19, 0, -20);
                  banL.scaling = new BABYLON.Vector3(0.05, 0.05, 0.05);
                })
                .catch((error) => {
                  console.error('Texture failed to load:', error);
                });
            },
          );
        }
        if (this.userData.property.some((prop) => prop.propName === 'Giường')) {
          const bedMaterial = new BABYLON.StandardMaterial('bedMaterial', scene);
          bedMaterial.diffuseColor = new BABYLON.Color3(1, 1, 1);
          BABYLON.SceneLoader.ImportMesh(
            '',
            '/assets/',
            'bed.glb',
            scene,
            (meshes) => {
              meshes.forEach((mesh) => {
                mesh.material = bedMaterial;
                mesh.physicsImpostor = new BABYLON.PhysicsImpostor(
                  mesh,
                  BABYLON.PhysicsImpostor.BoxImpostor,
                  { mass: 0, restitution: 0.9 },
                  scene,
                );
                mesh.position = new BABYLON.Vector3(-15, 0, 21.6);
                mesh.scaling = new BABYLON.Vector3(0.18, 0.18, 0.18);
              });
            },
          );
        }
        if (
          this.userData.property.some(
            (prop) => prop.propName === 'Music Player',
          )
        ) {
          const musicPlayer = BABYLON.MeshBuilder.CreateBox(
            'musicPlayer',
            { width: 2, height: 1, depth: 0.6 },
            scene,
          );
          const playerMaterial = new BABYLON.StandardMaterial(
            'playerMaterial',
            scene,
          );
          playerMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0.2);
          musicPlayer.position.set(-10, 1, -10);
          musicPlayer.rotation.z = Math.PI / 2;
          musicPlayer.material = playerMaterial;
          musicPlayer.physicsImpostor = new BABYLON.PhysicsImpostor(
            musicPlayer,
            BABYLON.PhysicsImpostor.BoxImpostor,
            { mass: 2, restitution: 0.9 },
            scene,
          );
          musicPlayer.actionManager = new BABYLON.ActionManager(scene);
          musicPlayer.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(
              BABYLON.ActionManager.OnPickTrigger,
              () => {
                if (this.audioInstance) {
                  this.audioInstance.pause();
                }
                const fileInput = document.createElement('input');
                fileInput.type = 'file';
                fileInput.accept = 'audio/*';
                fileInput.click();

                fileInput.addEventListener('change', async (event: any) => {
                  const file = event.target.files[0];
                  if (file) {
                    const directoryPath = `music/${this.userData.email}/`;
                    const directoryRef = ref(this.storage, directoryPath);

                    try {
                      // Xóa các file cũ trong thư mục
                      const result = await listAll(directoryRef);
                      const deletePromises = result.items.map((item) =>
                        deleteObject(item),
                      );
                      await Promise.all(deletePromises);

                      // Tải file mới lên
                      const filePath = `${directoryPath}${file.name}`;
                      const fileRef = ref(this.storage, filePath);
                      const uploadTask = uploadBytesResumable(fileRef, file);

                      // Theo dõi quá trình tải file lên
                      uploadTask.on(
                        'state_changed',
                        (snapshot) => {
                          // Có thể hiển thị tiến trình tải lên nếu cần
                          const progress =
                            (snapshot.bytesTransferred / snapshot.totalBytes) *
                            100;
                          console.log('Upload is ' + progress + '% done');
                        },
                        (error) => {
                          console.error('Error uploading file:', error);
                        },
                        async () => {
                          // Khi hoàn tất tải lên, lấy URL tải về
                          const fileURL = await getDownloadURL(fileRef);
                          if (this.audioInstance) {
                            this.audioInstance.pause();
                          }
                          this.audioInstance = new Audio(fileURL);
                          this.audioInstance.loop = true;
                          this.audioInstance.play();
                        },
                      );
                    } catch (error) {
                      console.error('Error handling music files: ', error);
                    }
                  }
                });
              },
            ),
          );
        }
        if (
          this.userData.property.some((prop) => prop.propName === 'Banner 1')
        ) {
          const banner = BABYLON.MeshBuilder.CreatePlane(
            'banner',
            { height: 6, width: 10 },
            scene,
          );
          banner.position.set(18, 5, -24.9);
          banner.rotation.y = Math.PI;
          banner.actionManager = new BABYLON.ActionManager(scene);
          banner.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(
              BABYLON.ActionManager.OnPickTrigger,
              async () => {
                const fileInput = document.createElement('input');
                fileInput.type = 'file';
                fileInput.accept = 'image/*';
                fileInput.click();

                fileInput.addEventListener('change', async (event: any) => {
                  const file = event.target.files[0];
                  if (file) {
                    const directoryPath = `banner1/${this.userData.email}/`;

                    // Sử dụng ref(this.storage, directoryPath) để lấy tham chiếu đến thư mục
                    const directoryRef = ref(this.storage, directoryPath);

                    try {
                      // Lấy danh sách các file trong thư mục và xóa chúng
                      const result = await listAll(directoryRef);
                      const deletePromises = result.items.map((item) =>
                        deleteObject(item),
                      );
                      await Promise.all(deletePromises);

                      // Sau khi xóa các file cũ, tải file mới lên
                      const filePath = `${directoryPath}${file.name}`;
                      const fileRef = ref(this.storage, filePath);
                      const uploadTask = uploadBytesResumable(fileRef, file);

                      // Theo dõi tiến trình tải lên
                      uploadTask.on(
                        'state_changed',
                        (snapshot) => {
                          // Hiển thị tiến trình nếu cần
                          const progress =
                            (snapshot.bytesTransferred / snapshot.totalBytes) *
                            100;
                          console.log('Upload is ' + progress + '% done');
                        },
                        (error) => {
                          console.error('Error uploading file:', error);
                        },
                        async () => {
                          // Khi tải lên hoàn tất, lấy URL của tệp
                          const fileURL = await getDownloadURL(fileRef);

                          // Hiển thị ảnh lên banner
                          if (this.bannerImage) {
                            bannerUI.removeControl(this.bannerImage);
                          }
                          this.bannerImage = new GUI.Image(
                            'bannerImage',
                            fileURL,
                          );
                          this.bannerImage.stretch = GUI.Image.STRETCH_FILL;
                          this.bannerImage.width = 1;
                          this.bannerImage.height = 1;
                          this.bannerImage.top = '200px';
                          bannerUI.addControl(this.bannerImage);
                        },
                      );
                    } catch (error) {
                      console.error('Error listing or deleting files: ', error);
                    }
                  }
                });
              },
            ),
          );

          const bannerUI = GUI.AdvancedDynamicTexture.CreateForMesh(banner);
          const directoryPath = `banner1/${this.userData.email}/`;
          const directoryRef = ref(this.storage, directoryPath);

          listAll(directoryRef)
            .then(async (result) => {
              if (result.items.length > 0) {
                // Lấy URL của file đầu tiên
                const fileRef = result.items[0];
                const fileURL = await getDownloadURL(fileRef);

                // Hiển thị ảnh lên banner
                const bannerImage = new GUI.Image('bannerImage', fileURL);
                bannerImage.stretch = GUI.Image.STRETCH_FILL;
                bannerImage.width = 1;
                bannerImage.height = 1;
                bannerImage.top = '200px';
                bannerUI.addControl(bannerImage);
              }
            })
            .catch((error) => {
              console.error('Error getting file URLs: ', error);
            });
        }
        if (
          this.userData.property.some((prop) => prop.propName === 'Banner 2')
        ) {
          // Tạo bannerRight
          const bannerR = BABYLON.MeshBuilder.CreatePlane(
            'bannerR',
            { height: 6, width: 10 },
            scene,
          );
          bannerR.position.set(-18, 5, -24.9);
          bannerR.rotation.y = Math.PI;
          bannerR.actionManager = new BABYLON.ActionManager(scene);
          bannerR.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(
              BABYLON.ActionManager.OnPickTrigger,
              () => {
                const fileInput = document.createElement('input');
                fileInput.type = 'file';
                fileInput.accept = 'image/*';
                fileInput.click();

                fileInput.addEventListener('change', (event: any) => {
                  const file = event.target.files[0];
                  if (file) {
                    const directoryPath = `banner2/${this.userData.email}/`;
                    const directoryRef = ref(this.storage, directoryPath);

                    // Lấy danh sách các file trong thư mục và xóa chúng
                    listAll(directoryRef)
                      .then(async (result) => {
                        const deletePromises = result.items.map((item) =>
                          deleteObject(item),
                        );
                        await Promise.all(deletePromises);

                        // Sau khi xóa các file cũ, tải file mới lên
                        const filePath = `${directoryPath}${file.name}`;
                        const fileRef = ref(this.storage, filePath);
                        const uploadTask = uploadBytesResumable(fileRef, file);

                        uploadTask
                          .then(() => {
                            // Sau khi upload thành công, lấy URL
                            return getDownloadURL(fileRef);
                          })
                          .then((fileURL) => {
                            // Kiểm tra kiểu dữ liệu của fileURL
                            if (typeof fileURL === 'string') {
                              // Hiển thị ảnh lên banner
                              if (this.bannerImageR) {
                                bannerUIR.removeControl(this.bannerImageR);
                              }
                              this.bannerImageR = new GUI.Image(
                                'bannerImageR',
                                fileURL,
                              );
                              this.bannerImageR.stretch =
                                GUI.Image.STRETCH_FILL;
                              this.bannerImageR.width = 1;
                              this.bannerImageR.height = 1;
                              this.bannerImageR.top = '200px';
                              bannerUIR.addControl(this.bannerImageR);
                            } else {
                              console.error(
                                'fileURL is not a string:',
                                fileURL,
                              );
                            }
                          })
                          .catch((error) => {
                            console.error(
                              'Error uploading file or getting URL: ',
                              error,
                            );
                          });
                      })
                      .catch((error) => {
                        console.error(
                          'Error listing or deleting files: ',
                          error,
                        );
                      });
                  }
                });
              },
            ),
          );

          const bannerUIR = GUI.AdvancedDynamicTexture.CreateForMesh(bannerR);
          const directoryPath = `banner2/${this.userData.email}/`;
          const directoryRef = ref(this.storage, directoryPath);

          // Lấy danh sách các file trong thư mục
          listAll(directoryRef)
            .then(async (result) => {
              if (result.items.length > 0) {
                // Lấy URL của file đầu tiên
                const fileRef = result.items[0];
                const fileURL = await getDownloadURL(fileRef);

                // Kiểm tra kiểu dữ liệu của fileURL
                if (typeof fileURL === 'string') {
                  // Hiển thị ảnh lên banner
                  const bannerImageR = new GUI.Image('bannerImageR', fileURL);
                  bannerImageR.stretch = GUI.Image.STRETCH_FILL;
                  bannerImageR.width = 1;
                  bannerImageR.height = 1;
                  bannerImageR.top = '200px';
                  bannerUIR.addControl(bannerImageR);
                } else {
                  console.error('fileURL is not a string:', fileURL);
                }
              }
            })
            .catch((error) => {
              console.error('Error getting file URLs: ', error);
            });
        }

        
          const avata = BABYLON.MeshBuilder.CreatePlane(
            'avata',
            { height: 4, width: 4 },
            scene,
          );
          avata.position.set(-23, 6, -5);
          avata.rotation.y = -Math.PI / 2;

          const avataUI = GUI.AdvancedDynamicTexture.CreateForMesh(avata);

          const uploadButton = GUI.Button.CreateSimpleButton(
            'uploadButton',
            'Upload Ảnh',
          );
          // Thêm sự kiện DOM trực tiếp cho nút
          uploadButton.onPointerDownObservable.add(() => {
            const fileInput = document.createElement('input');
            fileInput.type = 'file';
            fileInput.accept = 'image/*';
            fileInput.click();

            fileInput.addEventListener('change', async (event: any) => {
              const file = event.target.files[0];
              if (file) {
                const directoryPath = `avata/${this.userData.email}/`;
                const directoryRef = ref(this.storage, directoryPath);

                try {
                  // Lấy danh sách các file trong thư mục và xóa chúng
                  const result = await listAll(directoryRef);
                  const deletePromises = result.items.map((item) =>
                    deleteObject(item),
                  );
                  await Promise.all(deletePromises);

                  // Sau khi xóa các file cũ, tải file mới lên
                  const filePath = `${directoryPath}${file.name}`;
                  const fileRef = ref(this.storage, filePath);
                  const uploadTask = uploadBytesResumable(fileRef, file);

                  // Theo dõi quá trình upload
                  uploadTask
                    .then(async () => {
                      const fileURL = await getDownloadURL(fileRef);

                      // Cập nhật hình ảnh
                      if (this.avataImage) {
                        avataUI.removeControl(this.avataImage);
                      }
                      this.avataImage = new GUI.Image('avataImage', fileURL);
                      this.avataImage.stretch = GUI.Image.STRETCH_UNIFORM;
                      this.avataImage.width = 0.9;
                      this.avataImage.height = 0.9;
                      this.avataImage.top = 0;
                      this.avataImage.verticalAlignment =
                        GUI.Control.VERTICAL_ALIGNMENT_CENTER;
                      avataUI.addControl(this.avataImage);
                    })
                    .catch((error) => {
                      console.error(
                        'Error uploading file or getting URL: ',
                        error,
                      );
                    });
                } catch (error) {
                  console.error('Error listing or deleting files: ', error);
                }
              }
            });
          });
          avataUI.addControl(uploadButton);

          const nameShow = BABYLON.MeshBuilder.CreatePlane(
            'nameShow',
            { height: 3, width: 4 },
            scene,
          );
          nameShow.position.set(-23, 2.5, -5);
          nameShow.rotation.y = -Math.PI / 2;
          const nameShowUI = GUI.AdvancedDynamicTexture.CreateForMesh(nameShow);
          const userName = new GUI.TextBlock();
          userName.text = this.userData.name
            ? `${this.userData.name}`
            : 'No name';
          userName.color = 'yellow';
          userName.fontSize = 96;
          nameShowUI.addControl(userName);
        
        
          const organize = BABYLON.MeshBuilder.CreatePlane(
            'organize',
            { height: 3, width: 10 },
            scene,
          );
          organize.position.set(-24.9, 8, 3);
          organize.rotation.y = -Math.PI / 2;
          organize.actionManager = new BABYLON.ActionManager(scene);
          organize.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(
              BABYLON.ActionManager.OnPickTrigger,
              async () => {
                await this.CreateUpdateInfoUI();
              },
            ),
          );

          const organizeUI = GUI.AdvancedDynamicTexture.CreateForMesh(organize);
          const organ = new GUI.TextBlock();
          organ.text = this.userData.organize
            ? `${this.userData.organize}`
            : 'No organization';
          organ.color = 'green';
          organ.fontSize = 112;
          organizeUI.addControl(organ);

          const menu = BABYLON.MeshBuilder.CreatePlane(
            'menu',
            { height: 6, width: 10 },
            scene,
          );
          menu.position.set(-24.9, 3.5, 3);
          menu.rotation.y = -Math.PI / 2;

          const menuUI = GUI.AdvancedDynamicTexture.CreateForMesh(menu);

          const userMenu = new GUI.TextBlock();
          userMenu.text = this.userData.menu
            ? `\n${this.userData.menu.join('\n')}`
            : 'No menu';
          userMenu.color = 'green';
          userMenu.fontSize = 72;
          menuUI.addControl(userMenu);
        
      }
    
    return scene;
  }

  private searchEmailUI() {
    if (this.isSearchEmaiUIVisible) {
      console.log('Search Email UI is already visible.');
      return;
    }

    const searchEmaiUI =
      GUI.AdvancedDynamicTexture.CreateFullscreenUI('searchEmaiUI');

    const emailSearchInput = new GUI.InputText();
    emailSearchInput.width = '250px';
    emailSearchInput.height = '45px';
    emailSearchInput.color = 'white';
    emailSearchInput.background = 'black';
    emailSearchInput.placeholderText = 'Search email...';
    emailSearchInput.top = '-150px';
    emailSearchInput.left = '300px';
    emailSearchInput.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    emailSearchInput.horizontalAlignment =
      GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    searchEmaiUI.addControl(emailSearchInput);

    const searchButton = GUI.Button.CreateSimpleButton(
      'searchButton',
      'Search',
    );
    searchButton.width = '150px';
    searchButton.height = '45px';
    searchButton.color = 'white';
    searchButton.background = 'black';
    searchButton.top = '-100px';
    searchButton.left = '300px';
    searchButton.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    searchButton.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    searchEmaiUI.addControl(searchButton);

    const searchResultText = new GUI.TextBlock();
    searchResultText.width = '400px';
    searchResultText.height = '60px';
    searchResultText.color = 'white';
    searchResultText.fontSize = 14;
    searchResultText.top = '-200px';
    searchResultText.left = '0px';
    searchResultText.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    searchResultText.horizontalAlignment =
      GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    searchEmaiUI.addControl(searchResultText);

    searchButton.onPointerDownObservable.add(async () => {
      const searchEmail = emailSearchInput.text.toLowerCase();
      const userQuery = query(
        collection(this.firestore, 'User'),
        where('email', '>=', searchEmail),
        where('email', '<', searchEmail + '\uf8ff'),
      );

      try {
        const querySnapshot = await getDocs(userQuery);

        if (!querySnapshot.empty) {
          // Xóa các kết quả tìm kiếm cũ nếu có
          searchResultText.text = 'Found users:';
          querySnapshot.forEach((doc) => {
            const userDoc = doc.data();
            // Tách phần trước dấu '@' của email
            const emailPrefix = userDoc.email.split('@')[0];
            // Tạo nút cho mỗi email tìm được
            const emailButton = GUI.Button.CreateSimpleButton(
              'emailButton_' + userDoc.email,
              emailPrefix,
            );
            emailButton.width = '250px';
            emailButton.height = '40px';
            emailButton.color = 'white';
            emailButton.background = 'blue';
            emailButton.top = `${
              -120 + 40 * querySnapshot.docs.indexOf(doc)
            }px`; // Sắp xếp các nút theo chiều dọc
            emailButton.verticalAlignment =
              GUI.Control.VERTICAL_ALIGNMENT_CENTER;
            emailButton.horizontalAlignment =
              GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
            emailButton.onPointerUpObservable.add(() => {              
              this.isSearchEmaiUIVisible = false;
              searchEmaiUI.dispose();
              this.visitPeople(userDoc.email);
            });
            searchEmaiUI.addControl(emailButton);
          });
        } else {
          searchResultText.text = 'No user found with that email';
        }
      } catch (error) {
        searchResultText.text = 'Error searching for user';
        console.error('Error searching for user:', error);
      }
    });

    const closeButton = GUI.Button.CreateSimpleButton('closeButton', 'Đóng');
    closeButton.width = '200px';
    closeButton.height = '50px';
    closeButton.top = '110px';
    closeButton.left = '300';
    closeButton.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    closeButton.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    closeButton.onPointerDownObservable.addOnce(() => {
      searchEmaiUI.dispose();
      this.isSearchEmaiUIVisible = false;
    });
    searchEmaiUI.addControl(closeButton);
    this.isSearchEmaiUIVisible = true;
  }

  private createCommunityUI() {
    if (this.isCommunityUIVisible) {
      console.log('Community UI is already visible.');
      return;
    }
    const communityUI =
      GUI.AdvancedDynamicTexture.CreateFullscreenUI('communityUI');

    if (this.userData.gifters) {
      this.userData.gifters = this.userData.gifters.map((gifter) => ({
        ...gifter,
        lastSent: gifter.lastSent || '1970-01-01T00:00:00Z', // Default date
        seen: gifter.seen !== undefined ? gifter.seen : true, // Default to true or false based on your requirement
      }));
      const giftersList = new GUI.ScrollViewer();
      giftersList.width = '250px';
      giftersList.height = '400px';
      giftersList.color = 'transparent';
      giftersList.background = 'rgba(50,0,0,0.2)';
      giftersList.top = '-50px';
      giftersList.left = '-300px';
      giftersList.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
      giftersList.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
      const giftersStackPanel = new GUI.StackPanel();
      giftersStackPanel.width = giftersList.width;
      giftersList.addControl(giftersStackPanel);
      communityUI.addControl(giftersList);
      giftersStackPanel.clearControls();
      this.updateGifterList(giftersStackPanel);
    }

    if (this.userData.watching) {
      const watchingList = new GUI.ScrollViewer();
      watchingList.width = '250px';
      watchingList.height = '400px';
      watchingList.color = 'transparent';
      watchingList.background = 'rgba(50,0,0,0.2)';
      watchingList.top = '-20px';
      watchingList.left = '300px';
      watchingList.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
      watchingList.horizontalAlignment =
        GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
      const watchingStackPanel = new GUI.StackPanel();
      watchingStackPanel.width = watchingList.width;
      watchingList.addControl(watchingStackPanel);
      communityUI.addControl(watchingList);
      this.updateUserList(this.userData.watching, watchingStackPanel);
      // Add filter input
      const filterInput = new GUI.InputText();
      filterInput.width = '250px';
      filterInput.height = '30px';
      filterInput.color = 'white';
      filterInput.background = 'black';
      filterInput.placeholderText = 'Filter users...';
      filterInput.top = '-250px';
      filterInput.left = '300px';
      filterInput.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
      filterInput.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
      communityUI.addControl(filterInput);

      filterInput.onTextChangedObservable.add(() => {
        const filterText = filterInput.text.toLowerCase();
        const filteredUsers = this.userData.watching.filter((email) =>
          email.toLowerCase().includes(filterText),
        );
        this.updateUserList(filteredUsers, watchingStackPanel);
      });
    }

    const closeButton = GUI.Button.CreateSimpleButton('closeButton', 'Đóng');
    closeButton.width = '200px';
    closeButton.height = '50px';
    closeButton.top = '110px';
    closeButton.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    closeButton.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    closeButton.onPointerDownObservable.addOnce(() => {
      this.isCommunityUIVisible = false;
      communityUI.dispose();
    });
    communityUI.addControl(closeButton);

    this.isCommunityUIVisible = true;
  }

  private updateGifterList(giftersStackPanel: GUI.StackPanel) {
    giftersStackPanel.clearControls();
    // Sort gifters by lastSent in descending order
    this.userData.gifters.sort(
      (a, b) => new Date(b.lastSent).getTime() - new Date(a.lastSent).getTime(),
    );
    this.userData.gifters.forEach((gifter) => {
      const username = gifter.email.split('@')[0];
      const mark = gifter.seen ? '' : '🔴';
      const button = GUI.Button.CreateSimpleButton(
        'button+' + username,
        username + mark,
      );
      button.width = giftersStackPanel.highlightLineWidth;
      button.height = '50px';
      button.color = 'white';
      button.background = 'green';
      button.onPointerClickObservable.addOnce(() => {
        this.visitPeople(gifter.email);
        this.isCommunityUIVisible = false;
        if (!gifter.seen) {
          this.updateGifterSeenStatus(gifter.email);
        }
      });
      giftersStackPanel.addControl(button);
    });
  }
  private updateGifterSeenStatus = async (gifterEmail: string) => {
    const gifterIndex = this.userData.gifters?.findIndex(
      (gifter) => gifter.email === gifterEmail,
    );

    if (
      gifterIndex !== undefined &&
      gifterIndex !== -1 &&
      this.userData.gifters
    ) {
      this.userData.gifters[gifterIndex].seen = true;

      // Simplified update using this.userDocSnapshot
      this.userDocSnapshot.ref.update({ gifters: this.userData.gifters });
    }
  };

  private updateUserList = (users: string[], stackPanel: GUI.StackPanel) => {
    stackPanel.clearControls();
    users.forEach((email) => {
      const username = email.split('@')[0];
      const button = GUI.Button.CreateSimpleButton(
        'button' + username,
        username,
      );
      button.width = stackPanel.highlightLineWidth;
      button.height = '50px';
      button.color = 'white';
      button.background = 'green';
      button.onPointerClickObservable.addOnce(() => {
        this.visitPeople(email);
        this.isCommunityUIVisible = false;
        this.menuSound.currentTime = 0;
        this.menuSound.play();
      });
      stackPanel.addControl(button);
    });
  };

  async CreateUpdateInfoUI() {
    // Kiểm tra xem marketUI có đang hiển thị không
    if (this.isUpdateInfoUIVisible) {
      console.log('Market UI is already visible.');
      return;
    }
    const menu = this.userData.menu || [];
    const name = this.userData.name || '';
    const organize = this.userData.organize || '';

    const menuList = menu.map((g) => `${g}\n`).join('');
    const updateInfoUI =
      GUI.AdvancedDynamicTexture.CreateFullscreenUI('updateInfoUI');
    this.updateInfoUI = updateInfoUI;
    const menuInput = new GUI.InputTextArea();
    menuInput.width = '400px';
    menuInput.height = '250px';
    menuInput.color = 'white';
    menuInput.background = 'rgba(50,0,0,0.2)';
    menuInput.isVisible = true;
    menuInput.top = '-100px';
    menuInput.left = '300px';
    menuInput.text = `\n${menuList}`;
    menuInput.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    menuInput.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    updateInfoUI.addControl(menuInput);

    const nameInput = new GUI.InputText();
    nameInput.width = '400px';
    nameInput.height = '50px';
    nameInput.text = name;
    nameInput.color = 'white';
    nameInput.background = 'rgba(50,0,0,0.2)';
    nameInput.top = '-200px';
    nameInput.left = '-300px';
    nameInput.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    nameInput.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    updateInfoUI.addControl(nameInput);

    const organizeInput = new GUI.InputText();
    organizeInput.width = '400px';
    organizeInput.height = '50px';
    organizeInput.text = organize;
    organizeInput.color = 'white';
    organizeInput.background = 'rgba(50,0,0,0.2)';
    organizeInput.top = '-100px';
    organizeInput.left = '-300px';
    organizeInput.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    organizeInput.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    updateInfoUI.addControl(organizeInput);

    const updateButton = GUI.Button.CreateSimpleButton(
      'updateButton',
      'Cập nhật',
    );
    updateButton.width = '200px';
    updateButton.height = '50px';
    updateButton.top = '60px';
    updateButton.left = '200px';
    updateButton.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    updateButton.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    updateInfoUI.addControl(updateButton);
    updateButton.onPointerClickObservable.addOnce(async () => {
      if (!this.userData.menu) {
        this.userData.menu = [];
      }
      if (!this.userData.name) {
        this.userData.name = '';
      }
      if (!this.userData.organize) {
        this.userData.organize = '';
      }
      // Cập nhật các trường cụ thể trong userData
      const menuArray: string[] = menuInput.text.split('\n');
      const updatedData = {
        name: nameInput.text,
        organize: organizeInput.text,
        menu: menuArray,
      };

      // Cập nhật các trường trong Firestore mà không ảnh hưởng đến các trường khác
      await this.userDocSnapshot.ref.update(updatedData);

      // Cập nhật userData để phản ánh thay đổi
      this.userData.name = nameInput.text;
      this.userData.organize = organizeInput.text;
      this.userData.menu = menuArray;
      this.isUpdateInfoUIVisible = false;
      updateInfoUI.dispose();
      this.initBuildingScene();
    });

    const shareButton = GUI.Button.CreateSimpleButton('shareButton', 'Chia sẻ');
    shareButton.width = '400px';
    shareButton.height = '50px';
    shareButton.top = '60px';
    shareButton.left = '-300px';
    shareButton.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    shareButton.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    updateInfoUI.addControl(shareButton);

    shareButton.onPointerClickObservable.addOnce(() => {
      const url = `https://mevata.space`;
      const title = `Check out ${this.userData.name}'s profile!`;
      this.shareOnSocialMedia(url, title);
    });

    const closeButton = GUI.Button.CreateSimpleButton('closeButton', 'Đóng');
    closeButton.width = '200px';
    closeButton.height = '50px';
    closeButton.top = '60px';
    closeButton.left = '400px';
    closeButton.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    closeButton.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    closeButton.onPointerDownObservable.addOnce(() => {
      this.isUpdateInfoUIVisible = false;
      updateInfoUI.dispose();
    });
    updateInfoUI.addControl(closeButton);

    this.isUpdateInfoUIVisible = true;
  }

  private shareOnSocialMedia(url: string, title: string) {
    const socialPlatforms = [
      {
        name: 'Facebook',
        url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          url,
        )}`,
      },
      {
        name: 'Twitter',
        url: `https://twitter.com/intent/tweet?url=${encodeURIComponent(
          url,
        )}&text=${encodeURIComponent(title)}`,
      },
      {
        name: 'LinkedIn',
        url: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
          url,
        )}&title=${encodeURIComponent(title)}`,
      },
    ];

    socialPlatforms.forEach((platform, index) => {
      const button = GUI.Button.CreateSimpleButton(
        `share_${platform.name}`,
        `Share on ${platform.name}`,
      );
      button.width = '200px';
      button.height = '40px';
      button.color = 'white';
      button.background = 'blue';
      button.top = `${120 + index * 50}px`;
      button.left = '-200px';
      button.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
      button.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
      button.onPointerClickObservable.add(() => {
        window.open(platform.url, '_blank');
      });
      this.updateInfoUI.addControl(button);
    });
  }

  private async visitPeople(email: string) {
  try {
    if (email) {
      this.ngZone.runOutsideAngular(() => {
        this.buildingEngine.dispose();
        this.buildingScene.dispose();
      });
      // Gán selectedEmail ngoài runOutsideAngular để đảm bảo change detection nếu cần
      this.selectedEmail = email;
    }
  } catch (error) {
    console.error('Error during Firestore operation:', error);
  }
}


  async createMarketUI() {
    if (this.isMarketUIVisible) {
      console.log('Market UI is already visible.');
      return;
    }
    try {
      const productsRef = collection(this.firestore, 'products');
      const q = query(productsRef, where('classify', 'array-contains', 'văn phòng'));

      const querySnapshot = await getDocs(q);
      this.productsSnapshot = querySnapshot.docs.map(
        (doc) => doc.data() as Product,
      );
    } catch (error) {
      console.error('Error during Firestore operation:', error);
      return;
    }

    // Sửa lỗi ở đây
    const allProducts: Product[] = this.productsSnapshot.map(
      (doc: Product) => doc,
    );

    let forProductList: Product[];
    if (this.userData && Array.isArray(this.userData.property)) {
      if (this.userData.property.length === 0) {
        forProductList = allProducts;
      } else {
        const userProduct = this.userData.property
          .filter((p) => p?.propName)
          .map((p) => p.propName);

        forProductList = allProducts.filter(
          (product) =>
            product?.propName && !userProduct.includes(product.propName),
        );
      }
    } else {
      forProductList = allProducts;
    }

    if (forProductList.length === 0) {
      console.log('No products for sale');
      return;
    }

    const marketUI = GUI.AdvancedDynamicTexture.CreateFullscreenUI('UI');
    const userProductList = new GUI.ScrollViewer();
    userProductList.width = '250px';
    userProductList.height = '400px';
    userProductList.color = 'transparent';
    userProductList.background = 'rgba(50,0,0,0.2)';
    userProductList.isVisible = true;
    userProductList.top = '-50px';
    userProductList.left = '300px';
    userProductList.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    userProductList.horizontalAlignment =
      GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;

    const stackPanel = new GUI.StackPanel();
    userProductList.addControl(stackPanel);

    try {
      forProductList.forEach((doc) => {
        const button = GUI.Button.CreateSimpleButton(
          'button' + doc.propName,
          doc.propName,
        );
        button.width = '250px';
        button.height = '50px';
        button.color = 'white';
        button.background = 'black';
        button.onPointerClickObservable.addOnce(() => {
          this.menuSound.currentTime = 0;
          this.menuSound.play();
          if (this.currentTip) {
            this.currentTip.isVisible = false;
            marketUI.removeControl(this.currentTip);
          }
          if (this.currentBuyButton) {
            this.currentBuyButton.isVisible = false;
            marketUI.removeControl(this.currentBuyButton);
          }
          if (this.currentCancelButton) {
            this.currentCancelButton.isVisible = false;
            marketUI.removeControl(this.currentCancelButton);
          }

          const propTip = new GUI.InputTextArea();
          propTip.width = '400px';
          propTip.height = '250px';
          propTip.text = `${doc.propTip}\nBenefit: ${doc.propBenefit}\nPrice: ${doc.propPrice}`;
          propTip.isVisible = true;
          propTip.color = 'white';
          propTip.background = 'rgba(50,0,0,0.2)';
          propTip.top = '-100px';
          propTip.left = '-300px';
          propTip.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
          propTip.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
          marketUI.addControl(propTip);

          const buyButton = GUI.Button.CreateSimpleButton('buyButton', 'Mua');
          buyButton.width = '200px';
          buyButton.height = '50px';
          buyButton.isVisible = true;
          buyButton.top = '60px';
          buyButton.left = '-400px';
          buyButton.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
          buyButton.horizontalAlignment =
            GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
          buyButton.onPointerDownObservable.addOnce(async () => {
            this.menuSound.currentTime = 0;
            this.menuSound.play();
            if (this.userData.diamonds < doc.propPrice) {
              console.log(
                "You don't have enough diamonds to buy this product.",
              );
              return;
            }
            try {
              this.userData.diamonds -= doc.propPrice;
              this.userData.property.push({
                propName: doc.propName,
                propTag: doc.propTag,
                classify: doc.classify,
              });
              await this.userDocSnapshot.ref.update({
                property: this.userData.property,
                diamonds: this.userData.diamonds,
              });
              buyButton.isVisible = false;
              cancelButton.isVisible = false;
              propTip.isVisible = false;
              userProductList.isVisible = false;
              this.isMarketUIVisible = false;
              await this.loadUserData();
            } catch (updateError) {
              console.error('Error updating user data:', updateError);
            }
            this.initBuildingScene();
          });
          marketUI.addControl(buyButton);

          const cancelButton = GUI.Button.CreateSimpleButton(
            'cancelButton',
            'Hủy',
          );
          cancelButton.width = '200px';
          cancelButton.height = '50px';
          cancelButton.isVisible = true;
          cancelButton.top = '60px';
          cancelButton.left = '-200px';
          cancelButton.verticalAlignment =
            GUI.Control.VERTICAL_ALIGNMENT_CENTER;
          cancelButton.horizontalAlignment =
            GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
          cancelButton.onPointerDownObservable.addOnce(() => {
            buyButton.isVisible = false;
            cancelButton.isVisible = false;
            propTip.isVisible = false;
            userProductList.isVisible = false;
            this.isMarketUIVisible = false;
          });
          marketUI.addControl(cancelButton);

          this.currentTip = propTip;
          this.currentBuyButton = buyButton;
          this.currentCancelButton = cancelButton;
        });
        stackPanel.addControl(button);
      });
    } catch (error) {
      console.error('Error creating scroll menu:', error);
    }

    marketUI.addControl(userProductList);
    this.isMarketUIVisible = true;
  }

  private async sendDiamond(email: string) {
    const success = await this.diamondService.sendDiamond(
      this.userData.email,
      email,
      this.userData.diamonds
    );
    
    if (success) {
      this.updateUserDiamonds((this.userData.diamonds -= 1));
    }
  }

  private async updateUserDiamonds(newDiamonds: number) {
    try {
      await this.userDocSnapshot.ref.update({ diamonds: newDiamonds });
    } catch (error) {
      console.error('Error updating user diamonds:', error);
    }
  }

  private onResize = () => {
    if (this.buildingEngine) {
      this.buildingEngine.resize();
    }
    this.adjustUI();
  };

  private adjustUI() {
    const buttons = document.querySelectorAll('button');


    buttons.forEach((button) => {
      (button as HTMLElement).style.width = '100%';
      (button as HTMLElement).style.bottom = '10px'; // Điều chỉnh khoảng cách từ đáy
    });

    const resultContainer = document.getElementById('resultContainer');
    if (resultContainer) {
      resultContainer.style.width = 'auto';
      resultContainer.style.height = 'auto';
    }
  }
  private preloadPickCoin() {
    const pickCoin = new Audio();
    pickCoin.src = '/assets/audio/pickCoin.mp3';
    pickCoin.load();
    this.pickCoin = pickCoin;
  }
  private preloadSceneBuildingSound() {
    const menuSound = new Audio();
    menuSound.src = '/assets/audio/menu.mp3';
    menuSound.load();
    this.menuSound = menuSound;
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.onResize);
    document.removeEventListener('fullscreenchange', this.onResize);
    document.removeEventListener('webkitfullscreenchange', this.onResize);
    document.removeEventListener('mozfullscreenchange', this.onResize);
    document.removeEventListener('msfullscreenchange', this.onResize);

    if (this.buildingEngine) {
      this.buildingEngine.dispose();
    }

    this.destroy$.next();
    this.destroy$.complete();

    if (this.bannerImage && this.bannerImage.source) {
      URL.revokeObjectURL(this.bannerImage.source);
    }

    if (this.bannerImageR && this.bannerImageR.source) {
      URL.revokeObjectURL(this.bannerImageR.source);
    }
    if (this.screenImage && this.screenImage.source) {
      URL.revokeObjectURL(this.screenImage.source);
    }
    if (this.avataImage && this.avataImage.source) {
      URL.revokeObjectURL(this.avataImage.source);
    }
    if (this.providerPlanSubscription) {
      this.providerPlanSubscription();
    }
    if (this.clientPlanSubscription) {
      this.clientPlanSubscription();
    }
  }

  onBackButtonClicked() {
    // Gọi phương thức của component cha khi nhận được sự kiện từ con
    this.initBuildingScene();
  }

  onTableClick() {
    this.tableClicked.emit(); // Phát ra sự kiện khi nhấn nút quay lại
  }

  selectDocument(document: any) {
    this.selectedDocument = document;
    console.log('Selected document:', document);
  }

}
