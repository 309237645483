import {
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  Input,
} from '@angular/core';
import { UserDocument } from '../../../interfaces/user-document.interface';
import {
  Firestore,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  onSnapshot,
  DocumentChange,
  QueryDocumentSnapshot,
  DocumentData,
} from '@angular/fire/firestore';
import {
  Storage,
  ref,
  uploadBytesResumable,
  listAll,
  deleteObject,
  getDownloadURL,
} from '@angular/fire/storage';
import { NotifierService } from 'angular-notifier';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

interface Plan {
  providerEmail: string;
  plans: { name: string; option: number; tag: string[] }[];
  clientEmail: string;
  createdAt: any;
  clientPhone: string;
  fullName: string;
  address: string;
  guide?: string[];
  history?: string[];
  medications: { brandName: string; quantity: number; use: string }[];
}

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  @Input() userData!: UserDocument;

  
  allProducts: any[] = [];
  allServices: any[] = [];

  selectedDocument: any | null = null;
  
  constructor(
    private firestore: Firestore,
    private storage: Storage,
    public notifier: NotifierService,
    public docModal: NgbModal,
  ) {
    this.fetchAllProducts();
    this.fetchAllServices();
  }


  private async fetchAllProducts() {
    try {
      const productsRef = collection(this.firestore, 'products');
      const productsQuery = query(productsRef, where('classify', 'array-contains', 'y tế'));
      const querySnapshot = await getDocs(productsQuery);
      this.allProducts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      console.log('All products loaded:', this.allProducts);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  }
  private async fetchAllServices() {
    try {
      const servicesRef = collection(this.firestore, 'services');
      const querySnapshot = await getDocs(servicesRef);
      this.allServices = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      console.log('All services loaded:', this.allServices);
    } catch (error) {
      console.error('Error fetching services:', error);
    }
  }

  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  selectDocument(document: any) {
    this.selectedDocument = document;
    console.log('Selected document:', document);
  }

  async savePlans() {
    // Nhắc người dùng nhập số điện thoại và địa chỉ
    const clientPhone = prompt('Vui lòng nhập số điện thoại của khách hàng:');
    const address = prompt('Vui lòng nhập địa chỉ của khách hàng:');

    if (!clientPhone || !address) {
      console.warn('Số điện thoại và địa chỉ không được để trống.');
      this.notifier.notify('warning', 'Vui lòng nhập đầy đủ số điện thoại và địa chỉ.');
      return; // Ngừng thực thi nếu người dùng không nhập đủ thông tin
    }

    const planData: Plan = {
      clientEmail: this.userData.email,
      createdAt: new Date(),
      plans: [],
      providerEmail: 'pkthienbinh@gmail.com', // Thay thế bằng email của người cung cấp dịch vụ
      guide: null,
      history: this.selectedDocument
        ? [
            `${this.selectedDocument.propName || this.selectedDocument.name}: ${this.selectedDocument.propPrice || this.selectedDocument.price}`
          ]
        : [],
      clientPhone: clientPhone,
      fullName: '',
      address: address,
      medications: [],
    };

    try {
      const plansCollectionRef = collection(this.firestore, 'plans');
      const docRef = await addDoc(plansCollectionRef, planData);
      console.log('Plan saved with ID: ', docRef.id);
      this.notifier.notify('success', `Đặt yêu cầu thành công!`);
    } catch (error) {
      console.error('Error adding plan: ', error);
    }
  }

}
