import { Injectable } from '@angular/core';
import { Firestore, query, where, doc, setDoc, collection, onSnapshot, updateDoc, addDoc } from '@angular/fire/firestore';
import { NotifierService } from 'angular-notifier';
import * as GUI from 'babylonjs-gui';
import { CallData } from '../interfaces/call-data.interface';

@Injectable({
  providedIn: 'root'
})
export class VideoCallService {
  private peerConnection: RTCPeerConnection | null = null;
  public localStream: MediaStream;
  public localVideoFrame: HTMLVideoElement;
  private remoteVideo: HTMLVideoElement;
  private callDocId: string;
  private callSubscription: () => void | null = null;
  private callCandidateSubscription: () => void | null = null;
  private subscribeCallAnswer: () => void | null = null;
  private unsubscribeCandidates: (() => void) | undefined;

  constructor(
    private firestore: Firestore,
    private notifier: NotifierService
  ) {}

  // Hàm lắng nghe dữ liệu cuộc gọi từ Firestore (Realtime)
  public unsubscribeCallsData: () => void;

  public listenToCallsData(email: string) {
    const callsQuery = query(
      collection(this.firestore, 'calls'),
      where('clientEmail', '==', email),
    );

    this.unsubscribeCallsData = onSnapshot(
      callsQuery,
      (changes) => {
        changes.forEach((change) => {
          const data = change.data() as CallData;
          const docId = change.id;

          if (data.status && data.status === 'incoming') {
            this.handleIncomingCall(docId, data);
          }
        });
      },
      (error) => {
        console.error('Error during Firestore operation:', error);
      },
    );
  }
  
  async initiateVideoCall(callerEmail: string, receiverEmail: string) {
    this.callDocId = '${callerEmail}_${receiverEmail}';
    try {
      await this.setupLocalVideo();
      const offer = await this.peerConnection.createOffer();
      await this.peerConnection.setLocalDescription(offer);

      const callDocRef = doc(this.firestore, 'calls', this.callDocId);
      await setDoc(callDocRef, {
        offer: offer,
        providerEmail: callerEmail,
        clientEmail: receiverEmail,
        status: 'incoming'
      });

      this.listenForCallUpdates(callDocRef);
    } catch (error) {
      console.error('Error initializing video call:', error);
      this.notifier.notify('error', 'Unable to initialize call. Please check camera/microphone access and try again.');
      this.endVideoCall();
    }
  }

  private listenForCallUpdates(callDocRef) {
    this.callSubscription = onSnapshot(callDocRef, async (snapshot) => {
        const callData = snapshot.data() as CallData;
        if (callData && callData.status === 'accepted' && callData.answer) {
          const answer = new RTCSessionDescription(callData.answer);
          await this.peerConnection.setRemoteDescription(answer);
        }
        if (callData && callData.status === 'rejected') {
          this.endVideoCall();
        }
        if (callData && callData.status === 'ended') {
          this.endVideoCall();
        }
      });
  }

  private async setupLocalVideo() {
    this.localStream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true,
    });
    this.localVideoFrame = document.getElementById(
      'localVideo',
    ) as HTMLVideoElement;
    this.localVideoFrame.srcObject = this.localStream;
    this.localVideoFrame.muted = true;
    this.localVideoFrame
      .play()
      .catch((error) => console.error('Error playing local video:', error));

    this.setupPeerConnection();
  }

  private setupPeerConnection(): void {
    const configuration = {
      iceServers: [
        { urls: ['stun:stun.l.google.com:19302'] },
        { urls: ['stun:stun1.l.google.com:19302'] },
      ],
    };
    this.peerConnection = new RTCPeerConnection(configuration);

    this.localStream
      .getTracks()
      .forEach((track) =>
        this.peerConnection.addTrack(track, this.localStream),
      );

    this.peerConnection.ontrack = (event: RTCTrackEvent) => {
      if (event.streams && event.streams[0]) {
        this.setupRemoteVideo(event.streams[0]);
      }
    };
    this.handlePeerConnectionRespond();
  }

  private setupRemoteVideo(stream: MediaStream): void {
    this.remoteVideo = document.getElementById(
      'remoteVideo',
    ) as HTMLVideoElement;
    if (this.remoteVideo) {
      this.remoteVideo.srcObject = stream;
      this.remoteVideo.muted = false;
      this.remoteVideo
        .play()
        .catch((error) => console.error('Error playing remote video:', error));
    }
  }

  private handlePeerConnectionRespond() {
    this.peerConnection.onicecandidate = (event) => {
      if (event.candidate) {
        // Tạo docRef cho collection 'candidates'
        const candidatesCollectionRef = collection(
          this.firestore,
          'calls',
          this.callDocId,
          'candidates',
        );

        // Sử dụng addDoc để thêm candidate vào collection
        addDoc(candidatesCollectionRef, event.candidate.toJSON())
          .then(() => {
            console.log('Candidate added successfully');
          })
          .catch((error) => {
            console.error('Error adding candidate:', error);
          });
      }
    };

    this.peerConnection.onconnectionstatechange = () => {
      if (
        this.peerConnection.connectionState === 'disconnected' ||
        this.peerConnection.connectionState === 'failed'
      ) {
        this.notifier.notify(
          'error',
          'Connection interrupted. Please try again.',
        );
        this.endVideoCall();
      }
    };

    this.peerConnection.onnegotiationneeded = async () => {
      try {
        const offer = await this.peerConnection.createOffer();
        await this.peerConnection.setLocalDescription(offer);
        // Send the offer to the remote peer
        // You'll need to implement this part based on your signaling mechanism
      } catch (error) {
        console.error('Error during negotiation: ', error);
      }
    };

    const callDocRef = doc(this.firestore, 'calls', this.callDocId);

    this.subscribeCallAnswer = onSnapshot(
      callDocRef,
      (doc) => {
        const data = doc.data() as CallData;
        if (data && data.answer) {
          const remoteDesc = new RTCSessionDescription(data.answer);
          this.peerConnection.setRemoteDescription(remoteDesc);
        }
      },
      (error) => {
        console.error('Error fetching call data:', error);
      },
    );

    const candidatesCollectionRef = collection(
      this.firestore,
      'calls',
      this.callDocId,
      'candidates',
    );

    this.callCandidateSubscription = onSnapshot(
      candidatesCollectionRef,
      (snapshot) => {
        const addedCandidates = snapshot
          .docChanges()
          .filter((change) => change.type === 'added');

        addedCandidates.forEach(async (change) => {
          const candidateData = change.doc.data();
          const candidate = new RTCIceCandidate(candidateData);
          await this.peerConnection.addIceCandidate(candidate);
        });
      },
      (error) => {
        console.error('Error fetching call candidates:', error);
      },
    );
  }

  async endVideoCall() {
    // Unsubscribe from call-related subscriptions if they exist
    if (this.callSubscription) {
      this.callSubscription();
    }

    if (this.callCandidateSubscription) {
      this.callCandidateSubscription();
    }

    if (this.subscribeCallAnswer) {
      this.subscribeCallAnswer();
    }

    // Close the peer connection and nullify its events
    if (this.peerConnection) {
      this.peerConnection.onicecandidate = null;
      this.peerConnection.onicegatheringstatechange = null;
      this.peerConnection.onsignalingstatechange = null;
      this.peerConnection.ontrack = null;

      this.peerConnection.getTransceivers().forEach((transceiver) => {
        transceiver.stop();
      });

      this.peerConnection.close();
      this.peerConnection = null;
    }

    // Update the call status in Firestore
    try {
      const callDocRef = doc(this.firestore, 'calls', this.callDocId); // Tạo một tham chiếu đến tài liệu cuộc gọi
      await updateDoc(callDocRef, {
        status: 'ended',
      });
    } catch (error) {
      console.error('Error ending call:', error);
      this.notifier.notify('error', 'Lỗi khi kết thúc cuộc gọi');
      return; // Exit the function if updating Firestore fails
    }

    // Clean up video elements
    if (this.localVideoFrame) {
      this.localVideoFrame.srcObject = null;
      this.localVideoFrame = null;
    }

    if (this.remoteVideo) {
      this.remoteVideo.srcObject = null;
    }

    // Stop all local media tracks
    if (this.localStream) {
      this.localStream.getTracks().forEach((track) => track.stop());
    }

    // Notify user of call end success
    this.notifier.notify('success', 'Cuộc gọi đã kết thúc');
  }

  async handleIncomingCall(docId: string, callData: CallData) {
    this.callDocId = docId;
    try {
      if (!callData || callData.status !== 'incoming') {
        throw new Error('Invalid call data');
      }

      const isAccepted = await this.incomingCallInform(callData.providerEmail);

      if (isAccepted) {
        await this.setupLocalVideo();

        const offer = new RTCSessionDescription(callData.offer);
        await this.peerConnection.setRemoteDescription(offer);

        const answer = await this.peerConnection.createAnswer();
        await this.peerConnection.setLocalDescription(answer);

        const callDocRef = doc(this.firestore, 'calls', docId); // Tạo tham chiếu đến tài liệu cuộc gọi
        await setDoc(
          callDocRef,
          {
            status: 'accepted',
            answer: answer,
          },
          { merge: true },
        ); // Sử dụng merge để cập nhật tài liệu mà không ghi đè toàn bộ

        await this.handleIncomingCandidates(docId);

        // Lắng nghe thay đổi trên tài liệu Firestore để nhận biết kết thúc cuộc gọi
        this.callSubscription = onSnapshot(callDocRef, async (snapshot) => {
          const callData = snapshot.data() as CallData;
          if (callData && callData.status === 'ended') {
            this.endVideoCall();
          }
        });
      } else {
        const callDocRef = doc(this.firestore, 'calls', docId);
        await setDoc(
          callDocRef,
          {
            status: 'rejected',
          },
          { merge: true },
        ); // Sử dụng merge để cập nhật trạng thái
      }
    } catch (error) {
      console.error('Error handling incoming call:', error);
    }
  }

  private incomingCallInform(caller: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const dialogUI = GUI.AdvancedDynamicTexture.CreateFullscreenUI('UI');

      const dialog = new GUI.Rectangle();
      dialog.width = '350px';
      dialog.height = '250px';
      dialog.background = 'grey';
      dialog.top = '0px';
      dialog.left = '0px';

      const text = new GUI.TextBlock();
      text.text = `Cuộc gọi từ ${caller}`;
      text.color = 'white';
      text.top = '-50px';
      text.left = '0px';
      text.fontSize = 18;
      dialog.addControl(text);

      const acceptButton = GUI.Button.CreateSimpleButton(
        'acceptButton',
        'Accept',
      );
      acceptButton.width = '150px';
      acceptButton.height = '40px';
      acceptButton.color = 'white';
      acceptButton.background = 'green';
      acceptButton.onPointerUpObservable.add(() => {
        dialogUI.dispose();
        resolve(true);
      });

      const rejectButton = GUI.Button.CreateSimpleButton(
        'rejectButton',
        'Reject',
      );
      rejectButton.width = '150px';
      rejectButton.height = '40px';
      rejectButton.color = 'white';
      rejectButton.background = 'red';
      rejectButton.onPointerUpObservable.add(() => {
        dialogUI.dispose();
        resolve(false);
      });

      const buttonPanel = new GUI.StackPanel();
      buttonPanel.isVertical = false;
      buttonPanel.height = '50px';
      buttonPanel.top = '50px';
      buttonPanel.left = '0px';
      buttonPanel.addControl(acceptButton);
      buttonPanel.addControl(rejectButton);

      dialog.addControl(buttonPanel);
      dialogUI.addControl(dialog);
    });
  }

  private async handleIncomingCandidates(docId: string) {
    const candidatesCollectionRef = collection(
      this.firestore,
      'calls',
      docId,
      'candidates',
    );

    // Lắng nghe thay đổi trong tập hợp các ICE candidates
    this.unsubscribeCandidates = onSnapshot(
      candidatesCollectionRef,
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            const candidateData = change.doc.data();
            const candidate = new RTCIceCandidate(candidateData);
            this.peerConnection.addIceCandidate(candidate).catch((e) => {
              console.error('Error adding received ICE candidate:', e);
            });
          }
        });
      },
      (error) => {
        console.error('Error fetching ICE candidates:', error);
      },
    );
  }

  public unsubscribeFromCandidates() {
    if (this.unsubscribeCandidates) {
      this.unsubscribeCandidates();
      this.unsubscribeCandidates = undefined; // Đặt lại thuộc tính để tránh gọi lại
    }
  }
}