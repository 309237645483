<div class="container-fluid">
  <div class="row" style="padding: 0; margin: 5px">
    <p
      style="
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      "
    >
      <button class="button" (click)="reset()" style="margin-right: 10px">
        Hồ sơ mới
      </button>
      <input
        type="text"
        id="fullNameInput"
        [(ngModel)]="fullName"
        placeholder="Nhập tên"
        style="margin-right: 10px"
      />
      <input
        type="number"
        id="ageInput"
        [(ngModel)]="age"
        placeholder="Nhập tuổi"
        style="margin-right: 10px"
      />
      <label style="margin-right: 10px">
        <input type="radio" name="gender" [(ngModel)]="gender" value="male" />
        Nam
      </label>
      <label style="margin-right: 10px">
        <input type="radio" name="gender" [(ngModel)]="gender" value="female" />
        Nữ
      </label>
      <input
        type="text"
        id="clientPhoneInput"
        [(ngModel)]="clientPhone"
        placeholder="Nhập số điện thoại"
        style="margin-right: 10px"
      />
      <input
        type="text"
        id="clientEmailInput"
        [(ngModel)]="clientEmail"
        placeholder="Nhập email"
        style="margin-right: 10px"
      />
      <input
        type="text"
        id="addressInput"
        [(ngModel)]="address"
        placeholder="Nhập địa chỉ"
        style="margin-right: 10px"
      />
      <input
        type="text"
        id="underlyingInput"
        [(ngModel)]="underlying"
        placeholder="Nhập bệnh nền"
        style="flex-grow: 1; margin-left: 5px"
      />
      <button class="btn btn-outline-light my-2 my-sm-0" style="padding:0; margin-left: 5px"
    (click)="authService.signOut()">{{ userData.diamonds }}&#10162;</button>
    </p>
  </div>
  <div
    class="row"
    style="display: flex; justify-content: space-between; width: 100%"
  >
    <div class="col-lg-4">
      <textarea
        id="historyInput"
        [(ngModel)]="historyInput"
        rows="3"
        placeholder="Nhập diễn biến"
        (keyup.enter)="searchProblems()"
        style="width: 100%"
      ></textarea>

      <textarea
        id="labInput"
        [(ngModel)]="labInput"
        rows="20"
        placeholder="Nhập cận lâm sàng"
        style="width: 100%"
      ></textarea>
      <br />
      <div class="row">
        <mat-form-field appearance="fill">
          <mat-label>Chọn trạng thái</mat-label>
          <mat-select [(ngModel)]="selectedStatuses" multiple>
            <mat-option
              *ngFor="let option of statusOptions"
              [value]="option.value"
            >
              {{ option.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="col-lg-2">
      <div
        class="row"
        style="display: flex; justify-content: space-between; width: 100%"
      >
        <button
          *ngIf="historyInput || underlying || labInput"
          class="btn btn-outline-info col-12"
          (click)="searchPlans()"
        >
          AI Guide
        </button>
      </div>
      <div
        class="row"
        *ngIf="matchingProblems.length > 0"
        style="display: flex; justify-content: space-between; width: 100%"
      >
        <h4>Đánh giá rối loạn:</h4>
        <ul>
          <li *ngFor="let problem of matchingProblems">
            {{ problem.name }}
            <div>
              <button
                *ngFor="let option of [1, 2, 3, 4, 5]"
                (click)="selectOption(problem.name, option)"
                [class.selected]="selectedOptions[problem.name] === option"
              >
                {{ option }}
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="col-lg-4">
      <input
        type="text"
        id="diagnosisInput"
        [(ngModel)]="diagnosisInput"
        placeholder="Nhập chẩn đoán"
        style="margin-right: 10px; width: 100%"
      />

      <div *ngFor="let medication of medications; let i = index">
        <div style="display: flex; align-items: center">
          <input
            type="text"
            [(ngModel)]="medication.brandName"
            placeholder="Nhập tên thuốc"
            style="flex: 80%"
          />
          <input
            type="number"
            [(ngModel)]="medication.quantity"
            placeholder="Nhập số lượng"
            style="flex: 0 0 60px; min-width: 45px"
          />
          <!-- Sử dụng flex để đặt chiều rộng cố định -->
          <button
            (click)="removeMedication(i)"
            style="
              background-color: #dc3545;
              color: white;
              border: none;
              cursor: pointer;
            "
          >
            X
          </button>
        </div>
        <div>
          <input
            type="text"
            [(ngModel)]="medication.use"
            placeholder="Nhập hướng dẫn sử dụng"
            style="width: 100%; margin-bottom: 10px"
            (keydown.enter)="addMedication()"
          />
        </div>
      </div>
      <button class="button" (click)="addMedication()">Thêm thuốc</button>

      <textarea
        id="guideInput"
        [(ngModel)]="guideInput"
        rows="5"
        placeholder="Nhập hướng dẫn"
        style="width: 100%"
      ></textarea>

      <input
        type="file"
        #imageSelector
        id="imageSelector"
        name="imageSelector"
        (change)="selectFile($event)"
        multiple
        class="button"
      />

      <button
        *ngIf="fullName"
        printSectionId="print-doc"
        ngxPrint
        ngbTooltip="In Hồ Sơ "
        type="button"
        pButton
        pRipple
        class="p-button-primary"
        icon="pi pi-print"
      ></button>

      <div class="row">
        <div *ngIf="!loadingFileList">
          <ul>
            <li
              *ngFor="let file of importedFiles; let i = index"
              class="d-flex justify-content-between align-items-center"
            >
              <span (click)="viewUploadedFile(file)" style="cursor: pointer">
                {{ file.name }}
              </span>
              <button
                class="btn btn-outline-danger btn-sm"
                (click)="deleteImportedFile(i)"
                title="Xóa tệp"
              >
                &#10005;
              </button>
            </li>
          </ul>
          <ul>
            <li
              *ngFor="let file of selectedFiles; let i = index"
              class="d-flex justify-content-between align-items-center"
            >
              <span (click)="viewSelectedFile(file)" style="cursor: pointer">
                {{ file.name }}
              </span>
              <button
                class="btn btn-outline-danger btn-sm"
                (click)="deleteSelectedFile(i)"
                title="Xóa tệp"
              >
                &#10005;
              </button>
            </li>
          </ul>
        </div>

        <div *ngIf="loadingFileList" class="center2">
          <div class="loader"></div>
        </div>
        <div *ngIf="currentViewedFile">
          <div class="center2" *ngIf="loadingFileSelectedOther">
            <div class="loader"></div>
          </div>
          <ngx-doc-viewer
            *ngIf="currentViewedFile.type !== 'image'"
            [url]="currentViewedFile.url"
            viewer="url"
            (loaded)="contentLoaded()"
            [googleCheckContentLoaded]="true"
            [googleCheckInterval]="3000"
            [googleMaxChecks]="10"
            style="width: 100%; height: 50vh"
          ></ngx-doc-viewer>
          <img
            *ngIf="currentViewedFile.type === 'image'"
            [src]="currentViewedFile.url"
            [alt]="currentViewedFile.name"
            style="width: 100%; height: auto"
          />
        </div>
      </div>
      <input
        type="text"
        id="remarkInput"
        [(ngModel)]="remarkInput"
        placeholder="Nhập ghi chú"
        style="margin-right: 10px; width: 100%"
      />
      <button
        *ngIf="age && gender && guideInput && selectedStatuses.length > 0"
        class="btn btn-outline-danger col-12"
        (click)="inUpdate ? updatePlan() : savePlans()"
      >
        {{ inUpdate ? 'Cập nhật hồ sơ đã sửa' : 'Lưu thành hồ sơ mới' }}
      </button>
    </div>
    <div class="col-lg-2" style="padding: 5px; margin: 0">
      <input
        type="text"
        class="form-control input-sm"
        [(ngModel)]="keyword"
        (ngModelChange)="handleKeywordFilter()"
        placeholder="Lọc theo tên / điện thoại / email"
      />
      <button
        *ngIf="selectedStatuses.length > 0"
        class="btn btn-outline-info col-12"
        (click)="onStatusFilter()"
      >
        Lọc hồ sơ theo trạng thái
      </button>
      <div *ngIf="searchResults.length > 0" class="container-ds-phien-kham">
        <table class="table container table-sm" mat-table>
          <li
            *ngFor="let result of searchResults"
            class="item"
            (click)="
              selectDocument(result);
              docModal.open(selectedDocModal, { size: 'lg', centered: true })
            "
            [ngStyle]="{
              'background-color': selectedDocument == result ? 'lightblue' : ''
            }"
          >
            <b>{{ result.fullName }}</b>
            <br />
            <span
              >{{ result.createdAt?.toDate() | date : 'dd/M/yyyy' }} -
              {{ result.gender }} - {{ result.age }}</span
            >
            <br />
            {{ result.clientEmail }}
            <br />
            {{ result.clientPhone }}
          </li>
        </table>
      </div>
      <br />
      <button
        *ngIf="subcribePlans.length > 0"
        class="btn btn-outline-info col-12"
        (click)="goQuanLyThanhToan()"
      >
        Quản lý thanh toán
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div id="print-doc" *ngIf="fullName">
        <div class="hide-on-screen">
          <table width="100%" cellpadding="0" cellspacing="0">
            <tr valign="top">
              <td width="30%" style="border: none; padding: 0in">
                <p
                  align="left"
                  style="
                    font-size: 10pt;
                    text-align: center;
                    padding: 0;
                    margin: 0;
                  "
                >
                  {{ userData.organize | uppercase }}
                </p>
                <p
                  align="left"
                  style="
                    font-size: 12pt;
                    text-align: center;
                    padding: 0;
                    margin: 0;
                  "
                >
                  {{ address }}
                </p>
                <p
                  align="left"
                  style="
                    font-size: 12pt;
                    text-align: center;
                    padding: 0;
                    margin: 0;
                  "
                >
                  ĐT: <b>{{ clientPhone }}</b>
                </p>
                <p
                  align="left"
                  style="text-align: center; padding: 0; margin: 0"
                >
                  ..................................<br />
                </p>
              </td>
              <td style="border: none; padding: 0in">
                <p align="left" style="margin-top: 0.1in; text-align: center">
                  <font style="font-size: 18pt"><b>TÓM TẮT HỒ SƠ</b></font>
                </p>
                <p align="left" style="margin-top: 0; text-align: center">
                  <font style="font-size: 13pt"
                    ><b>(Medical Summary Report)</b></font
                  >
                </p>
              </td>
            </tr>
          </table>
          <p style="margin-top: 0.1in">&nbsp;</p>
          <p
            style="
              margin: 0;
              display: flex;
              align-items: center;
              font-size: 12pt;
            "
          >
            <span style="margin-right: 8px"
              ><b>Họ và tên / Full name: {{ fullName }}</b></span
            >
            <span style="margin-left: auto; margin-right: 8px"
              >Năm sinh / Tuổi: {{ age }}</span
            >
            <span>Giới / Sex: {{ gender }}</span>
          </p>
          <p style="margin-top: 0.1in; margin-bottom: 0.1in">
            <font style="font-size: 12pt"
              >Địa chỉ / Address: &nbsp;&nbsp;{{ address }}</font
            >
          </p>

          <p style="margin-top: 0.1in; margin-bottom: 0.1in">
            <font style="font-size: 12pt"
              ><b>Chẩn đoán / Diagnosis: {{ diagnosisInput }}</b>
            </font>
          </p>
          <p style="margin-top: 0.1in; margin-bottom: 0.1in">
            <font style="font-size: 12pt"
              ><b>Tóm tắt bệnh án / Summary: </b></font
            >
          </p>
          <p style="margin-top: 0.1in">
            <font style="font-size: 12pt; margin-bottom: 0.1in"
              >a) Quá trình bệnh lý và diễn biến lâm sàng / History:</font
            >
            <br /><br />
            <font style="font-size: 11pt; margin-left: 16px"
              >Tiền sử / Medical history: &nbsp;&nbsp;{{ underlying }}</font
            >
            <br /><br />
            <font style="font-size: 11pt; margin-left: 16px"
              >Diễn biến / Progress :
            </font>
          </p>
          <ul>
            <li *ngFor="let historyItem of historyInput.split('\n')">
              {{ historyItem }}
            </li>
          </ul>
          <p style="margin-top: 0.1in; margin-bottom: 0.1in">
            <font style="font-size: 12pt"
              >b) Tóm tắt kết quả cận lâm sàng / Investigations:
            </font>
            <br />
          </p>
          <ul>
            <li *ngFor="let labItem of labInput.split('\n')">{{ labItem }}</li>
          </ul>
          <p style="margin-top: 0.1in">
            <font style="font-size: 12pt"
              >c) Phương pháp điều trị / Treatments:</font
            ><br />
          </p>
          <ul>
            <li *ngFor="let guideItem of guideInput.split('\n')">
              {{ guideItem }}
            </li>
          </ul>
          <p>
            <font style="font-size: 11pt; margin-left: 16px"
              ><b> Đơn thuốc / Medications:</b></font
            >
          </p>

          <ol>
            <li *ngFor="let medication of medications; let i = index">
              <b>{{ medication.brandName }}</b> &nbsp;&nbsp;&nbsp;&nbsp;-
              S.lượng: {{ medication.quantity }} &nbsp;&nbsp;&nbsp;-
              {{ medication.use }}
            </li>
          </ol>

          <p style="margin-top: 0.1in; line-height: 100%">
            <font style="font-size: 11pt"
              ><b>Ghi chú / Note: </b>{{ remarkInput }}
            </font>
            <br />
          </p>
        </div>
        <table width="100%" cellpadding="0" cellspacing="0">
          <tr valign="top">
            <td width="50%" s style="border: none; padding: 0in">
              <p
                align="right"
                style="
                  margin-top: 0.1in;
                  margin-bottom: 1in;
                  text-align: center;
                "
              >
                <font style="font-size: 12pt"
                  >Ngày khám / Date:
                  <b
                    >&nbsp;<input
                      type="datetime-local"
                      style="border: 0"
                      value="<?= date('Y-m-d'); ?>" /></b
                ></font>
              </p>
            </td>
            <td
              width="50%"
              style="border: none; padding: 0in; text-align: right"
            >
              <p
                align="right"
                style="
                  margin-top: 0.1in;
                  margin-bottom: 1in;
                  text-align: center;
                "
              >
                <font style="font-size: 12pt"
                  ><b>{{ userData.name }} <br /></b
                ></font>
              </p>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <ng-template #selectedDocModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        {{ selectedDocument.fullName }}
      </h4>
      <button
        *ngIf="selectedDocument.providerEmail == userData.email"
        class="btn btn-outline-danger"
        (click)="
          deleteDocument(selectedDocument.documentId);
          modal.dismiss('Delete click')
        "
      >
        Xóa hồ sơ
      </button>
      <button 
        *ngIf="selectedDocument.clientEmail"
        class="btn btn-outline-warning"
        (click)="videoCallService.initiateVideoCall(selectedDocument.clientEmail)"
      >
        Gọi video
      </button>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="max-height: 600px; overflow-y: auto">
      <div>
        <h6><b>Chẩn đoán:</b></h6>
        <span>{{ selectedDocument.diagnosis }}</span>
        <br />
        <h6><b>Ghi chú:</b></h6>
        <span>{{ selectedDocument.remark }}</span>
        <br />
        <h6><b>Bệnh nền:</b></h6>
        <span>{{ selectedDocument.underlying }}</span>
        <br />
        <h6><b>Diễn biến:</b></h6>
        <ul *ngIf="selectedDocument.history?.length > 0">
          <li *ngFor="let history of selectedDocument.history">
            {{ history }}
          </li>
        </ul>
        <h6><b>Tình trạng:</b></h6>
        <ul *ngIf="selectedDocument.plans?.length > 0">
          <li *ngFor="let plan of selectedDocument.plans">
            {{ plan.name }} - {{ plan.option }}
          </li>
        </ul>
        <h6><b>Cận lâm sàng:</b></h6>
        <ul *ngIf="selectedDocument.lab?.length > 0">
          <li *ngFor="let lab of selectedDocument.lab">
            {{ lab }}
          </li>
        </ul>
        <h6><b>Hướng dẫn:</b></h6>
        <ul *ngIf="selectedDocument.guide?.length > 0">
          <li *ngFor="let guide of selectedDocument.guide">
            {{ guide }}
          </li>
        </ul>
        <h6><b>Thuốc:</b></h6>
        <ol *ngIf="selectedDocument.medications?.length > 0">
          <li
            *ngFor="
              let medication of selectedDocument.medications;
              let i = index
            "
          >
            <p>
              {{ medication.brandName }} - Số lượng: {{ medication.quantity }} -
              {{ medication.use }}
            </p>
          </li>
        </ol>
      </div>
    </div>
    <div class="modal-footer">
      <button
        class="btn btn-outline-success"
        (click)="importSelectedDocumentPersonal()"
      >
        Nhập thông tin hành chính
      </button>
      <button
        class="btn btn-outline-primary"
        (click)="importSelectedDocumentLab()"
      >
        Nhập mẫu cận lâm sàng
      </button>
      <button
        class="btn btn-outline-info"
        (click)="importSelectedDocumentTreatment()"
      >
        Nhập kế hoạch điều trị
      </button>

      <button
        *ngIf="selectedDocument.providerEmail == userData.email"
        class="btn btn-outline-warning"
        (click)="importAll()"
      >
        Sửa hồ sơ
      </button>
    </div>
  </ng-template>
</div>
