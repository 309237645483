import { Injectable } from '@angular/core';
import {
  Auth,
  getAuth,
  signInWithEmailAndPassword,
  User,
} from '@angular/fire/auth';
import { CookieService } from 'ngx-cookie-service';
import { GoService } from './go.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private auth: Auth;
  userSubject: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(
    null,
  );

  constructor(
    private go: GoService,
    private cookieSv: CookieService,
  ) {
    this.auth = getAuth();
    this.auth.onAuthStateChanged((currentUser) => {
      this.userSubject.next(currentUser);
    });
  }

  async signInWithEmail(email: string, password: string, isSave?: boolean) {
    try {
      const userCredential = await signInWithEmailAndPassword(
        this.auth,
        email,
        password,
      );
      const user = userCredential.user;
      this.cookieManagement(email, password, isSave);
      this.go.home();
    } catch (error) {
      this.showFailed(error);
    }
  }

  private cookieManagement(email: string, password: string, isSave?: boolean) {
    if (isSave) {
      this.cookieSv.set('email', email);
      this.cookieSv.set('password', password);
    } else {
      this.cookieSv.delete('email');
      this.cookieSv.delete('password');
    }
  }

  async signOut() {
    console.log('[AuthService]', 'Sign Out!');
    try {
      await this.auth.signOut();
      this.cookieSv.delete('email');
      this.cookieSv.delete('password');
      this.go.login();
    } catch (error) {
      console.error('[AuthService]', 'Sign out failed', error);
    }
  }

  private showFailed(error: any) {
    console.log('[AuthService]', error.code);
    let message = '';
    let suggest = '';

    switch (error.code) {
      case 'auth/wrong-password':
        message = 'Sai mật khẩu!';
        suggest = 'Hãy thử lại với một mật khẩu khác.';
        break;
      case 'auth/user-not-found':
        message = 'Email này không có trong hệ thống!';
        suggest = 'Hãy sử dụng một email khác hoặc đăng ký mới.';
        break;
      case 'auth/popup-closed-by-user':
        message = 'Cửa sổ đăng nhập đã bị đóng khi chưa hoàn thành đăng nhập.';
        suggest =
          'Hãy thử quá trình đăng nhập lại hoặc sử dụng một tài khoản khác.';
        break;
      default:
        message = 'Đăng nhập thất bại!';
        suggest = 'Vui lòng thử lại bằng tài khoản khác.';
        break;
    }
  }
}
