import { AuthService } from './../services/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.css'],
})
export class RootComponent implements OnInit {
  isCollapsed = false;
  activeID = 2;
  csyt: boolean = false; // Khởi tạo mặc định là false

  constructor(public authSv: AuthService) {}

  ngOnInit() {
    // Theo dõi sự thay đổi của userData$
    this.authSv.userData$.subscribe({
      next: (userData) => {
        if (userData) {
          // Kiểm tra role 'csyt'
          const role = userData?.role;
          this.csyt = Array.isArray(role) && role.includes('csyt');
        } else {
          // Reset lại csyt nếu không có dữ liệu người dùng
          this.csyt = false;
        }
      },
      error: (err) => {
        console.error('Error checking user role', err);
      },
    });
  }
}
