import {
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import * as BABYLON from 'babylonjs';
import 'babylonjs-loaders';
import * as GUI from 'babylonjs-gui';
import {
  Firestore,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  onSnapshot,
  DocumentChange,
  QueryDocumentSnapshot,
  DocumentData,
} from '@angular/fire/firestore';
import * as CANNON from 'cannon';
import { Observable, Subject } from 'rxjs';
import {
  Storage,
  ref,
  uploadBytesResumable,
  listAll,
  deleteObject,
  getDownloadURL,
} from '@angular/fire/storage';
import { NotifierService } from 'angular-notifier';
import { Auth, getAuth, onAuthStateChanged } from '@angular/fire/auth';
import { UserDocument } from '../interfaces/user-document.interface';
import { CallData } from '../interfaces/call-data.interface';
import { VideoCallService } from '../services/video-call.service';
import { DiamondService } from '../services/diamond.service';

interface Problem {
  name: string;
  tag: string[];
}

interface Plan {
  providerEmail: string;
  plans: { name: string; option: number; tag: string[] }[];
  clientEmail: string;
  createdAt: any;
  clientPhone: string;
  fullName: string;
  guide?: string[];
  history?: string[];
  medications: { brandName: string; quantity: number; use: string }[];
}

interface DocumentGuide {
  document: QueryDocumentSnapshot<DocumentData>;
  guides: string[];
  providerEmail: string;
}

interface Product {
  classify: string[];
  propBenefit: string;
  propName: string;
  propPrice: number;
  propTag: string[];
  propTip: string;
}

@Component({
  selector: 'app-visited-scene',
  template: '',
  standalone: true,
})
export class VisitedSceneComponent implements OnChanges {
  @Input() canvas!: HTMLCanvasElement;
  @Input() firestore!: Firestore;
  @Input() ngZone!: NgZone;
  @Input() email!: string; // Thêm `@Input()` để nhận email
  @Input() userData!: UserDocument;

  @Output() backButtonClicked: EventEmitter<void> = new EventEmitter<void>();

  visitedUserData!: UserDocument;
  visitedUserId!: string;

  private isVisitedUserUIVisible: boolean = false;
  private inputHistory: GUI.InputTextArea;
  private lastUserActivityTime: number;
  private readonly INACTIVITY_THRESHOLD = 5000; // 5 seconds
  private readonly ROTATION_SPEED = 0.005;

  private audioInstance: HTMLAudioElement | null = null;
  private userDocSnapshot: any;
  private clientPhoneInput: GUI.InputText;

  public plansProblem: {
    tag: any;
    name: string;
    option: number;
  }[] = [];

  constructor(
    private diamondService: DiamondService,
    public storage: Storage,
    public notifier: NotifierService,
    public videoCallService: VideoCallService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['email'] && changes['email'].currentValue !== changes['email'].previousValue) {
      this.startScene();
    }
  }

  async startScene() {
    if (this.email) {
      await this.initVisitedScene(this.email);
    }
  }

  private async initVisitedScene(email: string) {
    try {
      if (email) {
        const visitedUserQuery = query(
          collection(this.firestore, 'User'),
          where('email', '==', email)
        );

        const visitedUserSnapshot = await getDocs(visitedUserQuery);

        if (!visitedUserSnapshot.empty) {
          const visitedUserDocSnapshot = visitedUserSnapshot.docs[0];
          this.visitedUserData = visitedUserDocSnapshot.data() as UserDocument;
          this.visitedUserId = visitedUserDocSnapshot.id;

          const visitedEngine = new BABYLON.Engine(this.canvas, true);
          const scene = this.createVisitedScene(visitedEngine);

          if (scene) {
            visitedEngine.runRenderLoop(() => {
              scene.render();
            });

            window.addEventListener('resize', () => {
              visitedEngine.resize();
            });
          } else {
            console.error('Scene creation failed');
          }
        } else {
          console.error('No user document found');
        }
      }
    } catch (error) {
      console.error('Error during Firestore operation:', error);
    }
  }

  private createVisitedScene(engine: BABYLON.Engine): BABYLON.Scene | null {
    const scene = new BABYLON.Scene(engine);
    const ceilingMaterial = new BABYLON.StandardMaterial(
      'ceilingMaterial',
      scene,
    );
    ceilingMaterial.diffuseColor = new BABYLON.Color3(1, 0.5, 1);

    const glassMaterial = new BABYLON.StandardMaterial('glassMaterial', scene);
    glassMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0.2);
    glassMaterial.alpha = 0.9;

    const playerMaterial = new BABYLON.StandardMaterial(
      'playerMaterial',
      scene,
    );
    playerMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0.2);

    const wallMaterial = new BABYLON.StandardMaterial('wallMaterial', scene);
    wallMaterial.diffuseColor = new BABYLON.Color3(1, 1, 1);

    if (this.visitedUserData) {
      const pointLight = new BABYLON.PointLight(
        'pointLight',
        new BABYLON.Vector3(0, 8, 0),
        scene,
      );
      pointLight.diffuse = new BABYLON.Color3(1, 1, 1);
      pointLight.intensity = 0.5;
      pointLight.range = 35;
      scene.addLight(pointLight);

      const hemiLight = new BABYLON.HemisphericLight(
        'hemiLight',
        new BABYLON.Vector3(0, 6, 0),
        scene,
      );
      hemiLight.diffuse = new BABYLON.Color3(1, 0.5, 1);
      hemiLight.intensity = 0.6;
      scene.addLight(hemiLight);

      const visitedCamera = new BABYLON.ArcRotateCamera(
        'visitedCamera',
        0,
        Math.PI / 2,
        10,
        new BABYLON.Vector3(0, 5, 0),
        scene,
      );
      visitedCamera.attachControl(this.canvas, true);
      visitedCamera.lowerRadiusLimit = 10;
      visitedCamera.upperRadiusLimit = 45;
      visitedCamera.lowerBetaLimit = Math.PI / 2 - Math.PI / 15;
      visitedCamera.upperBetaLimit = Math.PI / 2 + Math.PI / 180;
      this.lastUserActivityTime = Date.now();

      visitedCamera.attachControl(this.canvas, true);
      this.canvas.addEventListener('pointermove', () => {
        this.lastUserActivityTime = Date.now();
      });

      scene.registerBeforeRender(() => {
        const currentTime = Date.now();
        if (
          currentTime - this.lastUserActivityTime >
          this.INACTIVITY_THRESHOLD
        ) {
          visitedCamera.alpha += this.ROTATION_SPEED;
        }
      });

      const ground = BABYLON.MeshBuilder.CreateGround(
        'ground',
        { width: 50, height: 50 },
        scene,
      );
      ground.physicsImpostor = new BABYLON.PhysicsImpostor(
        ground,
        BABYLON.PhysicsImpostor.BoxImpostor,
        { mass: 0, restitution: 0.9 },
        scene,
      );

      const ceiling = BABYLON.MeshBuilder.CreateBox(
        'ceiling',
        { width: 50, height: 0.5, depth: 50 },
        scene,
      );
      ceiling.position.set(0, 12, 0);
      ceiling.material = ceilingMaterial;

      const wallF = BABYLON.MeshBuilder.CreatePlane(
        'wallF',
        { height: 12, width: 50 },
        scene,
      );
      wallF.position.set(0, 6, -25);
      wallF.rotation.y = Math.PI;
      wallF.material = wallMaterial;
      wallF.physicsImpostor = new BABYLON.PhysicsImpostor(
        wallF,
        BABYLON.PhysicsImpostor.BoxImpostor,
        { mass: 0, restitution: 0.9 },
        scene,
      );

      const wallL = BABYLON.MeshBuilder.CreatePlane(
        'wallL',
        { height: 12, width: 50 },
        scene,
      );
      wallL.position.set(25, 6, 0);
      wallL.rotation.y = Math.PI / 2;
      wallL.material = wallMaterial;
      wallL.physicsImpostor = new BABYLON.PhysicsImpostor(
        wallL,
        BABYLON.PhysicsImpostor.BoxImpostor,
        { mass: 0, restitution: 0.9 },
        scene,
      );

      const wallR = BABYLON.MeshBuilder.CreatePlane(
        'wallR',
        { height: 12, width: 50 },
        scene,
      );
      wallR.position.set(-25, 6, 0);
      wallR.rotation.y = -Math.PI / 2;
      wallR.material = wallMaterial;
      wallR.physicsImpostor = new BABYLON.PhysicsImpostor(
        wallR,
        BABYLON.PhysicsImpostor.BoxImpostor,
        { mass: 0, restitution: 0.9 },
        scene,
      );

      const wallB = BABYLON.MeshBuilder.CreatePlane(
        'wallB',
        { height: 12, width: 50 },
        scene,
      );
      wallB.position.set(0, 6, 25);
      wallB.material = wallMaterial;
      wallB.physicsImpostor = new BABYLON.PhysicsImpostor(
        wallB,
        BABYLON.PhysicsImpostor.BoxImpostor,
        { mass: 0, restitution: 0.9 },
        scene,
      );

      BABYLON.SceneLoader.ImportMesh(
        '',
        '/assets/',
        'vending.glb',
        scene,
        (meshes) => {
          const diffuseTexture = new BABYLON.Texture(
            'assets/textures/Vending_Machine_VendingMachine_AlbedoTrans.png',
            scene,
          );
          Promise.all([
            new Promise((resolve) =>
              diffuseTexture.onLoadObservable.addOnce(resolve),
            ),
          ])
            .then(() => {
              const material = new BABYLON.StandardMaterial(
                'vendingMaterial',
                scene,
              );
              material.diffuseTexture = diffuseTexture;
              meshes.forEach((mesh) => {
                mesh.material = material;
              });
              const vending = meshes[0];
              vending.position = new BABYLON.Vector3(24, 0, 3);
              vending.scaling = new BABYLON.Vector3(1, 1, 1);
              vending.rotate(BABYLON.Axis.X, -Math.PI / 2);
            })
            .catch((error) => {
              console.error('Texture failed to load:', error);
            });
        },
      );

      const screen = BABYLON.MeshBuilder.CreatePlane(
        'screen',
        { height: 8, width: 15 },
        scene,
      );
      screen.position.set(0, 5, -24.5);
      screen.rotation.y = Math.PI;
      screen.rotation.x = -Math.PI / 90;
      screen.material = glassMaterial;

      const buttonBackPlane = BABYLON.MeshBuilder.CreatePlane(
        'buttonPlane',
        { height: 2, width: 7 },
        scene,
      );
      buttonBackPlane.position.set(-21, 10.5, -24.9); // Điều chỉnh vị trí cao hơn screen
      buttonBackPlane.rotation.y = Math.PI;
      buttonBackPlane.rotation.x = -Math.PI / 90;
      const buttonBackUI =
        GUI.AdvancedDynamicTexture.CreateForMesh(buttonBackPlane);
      const backButton = GUI.Button.CreateSimpleButton(
        'backButton',
        'Trở về >>',
      );
      backButton.color = 'white';
      backButton.cornerRadius = 20;
      backButton.background = 'blue';
      backButton.fontSize = 120;
      buttonBackUI.addControl(backButton);
      backButton.onPointerUpObservable.add(() => {
        this.ngZone.runOutsideAngular(() => {
          if (this.audioInstance) {
            this.audioInstance.pause();
          }
          engine.dispose();
          scene.dispose();
          this.onBackButtonClick();
        });
      });

      if (this.visitedUserData.property) {
        if (
          this.visitedUserData.property.some(
            (prop) => prop.propName === 'Bàn làm việc',
          )
        ) {
          BABYLON.SceneLoader.ImportMesh(
            '',
            '/assets/',
            'banL.glb',
            scene,
            (meshes) => {
              const diffuseTexture = new BABYLON.Texture(
                'assets/textures/RGB_5a0aa8925de14bf8b918f4221119f06f_Desk_A_diffuse.tga.png',
                scene,
              );
              Promise.all([
                new Promise((resolve) =>
                  diffuseTexture.onLoadObservable.addOnce(resolve),
                ),
              ])
                .then(() => {
                  const material = new BABYLON.StandardMaterial(
                    'banLMaterial',
                    scene,
                  );
                  material.diffuseTexture = diffuseTexture;
                  meshes.forEach((mesh) => {
                    mesh.material = material;
                  });
                  const banL = meshes[0];
                  banL.position = new BABYLON.Vector3(19, 0, -20);
                  banL.scaling = new BABYLON.Vector3(0.05, 0.05, 0.05);
                })
                .catch((error) => {
                  console.error('Texture failed to load:', error);
                });
            },
          );
        }
        if (
          this.visitedUserData.property.some(
            (prop) => prop.propName === 'Giường',
          )
        ) {
          BABYLON.SceneLoader.ImportMesh(
            '',
            '/assets/',
            'bed.glb',
            scene,
            (meshes) => {
              meshes.forEach((mesh) => {
                mesh.material = wallMaterial;
                mesh.position = new BABYLON.Vector3(-15, 0, 21.6);
                mesh.scaling = new BABYLON.Vector3(0.18, 0.18, 0.18);
              });
            },
          );
        }
        if (
          this.visitedUserData.property.some(
            (prop) => prop.propName === 'Music Player',
          )
        ) {
          const musicPlayer = BABYLON.MeshBuilder.CreateBox(
            'musicPlayer',
            { width: 2, height: 1, depth: 0.6 },
            scene,
          );
          musicPlayer.position.set(-10, 1, -10);
          musicPlayer.material = playerMaterial;
          musicPlayer.rotation.z = Math.PI / 2;

          const directoryPath = `music/${this.visitedUserData.email}/`;
          const directoryRef = ref(this.storage, directoryPath);

          // Lấy danh sách các file trong thư mục
          listAll(directoryRef)
            .then(async (result) => {
              if (result.items.length > 0) {
                const fileRef = result.items[0];
                const fileURL = await getDownloadURL(fileRef);

                // Kiểm tra kiểu dữ liệu của fileURL
                if (typeof fileURL === 'string') {
                  if (this.audioInstance) {
                    this.audioInstance.pause();
                  }
                  this.audioInstance = new Audio(fileURL);
                  this.audioInstance.loop = true;

                  musicPlayer.actionManager = new BABYLON.ActionManager(scene);
                  musicPlayer.actionManager.registerAction(
                    new BABYLON.ExecuteCodeAction(
                      BABYLON.ActionManager.OnPickTrigger,
                      () => {
                        if (this.audioInstance.paused) {
                          this.audioInstance.play();
                        } else {
                          this.audioInstance.pause();
                        }
                      },
                    ),
                  );
                } else {
                  console.error('fileURL is not a string:', fileURL);
                }
              }
            })
            .catch((error) => {
              console.error('Error fetching music files: ', error);
            });
        }

        if (
          this.visitedUserData.property.some(
            (prop) => prop.propName === 'Banner 1',
          )
        ) {
          const banner = BABYLON.MeshBuilder.CreatePlane(
            'banner',
            { height: 6, width: 10 },
            scene,
          );
          banner.position.set(18, 5, -24.9);
          banner.rotation.y = Math.PI;
          const bannerUI = GUI.AdvancedDynamicTexture.CreateForMesh(banner);

          const directoryPath = `banner1/${this.visitedUserData.email}/`;
          const directoryRef = ref(this.storage, directoryPath);

          // Lấy danh sách các file trong thư mục
          listAll(directoryRef)
            .then(async (result) => {
              if (result.items.length > 0) {
                const fileRef = result.items[0];
                const fileURL = await getDownloadURL(fileRef);

                // Kiểm tra kiểu dữ liệu của fileURL
                if (typeof fileURL === 'string') {
                  const bannerImage = new GUI.Image('bannerImage', fileURL);
                  bannerImage.stretch = GUI.Image.STRETCH_FILL;
                  bannerImage.width = 1;
                  bannerImage.height = 1;
                  bannerImage.top = '200px';
                  bannerUI.addControl(bannerImage);

                  // Thêm sự kiện click để phóng to
                  banner.actionManager = new BABYLON.ActionManager(scene);
                  banner.actionManager.registerAction(
                    new BABYLON.ExecuteCodeAction(
                      BABYLON.ActionManager.OnPickTrigger,
                      () => {
                        const zoomedImage = new GUI.Image(
                          'zoomedImage',
                          fileURL,
                        );
                        zoomedImage.stretch = GUI.Image.STRETCH_UNIFORM;
                        zoomedImage.width = 0.8;
                        zoomedImage.height = 0.8;

                        const fullscreenUI =
                          GUI.AdvancedDynamicTexture.CreateFullscreenUI(
                            'FullscreenUI',
                          );
                        fullscreenUI.addControl(zoomedImage);

                        const closeButton = GUI.Button.CreateSimpleButton(
                          'closeButton',
                          'X',
                        );
                        closeButton.width = '60px';
                        closeButton.height = '40px';
                        closeButton.color = 'white';
                        closeButton.background = 'red';
                        closeButton.top = '10px';
                        closeButton.left = '10px';
                        closeButton.onPointerUpObservable.add(() => {
                          fullscreenUI.dispose();
                        });
                        fullscreenUI.addControl(closeButton);
                      },
                    ),
                  );
                } else {
                  console.error('fileURL is not a string:', fileURL);
                }
              }
            })
            .catch((error) => {
              console.error('Error fetching banner files: ', error);
            });
        }

        if (
          this.visitedUserData.property.some(
            (prop) => prop.propName === 'Banner 2',
          )
        ) {
          const bannerR = BABYLON.MeshBuilder.CreatePlane(
            'bannerR',
            { height: 6, width: 10 },
            scene,
          );
          bannerR.position.set(-18, 5, -24.9);
          bannerR.rotation.y = Math.PI;
          const bannerUIR = GUI.AdvancedDynamicTexture.CreateForMesh(bannerR);

          const directoryPath = `banner2/${this.visitedUserData.email}/`;
          const directoryRef = ref(this.storage, directoryPath);

          // Lấy danh sách các file trong thư mục
          listAll(directoryRef)
            .then(async (result) => {
              if (result.items.length > 0) {
                const fileRef = result.items[0];
                const fileURL = await getDownloadURL(fileRef);

                // Kiểm tra kiểu dữ liệu của fileURL
                if (typeof fileURL === 'string') {
                  const bannerImageR = new GUI.Image('bannerImageR', fileURL);
                  bannerImageR.stretch = GUI.Image.STRETCH_FILL;
                  bannerImageR.width = 1;
                  bannerImageR.height = 1;
                  bannerImageR.top = '200px';
                  bannerUIR.addControl(bannerImageR);

                  // Thêm sự kiện click để phóng to
                  bannerR.actionManager = new BABYLON.ActionManager(scene);
                  bannerR.actionManager.registerAction(
                    new BABYLON.ExecuteCodeAction(
                      BABYLON.ActionManager.OnPickTrigger,
                      () => {
                        const zoomedImage = new GUI.Image(
                          'zoomedImageR',
                          fileURL,
                        );
                        zoomedImage.stretch = GUI.Image.STRETCH_UNIFORM;
                        zoomedImage.width = 0.8;
                        zoomedImage.height = 0.8;

                        const fullscreenUI =
                          GUI.AdvancedDynamicTexture.CreateFullscreenUI(
                            'FullscreenUIR',
                          );
                        fullscreenUI.addControl(zoomedImage);

                        const closeButton = GUI.Button.CreateSimpleButton(
                          'closeButtonR',
                          'X',
                        );
                        closeButton.width = '60px';
                        closeButton.height = '40px';
                        closeButton.color = 'white';
                        closeButton.background = 'red';
                        closeButton.top = '10px';
                        closeButton.left = '10px';
                        closeButton.onPointerUpObservable.add(() => {
                          fullscreenUI.dispose();
                        });
                        fullscreenUI.addControl(closeButton);
                      },
                    ),
                  );
                } else {
                  console.error('fileURL is not a string:', fileURL);
                }
              }
            })
            .catch((error) => {
              console.error('Error fetching banner files: ', error);
            });
        }

        if (
          this.visitedUserData.property.some(
            (prop) => prop.propName === 'Avata',
          )
        ) {
          const avata = BABYLON.MeshBuilder.CreatePlane(
            'avata',
            { height: 4, width: 4 },
            scene,
          );
          avata.position.set(-24.9, 6, -5);
          avata.rotation.y = -Math.PI / 2;
          const avataUI = GUI.AdvancedDynamicTexture.CreateForMesh(avata);

          const directoryPath = `avata/${this.visitedUserData.email}/`;
          const directoryRef = ref(this.storage, directoryPath);

          // Lấy danh sách các file trong thư mục
          listAll(directoryRef)
            .then(async (result) => {
              if (result.items.length > 0) {
                // Lấy URL của file đầu tiên
                const fileRef = result.items[0];
                const fileURL = await getDownloadURL(fileRef);

                // Kiểm tra kiểu dữ liệu của fileURL
                if (typeof fileURL === 'string') {
                  // Hiển thị ảnh lên banner
                  const avataImage = new GUI.Image('avataImage', fileURL);
                  avataImage.stretch = GUI.Image.STRETCH_UNIFORM;
                  avataImage.width = 0.9;
                  avataImage.height = 0.9;
                  avataImage.top = 0;
                  avataImage.verticalAlignment =
                    GUI.Control.VERTICAL_ALIGNMENT_CENTER;
                  avataUI.addControl(avataImage);
                } else {
                  console.error('fileURL is not a string:', fileURL);
                }
              }
            })
            .catch((error) => {
              console.error('Error fetching avatar files: ', error);
            });

          const nameShow = BABYLON.MeshBuilder.CreatePlane(
            'nameShow',
            { height: 3, width: 4 },
            scene,
          );
          nameShow.position.set(-24.9, 2.5, -5);
          nameShow.rotation.y = -Math.PI / 2;
          const nameShowUI = GUI.AdvancedDynamicTexture.CreateForMesh(nameShow);
          const userName = new GUI.TextBlock();
          userName.text = this.visitedUserData.name
            ? `${this.visitedUserData.name}`
            : 'No name';
          userName.color = 'yellow';
          userName.fontSize = 96;
          nameShowUI.addControl(userName);
        }

        if (
          this.visitedUserData.property.some((prop) => prop.propName === 'Menu')
        ) {
          const organize = BABYLON.MeshBuilder.CreatePlane(
            'organize',
            { height: 3, width: 10 },
            scene,
          );
          organize.position.set(-24.9, 8, 3);
          organize.rotation.y = -Math.PI / 2;
          const organizeUI = GUI.AdvancedDynamicTexture.CreateForMesh(organize);
          const organ = new GUI.TextBlock();
          organ.text = this.visitedUserData.organize
            ? `${this.visitedUserData.organize}`
            : 'No organization';
          organ.color = 'green';
          organ.fontSize = 112;
          organizeUI.addControl(organ);

          const menu = BABYLON.MeshBuilder.CreatePlane(
            'menu',
            { height: 6, width: 10 },
            scene,
          );
          menu.position.set(-24.9, 3.5, 3);
          menu.rotation.y = -Math.PI / 2;
          menu.actionManager = new BABYLON.ActionManager(scene);
          menu.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(
              BABYLON.ActionManager.OnPickTrigger,
              () => {
                this.createVisitedUserUI();
              },
            ),
          );
          const menuTexture = GUI.AdvancedDynamicTexture.CreateForMesh(menu);
          const userMenu = new GUI.TextBlock();
          userMenu.text = this.visitedUserData.menu
            ? `\n${this.visitedUserData.menu.join('\n')}`
            : 'No menu';
          userMenu.color = 'green';
          userMenu.fontSize = 72;
          menuTexture.addControl(userMenu);

          const organize1 = BABYLON.MeshBuilder.CreatePlane(
            'organize1',
            { height: 3, width: 10 },
            scene,
          );
          organize1.position.set(0, 8, -24.95);

          const organizeUI1 =
            GUI.AdvancedDynamicTexture.CreateForMesh(organize1);
          const organ1 = new GUI.TextBlock();
          organ1.text = this.visitedUserData.organize
            ? `${this.visitedUserData.organize}`
            : 'No organization';
          organ1.color = 'green';
          organ1.fontSize = 112;
          organizeUI1.addControl(organ1);

          const menu1 = BABYLON.MeshBuilder.CreatePlane(
            'menu1',
            { height: 6, width: 10 },
            scene,
          );
          menu1.position.set(0, 3.5, -24.95);

          const menuTexture1 = GUI.AdvancedDynamicTexture.CreateForMesh(menu1);
          const userMenu1 = new GUI.TextBlock();
          userMenu1.text = this.visitedUserData.menu
            ? `\n${this.visitedUserData.menu.join('\n')}`
            : 'No menu';
          userMenu1.color = 'green';
          userMenu1.fontSize = 72;
          menuTexture1.addControl(userMenu1);
        }
      }
    }
    return scene;
  }

  private createVisitedUserUI() {
    if (this.isVisitedUserUIVisible) {
      console.log('Visited User UI is already visible.');
      return;
    }
    const menu = this.visitedUserData.menu || [];
    const name = this.visitedUserData.name || '';
    const organize = this.visitedUserData.organize || '';

    const visitedUserUI =
      GUI.AdvancedDynamicTexture.CreateFullscreenUI('visitedUserUI');

    const visitedUserOrganize = new GUI.TextBlock();
    visitedUserOrganize.text = organize;
    visitedUserOrganize.width = '400px';
    visitedUserOrganize.height = '50px';
    visitedUserOrganize.color = 'yellow';
    visitedUserOrganize.top = '-200px';
    visitedUserOrganize.left = '-250px';
    visitedUserOrganize.verticalAlignment =
      GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    visitedUserOrganize.horizontalAlignment =
      GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    visitedUserUI.addControl(visitedUserOrganize);

    const visitedUserMenu = new GUI.TextBlock();
    visitedUserMenu.text = `\n${menu.join('\n')}`;
    visitedUserMenu.width = '400px';
    visitedUserMenu.height = '250px';
    visitedUserMenu.color = 'green';
    visitedUserMenu.top = '-50px';
    visitedUserMenu.left = '-250px';
    visitedUserMenu.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    visitedUserMenu.horizontalAlignment =
      GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    visitedUserMenu.textHorizontalAlignment =
      GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
    visitedUserMenu.paddingLeft = '10px';
    visitedUserUI.addControl(visitedUserMenu);

    const inputHistory = new GUI.InputTextArea();
    this.inputHistory = inputHistory;
    inputHistory.width = '400px';
    inputHistory.height = '150px';
    inputHistory.text = 'Thông tin đặt dịch vụ:';
    inputHistory.isVisible = true;
    inputHistory.color = 'white';
    inputHistory.background = 'rgba(50,0,0,0.2)';
    inputHistory.top = '100px';
    inputHistory.left = '-250px';
    inputHistory.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    inputHistory.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    visitedUserUI.addControl(inputHistory);

    const visitedUserName = new GUI.TextBlock();
    visitedUserName.text = name;
    visitedUserName.width = '400px';
    visitedUserName.height = '50px';
    visitedUserName.color = 'white';
    visitedUserName.top = '-200px';
    visitedUserName.left = '180px';
    visitedUserName.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    visitedUserName.horizontalAlignment =
      GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    visitedUserUI.addControl(visitedUserName);

    const addWatchingButton = GUI.Button.CreateSimpleButton(
      'addWatchingButton',
      'Theo dõi / Bỏ theo dõi',
    );
    addWatchingButton.width = '250px';
    addWatchingButton.height = '50px';
    addWatchingButton.top = '-140px';
    addWatchingButton.left = '250px';
    addWatchingButton.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    addWatchingButton.horizontalAlignment =
      GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    addWatchingButton.onPointerDownObservable.addOnce(() => {
      this.addWatching();
    });
    visitedUserUI.addControl(addWatchingButton);

    const sendGiftButton = GUI.Button.CreateSimpleButton(
      'sendGiftButton',
      'Tặng điểm',
    );
    sendGiftButton.width = '250px';
    sendGiftButton.height = '50px';
    sendGiftButton.top = '-80px';
    sendGiftButton.left = '250px';
    sendGiftButton.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    sendGiftButton.horizontalAlignment =
      GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    sendGiftButton.onPointerDownObservable.add(() => {
      this.sendDiamond(this.visitedUserData.email);
    });
    visitedUserUI.addControl(sendGiftButton);

    const clientPhoneInput = new GUI.InputText();
    this.clientPhoneInput = clientPhoneInput;
    clientPhoneInput.width = '250px';
    clientPhoneInput.height = '30px';
    clientPhoneInput.color = 'white';
    clientPhoneInput.background = 'black';
    clientPhoneInput.placeholderText = 'Điện thoại liên hệ...';
    clientPhoneInput.top = '-30px';
    clientPhoneInput.left = '250px';
    clientPhoneInput.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    clientPhoneInput.horizontalAlignment =
      GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    visitedUserUI.addControl(clientPhoneInput);

    const sendOrderButton = GUI.Button.CreateSimpleButton(
      'sendOrderButton',
      'Đặt dịch vụ',
    );
    sendOrderButton.width = '250px';
    sendOrderButton.height = '50px';
    sendOrderButton.top = '15px';
    sendOrderButton.left = '250px';
    sendOrderButton.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    sendOrderButton.horizontalAlignment =
      GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    sendOrderButton.onPointerDownObservable.addOnce(() => {
      this.isVisitedUserUIVisible = false;
      this.savePlans(this.visitedUserData.email);
    });
    visitedUserUI.addControl(sendOrderButton);

    const videCallButton = GUI.Button.CreateSimpleButton(
      'videCallButton',
      'Gọi Video',
    );
    videCallButton.width = '250px';
    videCallButton.height = '50px';
    videCallButton.top = '75px';
    videCallButton.left = '250px';
    videCallButton.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    videCallButton.horizontalAlignment =
      GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    videCallButton.onPointerDownObservable.add(() => {
      this.isVisitedUserUIVisible = false;
      this.videoCallService.initiateVideoCall(this.userData.email, this.visitedUserData.email);
    });
    visitedUserUI.addControl(videCallButton);

    const closeButton = GUI.Button.CreateSimpleButton('closeButton', 'Đóng');
    closeButton.width = '250px';
    closeButton.height = '50px';
    closeButton.top = '135px';
    closeButton.left = '250px';
    closeButton.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    closeButton.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    closeButton.onPointerDownObservable.addOnce(() => {
      this.isVisitedUserUIVisible = false;
      visitedUserUI.dispose();
    });
    visitedUserUI.addControl(closeButton);

    this.isVisitedUserUIVisible = true;
  }

  private async savePlans(providerMail: string) {
    const historyArray: string[] = this.inputHistory?.text
      ? this.inputHistory.text.split('\n')
      : [];
    const planData: Plan = {
      clientEmail: this.userData.email,
      createdAt: new Date(),
      providerEmail: providerMail,
      plans:[],
      guide: null,
      history: historyArray,
      clientPhone: this.clientPhoneInput.text,
      fullName: '',
      medications: [],
    };

    try {
      const plansCollectionRef = collection(this.firestore, 'plans');
      const docRef = await addDoc(plansCollectionRef, planData);
      this.notifier.notify(
        'success',
        `Đặt yêu cầu tới ${providerMail} thành công!`,
      );
    } catch (error) {
      console.error('Error adding plan: ', error);
    }
  }

  private addWatching() {
    if (!this.userData.watching) {
      this.userData.watching = [];
    }
    if (!this.userData.watching.includes(this.visitedUserData.email)) {
      this.userData.watching.push(this.visitedUserData.email);
      this.userDocSnapshot.ref.update({
        watching: this.userData.watching,
      });
    } else {
      console.log('In watching already!');
      // Loại bỏ visitedUserData.email khỏi this.userData.watching
      const index = this.userData.watching.indexOf(this.visitedUserData.email);
      this.userData.watching.splice(index, 1);
      this.userDocSnapshot.ref
        .update({
          watching: this.userData.watching,
        })
        .then(() => {
          console.log('Đã loại bỏ khỏi danh sách theo dõi');
        })
        .catch((error) => {
          console.error('Lỗi khi cập nhật danh sách theo dõi:', error);
        });
    }
  }

  onBackButtonClick() {
    this.backButtonClicked.emit(); // Phát ra sự kiện khi nhấn nút quay lại
  }

  private async sendDiamond(email: string) {
    const success = await this.diamondService.sendDiamond(
      this.userData.email,
      email,
      this.userData.diamonds
    );
    
    if (success) {
      this.updateUserDiamonds((this.userData.diamonds -= 1));
    }
  }

  private async updateUserDiamonds(newDiamonds: number) {
    try {
      await this.userDocSnapshot.ref.update({ diamonds: newDiamonds });
    } catch (error) {
      console.error('Error updating user diamonds:', error);
    }
  }
}
