<head>
  <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no">
</head>
<div class="game-container">
  <canvas #renderCanvas touch-action="none"></canvas>
  <app-user-detail *ngIf="goHome" [userData]="userData" [canvas]="renderCanvas" (tableClicked)="onTableClicked()"></app-user-detail>
  <div id="gameOver" class="game-over" style="display: none;">
    <h2>Tình trạng sức khỏe</h2>
    <ul>
      <li *ngFor="let problem of plansProblem">
        <span>{{ problem.name }}</span>
        <span class="container-status">
          <span *ngIf="problem.option == 5" class="dot-status red"></span>
          <span *ngIf="problem.option == 1" class="dot-status green"></span>
          <span *ngIf="problem.option == 3" class="dot-status yellow"></span>
          <span *ngIf="problem.option == 2" class="dot-status cyan"></span>
          <span *ngIf="problem.option == 4" class="dot-status pink"></span>
        </span>
      </li>
    </ul>

    <p style="white-space: pre-line;">{{ chatGPTContent }}</p>

    <button (click)="replay()">Đánh giá lại</button>
    <button *ngIf="buttonFind" (click)="getGptGuide()">Xem hướng dẫn</button>
    <button *ngIf="!buttonFind" (click)="callDoctor()">Call Doctor</button>

  </div>
  <div id="diamonds" (click)="authService.signOut()">
    &#10084; {{ this.userData.diamonds }}
  </div>
  <button *ngIf="videoCallService.localVideoFrame" (click)="videoCallService.endVideoCall()" class="end-call-button">End Call</button>
  
  <app-user-list *ngIf="goMarket" [userData]="userData"></app-user-list>
</div>

<script>
  function resizeCanvas() {
    var canvas = document.querySelector('#renderCanvas');
    canvas.style.height = window.innerHeight + 'px';
  }

  window.addEventListener('resize', resizeCanvas);
  document.addEventListener('DOMContentLoaded', function() {
    resizeCanvas();
  });
</script>