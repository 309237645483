<app-visited-scene 
  *ngIf="selectedEmail"
  [canvas]="canvas" 
  [firestore]="firestore" 
  [ngZone]="ngZone" 
  [email]="selectedEmail"
  [userData]="userData"
  [videoCallService]="videoCallService"
  [storage]="storage"
  (backButtonClicked)="onBackButtonClicked()">
</app-visited-scene>
<div *ngIf="clientPlansListOpen" class="product-list">
  <div *ngFor="let planDoc of orderList" class="product-item" (click)="
              selectDocument(planDoc);
              docModal.open(selectedDocModal, { size: 'lg', centered: true })
            ">
    <h4>{{ planDoc.providerEmail }}</h4>
    <p>{{ planDoc.createdAt | date: 'dd/MM/yyyy HH:mm' }}</p>
  </div>
</div>

<ng-template #selectedDocModal let-modal>
  <div class="modal-header dark-modal">
    <h4 class="modal-title" id="modal-basic-title">
      {{ selectedDocument.createdAt | date: 'dd/MM/yyyy HH:mm' }}
    </h4>
    
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body dark-modal" style="max-height: 600px; overflow-y: auto">
    <div>     
      <h6><b>Thuốc:</b></h6>
      <ol *ngIf="selectedDocument.medications?.length > 0">
        <li
          *ngFor="
            let medication of selectedDocument.medications;
            let i = index
          "
        >
          <p>
            {{ medication.brandName }} - Số lượng: {{ medication.quantity }} -
            {{ medication.use }}
          </p>
        </li>
      </ol>
      <br />
      <h6><b>Hướng dẫn:</b></h6>
      <ul *ngIf="selectedDocument.guide?.length > 0">
        <li *ngFor="let guide of selectedDocument.guide">
          {{ guide }}
        </li>
      </ul> 
    </div>
  </div>
  <div class="modal-footer dark-modal">
    <span>{{ selectedDocument.providerEmail }}</span>
    <button 
      *ngIf="selectedDocument.providerEmail"
      class="btn btn-outline-warning"
      (click)="videoCallService.initiateVideoCall(selectedDocument.clientEmail, selectedDocument.providerEmail)"
    >
      Gọi video
    </button>
  </div>
</ng-template>
