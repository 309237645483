<div class="product-list">
  <div *ngFor="let product of allProducts" class="product-item" (click)="
              selectDocument(product);
              docModal.open(selectedDocModal, { size: 'lg', centered: true })
            ">
    <h4>{{ product.propName }}</h4>
    <p>{{ product.propTip }}</p>
    <p>{{ product.propBenefit }}</p>
  </div>
</div>

<div class="product-list right-aligned">
  <div *ngFor="let service of allServices" class="product-item" (click)="
              selectDocument(service);
              docModal.open(selectedDocModal, { size: 'lg', centered: true })
            ">
    <h4>{{ service.name }}</h4>
    <p>{{ service.description }}</p>
    <p>{{ service.price | currency: 'VND': 'symbol': '1.0-2' }}</p>
  </div>
</div>

<ng-template #selectedDocModal let-modal>
    <div class="modal-header dark-modal">
      <h4 class="modal-title" id="modal-basic-title">
        <span *ngIf="selectedDocument.propName; else serviceName">{{ selectedDocument.propName }}</span>
        <ng-template #serviceName>
          {{ selectedDocument.name }}
        </ng-template>
      </h4>
      
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body dark-modal" style="max-height: 60vh; overflow-y: auto">
      <div *ngIf="selectedImage" class="image-modal" (click)="closeImageModal()">
        <div class="image-modal-content">
          <img [src]="selectedImage" alt="Zoomed Image" />
        </div>
      </div>
      
      <div class="product-slideshow">
        <div *ngIf="selectedDocument.productImages?.length > 0; else serviceImages">
          <div ngxSlickItem *ngFor="let image of imageUrls" class="slide" (click)="openImageModal(image)">
            <img [src]="image" alt="Product Image" class="img-fluid" />
          </div>
        </div>

        <ng-template #serviceImages>
          <div ngxSlickItem *ngFor="let image of imageUrls" class="slide" (click)="openImageModal(image)">
            <img [src]="image" alt="Product Image" class="img-fluid" />
          </div>
        </ng-template>
      </div>

      <div>
        <br />
        <h6><b>Công dụng:</b></h6>
        <span *ngIf="selectedDocument.propTip; else serviceDescription">{{ selectedDocument.propTip }}</span>
        <ng-template #serviceDescription>
          <p>{{ selectedDocument.description }}</p>
        </ng-template>
        <br />

        <div class="price-section text-right">
          <h6><b>Giá:</b></h6>
          <span *ngIf="selectedDocument.propPrice; else servicePrice">
            {{ selectedDocument.propPrice | currency: 'VND': 'symbol': '1.0-2' }}
          </span>
          <ng-template #servicePrice>
            <p>{{ selectedDocument.price | currency: 'VND': 'symbol': '1.0-2' }}</p>
          </ng-template>
        </div>
      </div>

    </div>
    <div class="modal-footer dark-modal">
      <span *ngIf="selectedDocument.propBenefit" class="prop-benefit">
        {{ selectedDocument.propBenefit }}
      </span>
      <button class="btn btn-outline-warning" (click)="savePlans(modal)">
        Đặt sản phẩm / dịch vụ
      </button>
    </div>
  </ng-template>

  
